import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { t } from "pages/microservices/masterData/translation/Translation";
import Button from "components/atoms/Button";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { M_MASTER_DATA } from "constants/Constants";
import { ApiCall } from "services/ApiServices";
import {
  ADD_TO_PROJECT,
  FETCH_ALL_EMPLOYEES,
  REMOVE_FROM_PROJECT,
} from "routes/ApiEndpoints";
import {
  validateForm,
  validateMultiSelectField,
  validateRequired,
} from "services/validation/ValidationService";
import { useParams } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";

interface Option {
  label: string;
  value: string;
}

interface AddProjectModalProps {
  handleClose: () => void;
  projectName: string;
  url?: any;
  removeStatus?: any;
}
interface ValidationRules {
  [key: string]: Function[];
}

const AddEmployeeModal: React.FC<AddProjectModalProps> = ({
  handleClose,
  projectName,
  url,
  removeStatus,
}) => {
  const urlParts = url.split("/");
  const project = urlParts[urlParts.length - 1];
  const company = urlParts[urlParts.length - 2];
  let projectId = 0;
  let companyId = 0;
  if (!isNaN(Number(project)) && !isNaN(Number(company))) {
    projectId = parseInt(project);
    companyId = parseInt(company);
  }
  const { id } = useParams();
  const [selectedOptions, setSelectedOptions] = useState({
    employee: [],
  });
  const [options, setOptions] = useState({
    employee: [],
  });
  const [error, setError] = useState({
    employee: null,
  });

  const validateInput = (selectedOption: any, fieldName: string): boolean => {
    const validationRules: ValidationRules = {}; // Initialize an empty object
    if (url.includes('matching')) {
      validationRules.candidate = [validateRequired];
    } else {
      validationRules.candidate = [validateMultiSelectField];
    }

    const formData = { ...selectedOptions, [fieldName]: selectedOption };
    const validationErrors = validateForm(formData, validationRules);
    setError((prevErrors) => ({
      ...prevErrors,
      [fieldName]: validationErrors[fieldName],
    }));
    return !validationErrors[fieldName];
  };
  const handleAddClick = () => {
    const isEmployeeSelected = validateInput(
      selectedOptions.employee,
      "employee"
    );
    if (isEmployeeSelected) {
      const postData: any = {
        employeeId: selectedOptions.employee,
        companyId: companyId,
        projectId: projectId,
        category: "Employee",
      };
      if (removeStatus) {
        ApiCall.service(
          REMOVE_FROM_PROJECT,
          "POST",
          postData,
          true,
          M_MASTER_DATA
        ).then((response) => {
          if (response.status === 200) {
            CustomNotify({
              type: "success",
              message: response.message,
            });
          }
        });
      } else {
        ApiCall.service(
          ADD_TO_PROJECT,
          "POST",
          postData,
          false,
          M_MASTER_DATA
        ).then((response) => {
          if (response.status === 200) {
            CustomNotify({
              type: "success",
              message: response.message,
            });
          }
        });
      }
      handleClose(); // Close the modal
    } else {
    }
  };

  const handleSelectChange = (selectedOption: Option, fieldName: string) => {
    const handleCommonLogic = (values: any) => {
      setSelectedOptions((prevState) => ({
        ...prevState,
        [fieldName]: values,
      }));
      validateInput(values, fieldName);
    };

    switch (fieldName) {
      case "employee":
        const employees = selectedOption ? selectedOption : [];
        handleCommonLogic(employees);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const fetchemployee = async () => {
      try {
        const response = await ApiCall.getService(
          FETCH_ALL_EMPLOYEES,
          "GET",
          M_MASTER_DATA,
          false
        );
        if (response?.status === 200) {
          const filteredData = response.data.filter(
            (employee: any) => employee.id === Number(id)
          );
          const formattedOptions = response.data.map((employee: any) => ({
            value: employee.id,
            label: employee.full_name,
          }));
          setOptions({
            employee: formattedOptions,
          });

          if (url.includes("hotlist")) {
            const existingEmployees = response?.data
              .flatMap((record: any) => {
                const result: any = record.existingEmployees.filter(
                  (filterRecords: any) =>
                    projectId !== 0 && companyId !== 0 && filterRecords.project_id == projectId &&
                    filterRecords.company_id == companyId
                );
                if (result.length > 0) {
                  return result;
                }
              })
              .filter(Boolean);
            console.log("existing employees", existingEmployees);

            const formattedExistingOptions = existingEmployees.map(
              (employee: any) => {
                const empId = employee.employee_id;
                const employeeDetails = response?.data.find(
                  (emp: any) => emp.id === empId
                );

                if (employeeDetails.full_name) {
                  return {
                    value: empId,
                    label: employeeDetails.full_name,
                  };
                }
              }
            );
            setSelectedOptions({
              employee: formattedExistingOptions,
            });
          }
          if (url.includes("matching")) {
            const filtteredOptions = filteredData.map((employee: any) => ({
              value: employee.id,
              label: employee.full_name,
            }));
            setSelectedOptions({
              employee: filtteredOptions[0].value && [filtteredOptions[0].value],
            });
          }
        }
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };
    fetchemployee();
  }, []); // Empty dependency array to run only once

  return (
    <Modal
      size="lg"
      show={true}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="bitter-italic-normal-medium-24">
          {t(`Link employee to project : "${projectName}"`)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <SelectWithSearch
            title={t("Employee")}
            name="employee"
            isMandatory={true}
            search={true}
            options={options.employee}
            placeHolder="Select employee"
            value={selectedOptions.employee.length > 0 ? selectedOptions.employee : []}
            onChange={(e) => handleSelectChange(e, "employee")}
            isMulti={true}
            className="select-field"
            error={error.employee || ""}
            isDisabled={url.includes("matching") ? true : false}
          />
        </div>
        <div className="row align-items-center ">
          <div className="col-6  ">
            <p
              className="text-uppercase  mb-0 cursor-pointer"
              onClick={handleClose}
            >
              {t("Cancel")}
            </p>
          </div>
          <div className="col-6">
            <Button
              title={removeStatus ? t("Remove") : t("Add")}
              handleClick={handleAddClick}
              className="btn form-button float-end text-uppercase rounded-0 shadow-none"
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddEmployeeModal;
