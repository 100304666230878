import React, { useState } from "react";
import { useFormContext } from "../../context/Context";
import CardCircle from "components/atoms/CardCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import { t } from "pages/microservices/masterData/translation/Translation";
import { ADD_DOCUMENT, PROFILE_CARD, REMOVE_DOCUMENT, UPDATE_ERROR_FIELD, UPDATE_FIELD } from "../../annotations/CandidateConstants";
import MaxiconFileUpload from "components/molecules/MaxiconFileUpload";
import { handleSingleFileUpload } from "utils/S3Bucket/Index";
import ModalPopup from "components/atoms/ModalPopup";
import { Option } from "utils/TypeAnnotations";
import CalendarIcon from "static/images/CalendarIcon";
import { formatDate } from "../../helpers/CandidateHelperFunctions";
import { useLocation } from "react-router-dom";

const CandidateProfileCard: React.FC = () => {
    const { state, dispatch } = useFormContext();
    const location = useLocation();
    // const isEdit = location.pathname.includes('edit');
    const [isUploadPhotoModalOpen, setUploadPhotoModalOpen] = useState(false);

    const openUploadPhotoModal = () => {
        setUploadPhotoModalOpen(true);
    };

    const closeUploadPhotoModal: any = () => {
        setUploadPhotoModalOpen(false);
        // dispatch({
        //     type: UPDATE_ERROR_FIELD,
        //     field: "picFileId",
        //     error: "",
        // });
    };

    const number =
        state.general.street.trim() !== ""
            ? `, ${state.general.number}`
            : state.general.number;
    const country =
        state.general.country?.label === undefined
            ? ""
            : state.general.country?.label;
    const city = state.general.city;
    // state.general.country?.value === 29
    // ? state.general.city
    // : state.general.cityOther;
    const functionsList = state.jobProfile.desiredFunctions !== null && state.jobProfile?.desiredFunctions
        .map((item) => item.label)
        .join(", ");

    const handleFileChange = async (event: any, field: any) => {
        let fileData: any = null;
        if (event !== null) {
            fileData = await handleSingleFileUpload(event, "cv");
            if (fileData !== null) {
                closeUploadPhotoModal();
                if (field === "uploadCv" && fileData?.accessUrl !== '' && fileData?.fileName !== '') {
                    const cvCategory: Option | null = state.dropDownData.categoryList.find((category: Option) => category?.label?.toLowerCase() === "cv") ?? null;
                    const fileName = fileData?.fileName;
                    const index = fileName.lastIndexOf(".");
                    const name = index !== -1 ? fileName.slice(0, index - 1) : fileName;
                    const extension = index !== -1 ? fileName.slice(index) : "";

                    const cvUploadedDoc = {
                        id: null,
                        fileUpload: {
                            accessUrl: fileData?.accessUrl,
                            fileName: fileData?.fileName,
                        },
                        fileName: name,
                        fileExtension: extension,
                        category: cvCategory,
                        validFrom: null,
                        validUntil: null,
                    };
                    dispatch({
                        type: ADD_DOCUMENT,
                        data: cvUploadedDoc,
                        index: state.documents.length,
                    });
                }
            }
        } else if (field === "uploadCv") {
            //** Delete from doc checklist if delete resume here*/
            const fileIdToDeleteInDoc = state.profileCard.uploadCv?.accessUrl;
            const indexToRemove = state.documents.findIndex(
                (document) => document.fileUpload?.accessUrl === fileIdToDeleteInDoc
            );
            if (indexToRemove !== -1) {
                dispatch({ type: REMOVE_DOCUMENT, indexToRemove });
            }
        }
        dispatch({
            type: UPDATE_FIELD,
            tab: PROFILE_CARD,
            field: field,
            value: fileData,
        });
    };
console.log("state of general tab", state.general);

    return (
        <div className="row">
            <div className="col-md-7">
                <div
                    style={{
                        marginLeft: "3.75vw",
                    }}
                >
                    <div
                        className="card position-relative border-0"
                        style={{
                            backgroundColor: "",
                            padding: "2.5vw",
                        }}
                    >
                        <div className="cv-upload">
                            <CardCircle
                                position="absolute"
                                width="7.813vw"
                                height="7.813vw"
                                backgroundColor="var(--primaryBrandColor)"
                                left="0%"
                                top={"50%"}
                                transform={`translate(-3.5vw, -3.9vw)`} // Centering horizontally and vertically
                                className="me-5 flex-column cursor-pointer justify-content-center"
                                color="var(--color-white)"
                                inputClass={"mb-2"}
                                onClick={openUploadPhotoModal}
                            >
                                {state.profileCard.profilePicture !== null && state?.profileCard?.profilePicture?.fileName !== "" ? (
                                    <img
                                        src={state.profileCard.profilePicture?.accessUrl}
                                        alt={t("Uploaded")}
                                        style={{
                                            backgroundColor: "var(--color-white)",
                                            borderRadius: "50%",
                                            boxShadow: "rgb(248 216 235) 0.052vw 0vw 0.521vw 0.156vw",
                                        }}
                                        width="100%"
                                        height="100%"
                                    />
                                ) : (
                                    <>
                                        <FontAwesomeIcon
                                            icon={faArrowUpFromBracket}
                                            className="fa-lg mb-2"
                                        />
                                        <span className="">{t("Upload photo")}</span>
                                    </>
                                )}
                            </CardCircle>
                        </div>
                        <div className="row rounded" style={{ backgroundColor: "#BEF1FA" }}>
                            <ModalPopup
                                show={isUploadPhotoModalOpen}
                                onHide={closeUploadPhotoModal}
                                title={t("Profile photo")}
                                body={
                                    <div className="row">
                                        <div className="col-md-12 ms-auto position-relative">
                                            <div className={`py-3 text-center`}>
                                                <MaxiconFileUpload
                                                    label={t('Upload photo')}
                                                    name={'profilePicture'}
                                                    id={'profilePicture'}
                                                    isMandatory={false}
                                                    edit={false}
                                                    fileId={null}
                                                    fileObj={JSON.stringify(state.profileCard.profilePicture)}
                                                    multiple={false}
                                                    uploadPath="candidate-profilePicture"
                                                    formats={'value.formats'}
                                                    handleChange={(e: any) => handleFileChange(e, "profilePicture")}
                                                    maxFileSize={10000}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            />
                            <div className="col-md-10 ms-auto">
                                <div className={`color-dark-pink name-text text-start mb-3`}>
                                    {state.general?.firstName} {state.general?.lastName}
                                </div>
                                <div
                                    className="job-title text-start"
                                    style={{ marginBottom: "1vw" }}
                                >
                                    {state.jobProfile.desiredFunctions && state.jobProfile?.desiredFunctions.length > 0 && (
                                        <>
                                            <span>{t("looking for a job as")}</span>
                                            <span>
                                                <strong> {functionsList}</strong>
                                            </span>
                                        </>
                                    )}
                                </div>
                                <div className="candidate-person-details">
                                    <div
                                        className="d-flex align-items-center"
                                        style={{ marginBottom: "0.8vw" }}
                                    >
                                        <img
                                            src="/static/images/mobile.png"
                                            alt={t("Mobile")}
                                            className="profileCardImage"
                                        />
                                        <span className={`ms-3`}>{state.general?.phoneNumber}</span>
                                    </div>
                                    <div
                                        className="d-flex align-items-center"
                                        style={{ marginBottom: "0.8vw" }}
                                    >
                                        <img
                                            src="/static/images/mail.png"
                                            alt={t("Mail")}
                                            className="profileCardImage"
                                        />
                                        <span className={`ms-3`}>{state.general?.email}</span>
                                    </div>
                                    <div
                                        className="d-flex align-items-start"
                                        style={{ marginBottom: "0.8vw" }}
                                    >
                                        <img
                                            src="/static/images/home.png"
                                            alt={t("Address")}
                                            className="profileCardImage"
                                        />
                                        <span className={`ms-3`}>
                                            {state.general?.street && (
                                                <div style={{ marginBottom: "0.8vw" }}>
                                                    {`${state.general?.street}${number}`}
                                                </div>
                                            )}
                                            <span>
                                                {`${city}`}
                                                {state.general?.zipCode && ` ${state.general?.zipCode}`}
                                            </span>
                                            <div>{`${country}`}</div>
                                        </span>
                                    </div>
                                    {state.general?.dob &&
                                        state.general?.dob !== null && (
                                            <div
                                                className="d-flex align-items-center "
                                                style={{ marginBottom: "0.8vw" }}
                                            >
                                                <span className="color-dark-pink profileCalendar">
                                                    <CalendarIcon />
                                                </span>
                                                <span className={`ms-3`}>
                                                    {state.general.dob instanceof Date ? formatDate(state.general.dob): String(state.general.dob)}
                                                    {/* {isEdit ? formatDate(state.general.dob) : String(state.general.dob)} */}
                                                </span>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xxl-3 col-4 pt-1 ps-4 align-self-center">
                <div className="shout-icon d-flex align-items-center">
                    <div className="d-flex">
                        <img
                            src="/static/images/megaphone.svg"
                            alt="shout"
                            className="img-fluid"
                            width="90vw"
                        />
                        <div className="available-text mb-2 fraunces-italic color-dark-pink">
                            <div>{t("Available")}</div>
                            <div>{t("for work!")}</div>
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <MaxiconFileUpload
                        label={t('Upload cv')}
                        name={'uploadCv'}
                        id={'uploadCv'}
                        isMandatory={false}
                        edit={false}
                        fileId={null}
                        fileObj={JSON.stringify(state.profileCard.uploadCv)}
                        multiple={false}
                        uploadPath="candidate-certificate"
                        formats={'value.formats'}
                        handleChange={(e: any) => handleFileChange(e, "uploadCv")}
                        maxFileSize={10000}
                    />

                </div>

            </div >
        </div >
    );
};

export default CandidateProfileCard;
