import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "components/atoms/Button";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import TableActions from "components/organism/Actions/TableAction";
import {
  PATH_EMPLOYEE_MANAGE,
  PATH_PROJECTS_MANAGE,
  PATH_HR_PLANNING,
} from "constants/Paths";
import { t } from "pages/microservices/masterData/translation/Translation";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import {
  getProjectStatusColor,
  getProjectTooltipMessage,
} from "services/util/UtilService";
import Pagination from "components/atoms/Pagination";
import {
  DELETE_HOTLIST_DATA,
  FETCH_ACTIVE_PROJECT,
  GET_COMPANY_PROJECT_OPTION,
  GET_EMPLOYEE_DETAILS_BY_ID,
  PROJECTS,
  SAVE_HOTLIST_DATA,
} from "routes/ApiEndpoints";
import AddEmployeeModal from "./addEmployeeModel";
import CustomNotify from "components/atoms/CustomNotify";
import ModalPopup from "components/atoms/ModalPopup";
import { MatchingCandidateProfileCardType } from "components/Matching/MatchingCandidateProfileCardType";
import MatchingProfileCard from "components/Matching/MatchingCandidateProfileCard";
import { IconData } from "services/IconData";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import BackButton from "components/atoms/BackButton";

interface ProjectData {
  id: number;
  isActive: any;
  company: any;
  name: string;
  locations: { name: string }[];
  margin: string;
  marginPercentage: string;
  startdate: string;
  enddate: string;
  contractSign: boolean;
  hotlistStatus: boolean;
  AddEmployeeStatus: [];
}
interface SearchData {
  company: string;
  project: string;
}
interface DataProps {
  page: number;
  nOR: number;
  search?: Partial<SearchData>;
}
interface Option {
  value: number;
  label: string;
}

const MatchingEmployee: React.FC = ({}) => {
  const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState<string>("");
  const [option, setOptions] = useState({
    company: [],
    project: [],
  });
  const itemsPerPage = 10;
  const queryParams = new URLSearchParams(window.location.search);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [projectData, setProjectData] = useState<ProjectData[]>([]);
  const navigate = useNavigate();
  const url = useLocation();
  const [search, setSearch] = useState<any>({
    company: queryParams.get("company") ?? "",
    projects: queryParams.get("projects") ?? "",
  });
  const [submit, setSubmit] = useState(true);
  const [status, setStatus] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [id, setId] = useState({
    projectId: null,
    companyId: null,
  });
  const [profileCardData, setProfileCardData] =
    useState<MatchingCandidateProfileCardType>({
      candidateId: null,
      firstName: "",
      lastName: "",
      functions: "",
      mobNumber: "",
      email: "",
      street: "",
      number: "",
      box: "",
      zipCode: "",
      city: "",
      picFileName: "",
      picFilePath: "",
      country: "",
      cvData: "",
      profilePicData: "",
    });
  const [removeStatus, setRemoveStatus] = useState<boolean>(false);
  const urlParts = url.pathname.split("/");
  const employeeId = parseInt(urlParts[urlParts.length - 1]);

  const handleSelectChange = (
    selectedOption: Option | null,
    fieldName: string
  ) => {
    setSearch((prevState: any) => ({
      ...prevState,
      [fieldName]: selectedOption?.value,
    }));
  };

  const handleAddEmployee = (item: any) => {
    setId({
      ...id,
      projectId: item.id,
      companyId: item?.company?.value,
    });
    setShowAddEmployeeModal(true);
    setSelectedProject(item.name);
    setStatus(!status);
    setRemoveStatus(false);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSubmit = (clear: boolean) => {
    if (clear) {
      navigate({ search: "" });
      setSearch({
        company: "",
        projects: "",
      });
    }
    if (createSearchQuery(search) !== "" && !clear) {
      navigate(`?${createSearchQuery(search)}`);
    } else if (!clear) {
      CustomNotify({ type: "warning", message: "Search input not provided." });
    }
    setSubmit(!submit);
    setCurrentPage(1);
  };

  const createSearchQuery = (data: Partial<SearchData>) => {
    const queryParams = Object.entries(data)
      .filter(([key, value]) => value !== undefined && value !== "")
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return queryParams;
  };

  useEffect(() => {
    fetchOptions();
    if (employeeId) {
      fetchEmployeeDetails(employeeId);
    }
  }, []);
  const fetchEmployeeDetails = async (id: any) => {
    try {
      const response = await ApiCall.service(
        GET_EMPLOYEE_DETAILS_BY_ID,
        "POST",
        { employeeId: id },
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        const data = response?.data;
        // Update the state with the fetched data
        setProfileCardData({
          candidateId: id,
          firstName: data.firstName || "",
          lastName: data.lastName || "",
          functions: "",
          mobNumber: data.mobNumber || "",
          email: data.email || "",
          street: data.street || "",
          number: data.number || "",
          box: data.box || "",
          zipCode: data.zipCode || "",
          city: data.city || "",
          picFileName: data.image_url || "",
          picFilePath: data.image_url || "",
          country: data.country || "",
          cvData: data.cv || "",
          profilePicData: data.image_url || "",
        });
      }
    } catch (error) {
      //  console.error("Error fetching data:", error);
    }
  };

  const fetchOptions = async () => {
    try {
      const responseCompanyProject = await ApiCall.getService(
        GET_COMPANY_PROJECT_OPTION,
        "GET",
        M_MASTER_DATA,
        false
      );
      if (responseCompanyProject?.status === 200) {
        setOptions({
          company: responseCompanyProject?.data.company || [],
          project: responseCompanyProject?.data.projects || [],
        });
      }
    } catch (error) {
      //  console.error("Error fetching data:", error);
    }
  };
  const handleAddPlanning = (value: any) => {
    navigate(`${PATH_HR_PLANNING}?employee=${value.id}`);
  };

  const handleAddHotlist = async (item: any) => {
    const postData = {
      category: "project",
      categoryValue: item.id,
    };
    try {
      if (!item.hotlistStatus) {
        const response = await ApiCall.service(
          SAVE_HOTLIST_DATA,
          "POST",
          postData,
          false,
          M_MASTER_DATA
        );
        if (response?.status === 200) {
          setStatus(!status);
          CustomNotify({
            type: "success",
            message: "Project successfully added to hotlist",
          });
        }
      } else if (item.hotlistStatus) {
        setStatus(!status);
        setShowDeleteModal(true);
        setDeleteId(item.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const postData: DataProps = {
      page: currentPage,
      nOR: itemsPerPage,
      search: search,
    };
    getProjectData(postData);
  }, [currentPage, submit, status]);

  const getProjectData = async (postData: DataProps) => {
    const response = await ApiCall.service(
      FETCH_ACTIVE_PROJECT,
      "POST",
      postData,
      false,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      const projectDataFromAPI: ProjectData[] = response?.data?.map(
        (item: any) => ({
          id: item.id,
          name: item.name,
          locations: item.locations, // Fill this with appropriate data
          margin: item.margin,
          marginPercentage: item.marginPercentage,
          startdate: item.startdate,
          enddate: item.enddate,
          contractSign: false, // Set it to an appropriate boolean value
          company: item.company,
          isActive: item.isActive,
          hotlistStatus: item.hotlist ? item.hotlist.status : false,
          AddEmployeeStatus: item.status ? item.status : [],
        })
      );
      setProjectData(projectDataFromAPI);
      setTotalPages(response?.data?.totalPages);
    } else {
      console.log("error");
    }
  };
  const handleClose = () => {
    setShowAddEmployeeModal(false);
    setStatus(!status);
  };

  const handleModalClose = () => {
    setShowDeleteModal(false);
  };
  const handleSaveChanges = async () => {
    setStatus(!status);
    setShowDeleteModal(false);
    if (deleteId) {
      const postData = {
        id: deleteId,
        page: "matching",
      };
      const response = await ApiCall.service(
        DELETE_HOTLIST_DATA,
        "POST",
        postData,
        false,
        M_MASTER_DATA
      );
      if (response.status === 200) {
        CustomNotify({
          type: "success",
          message: "Deleted  successfully from  hotlist",
        });
      } else {
        CustomNotify({ type: "success", message: "unableto delete" });
      }
    }
  };

  function handleRemoveEmployee(item: any) {
    setId({
      ...id,
      projectId: item.id,
      companyId: item?.company?.value,
    });
    setShowAddEmployeeModal(true);
    const statusCheck: any =
      item.AddEmployeeStatus.length > 0 &&
      item.AddEmployeeStatus.filter(
        (record: any) =>
          record["employee_id"] == employeeId && record["project_id"] == item.id
      ).length !== 0;

    setRemoveStatus(statusCheck);
  }
  const handleCvDownload = () => {
    try {
      const cvData = JSON.parse(profileCardData.cvData);
      const cvUrl = cvData.accessUrl;
      const fileName = cvData.fileName;
      if (cvUrl) {
        const link = document.createElement("a");
        link.href = cvUrl;
        link.target = "_blank";
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
          document.body.removeChild(link);
        }, 1000);
      } else {
        CustomNotify({
          type: "error",
          message:
            "The CV for this employee has not been uploaded. Please check the employee's profile",
        });
      }
    } catch (error) {
      CustomNotify({
        type: "error",
        message:
          "The CV for this employee has not been uploaded. Please check the employee's profile",
      });
    }
  };

  return (
    <>
      {/* <h1 className="py-4 page-title mb-0">{t("Matching employees")}</h1> */}
      <div className="row" style={{ paddingTop: "2vw" }}>
        <div className="col-md-12 position-relative">
          <MatchingProfileCard profileCardProps={profileCardData} />
          <div className="row">
            <div className="col-7"></div>
            <div className="col-3">
              <Button
                title="Cv download"
                type="button"
                handleClick={() => handleCvDownload()}
                className="form-button matchingCandidateBtn cvDownloadIcon float-end"
                icon={faCloudDownloadAlt}
                buttonTitle="me-3"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div className="row">
          {/* Location Select */}
          <div className="col-4">
            <SelectWithSearch
              title="Company"
              name="company"
              isMandatory={false}
              search={true}
              placeHolder="Select company"
              options={option?.company}
              value={search.company}
              onChange={(e) => handleSelectChange(e, "company")}
              className="select-field"
              error={""}
            />
          </div>
          <div className="col-4">
            <SelectWithSearch
              title="Project"
              name="projects"
              id="projects"
              isMandatory={false}
              search={true}
              placeHolder="Select Project"
              options={option?.project}
              value={search.projects}
              onChange={(e) => handleSelectChange(e, "projects")}
              className="select-field"
              error={""}
            />
          </div>
          <div className="col-4 searchBtnWrapper">
            <div className="d-flex align-items-center">
              <ResetBtn handleResetClick={() => handleSubmit(true)} />
              <SearchBtn handleSearchClick={() => handleSubmit(false)} />
            </div>
          </div>
        </div>
        <div className="p-5 border rounded-4">
          {/* Table */}
          <div className="table-responsive manage-matching-list">
            <table className="table table-hover">
              <thead>
                <tr className="TableHeader">
                  <th style={{ width: "10%" }}>{t("Project")}</th>
                  <th style={{ width: "10%" }}>{t("Company")}</th>
                  <th style={{ width: "10%" }}>{t("Location")}</th>
                  <th style={{ width: "10%" }}>{t("Margin")}</th>
                  <th style={{ width: "10%" }}>{t("Margin%")}</th>
                  <th style={{ width: "10%" }}>{t("Start date")}</th>
                  <th style={{ width: "10%" }}>{t("End date")}</th>
                  <th style={{ width: "10%" }}>{t("Project status")}</th>
                  <th style={{ width: "10%" }}>{t("Action")}</th>
                </tr>
              </thead>
              <tbody>
                {projectData && projectData.length > 0 ? (
                  projectData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item?.company?.name}</td>
                      <td>
                        {item.locations
                          .map((location: { name: any }) => location.name)
                          .join(", ")}
                      </td>
                      <td>{item.margin}</td>
                      <td>{item.marginPercentage}</td>
                      <td>{item.startdate.split(" ")[0]}</td>
                      <td>
                        {item.enddate != null ? item.enddate.split(" ")[0] : ""}
                      </td>
                      <td className="align-middle">
                        <div
                          className={`${getProjectStatusColor(
                            item.isActive
                          )} m-auto`}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={getProjectTooltipMessage(item.isActive)}
                        ></div>
                      </td>
                      <td className="align-middle">
                        <TableActions
                          isAction={true}
                          handleAddPlanning={() => handleAddPlanning(item)}
                          handleAddEmployee={() => {
                            if (
                              item?.AddEmployeeStatus.length > 0 &&
                              item.AddEmployeeStatus.filter(
                                (record) =>
                                  record["project_id"] == item.id &&
                                  record["employee_id"] == employeeId
                              ).length !== 0
                            ) {
                              handleRemoveEmployee(item);
                            } else {
                              handleAddEmployee(item);
                            }
                          }}
                          employeeAddedToProject={
                            item.AddEmployeeStatus.length > 0 &&
                            item.AddEmployeeStatus.filter(
                              (record) =>
                                record["project_id"] == item.id &&
                                record["employee_id"] == employeeId
                            ).length !== 0
                              ? true
                              : false
                          }
                          handleAddHotlist={() => handleAddHotlist(item)}
                          value={item.hotlistStatus} //if even then true
                          permission={null}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="border-bottom">
                    <td colSpan={9} className="border-0 text-center py-3 px-2">
                      {t("No records")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="pagination justify-content-center align-items-center my-3 my-lg-0">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
      {/* Back Button */}
      <div className="d-flex justify-content-between align-items-center my-4">
        <BackButton />
      </div>

      {/* Project Modal */}
      {showAddEmployeeModal && (
        <AddEmployeeModal
          handleClose={handleClose}
          projectName={selectedProject}
          url={
            url.pathname +
            "/" +
            employeeId +
            "/" +
            id.companyId +
            "/" +
            id.projectId
          }
          removeStatus={removeStatus}
        />
      )}
      <ModalPopup
        show={showDeleteModal}
        onHide={handleModalClose}
        title={t("Delete confirmation")}
        body={t("Are you sure want to delete this match from hotlist?")}
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle={t("No")}
        confirmTitle={t("Yes")}
      />
    </>
  );
};

export default MatchingEmployee;
