import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Search from "components/atoms/Search";
import Pagination from "components/atoms/Pagination";
import EditIcon from "static/images/EditIcon";
import { t } from "pages/microservices/masterData/translation/Translation";
import { CREATE_CANDIDATE, EDIT_CANDIDATE, MANAGE_CONTRACT_PROPOSAL, MANAGE_WAGE_PROPOSAL, PATH_CANDIDATE_CORNER, PATH_MATCHING_EMPLOYEE } from "constants/Paths";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import Title from "components/atoms/Title";
import { ApiCall } from "services/ApiServices";
import { M_IDENTITY_MANAGER, UserStatus } from "constants/Constants";
import { CANDIDATE_ARCHIVE, FETCH_CANDIDATE_OVERVIEW_DATA } from "routes/ApiEndpoints";
import { OptionProps } from "react-select";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import Matching from "static/images/matchingIcon";
import Button from "components/atoms/Button";
import FollowUpIcon from "static/images/FollowUpIcon";
import RightSideBar from "pages/microservices/masterData/follow-up-todo/RightSideBar";
import ArchieveIcon from "static/images/ArchiveIcon";
import ModalPopup from "components/atoms/ModalPopup";
import CustomNotify from "components/atoms/CustomNotify";
import { useSelector, useDispatch } from "react-redux";
import { selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { convertArrayToOptions } from "services/util/UtilService";
import FilterOrganism from "components/organism/FilterOrganism";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import { CandidatesTableHeader } from "TableHeader";
import AddWageProposalIcon from "static/images/AddWageProposalIcon";
import AddContractIcon from "static/images/addContractIcon";

export interface CandidateData {
    id?: number;
    email: string;
    status: number | null;
    function: string;
    name: string;
    mobileNumber: string;
    businessUnits: string;
    businessUnitId: number | null;
    ssn: string;
}

const ManageCandidates: React.FC = () => {
    const navigate = useNavigate();
    const pageHistory = useSelector(selectPageHistory);
    const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
    const dispatch = useDispatch();
    const [candidates, setCandidates] = useState<CandidateData[]>([]);
    const [pageData, setPageData] = useState({
        totalPages: 0,
        totalRecords: 0,
        refresh: true,
        initialCall: true
    });

    const getSearchData = () => {
        return {
            businessUnits: history?.filters?.businessUnits ?? [],
            businessUnitId: history?.filters?.businessUnitId ?? "",
            name: history?.filters?.name ?? "",
            email: history?.filters?.email ?? "",
            mobileNumber: history?.filters?.mobileNumber ?? "",
            function: history?.filters?.function ?? "",
            ssn: history?.filters?.ssn ?? "",
            status: history?.filters?.status ?? "",
            orderBy: history?.filters?.orderBy ?? "id",
            sortDirection: history?.filters?.sortDirection ?? "desc",
        }
    };
    const [businessUnits, setBusinessUnits] = useState<OptionProps[]>([]);
    const [followupSideBar, setFollowupSideBar] = useState<boolean>(false);
    const [sideBarWidth, setSidebarWidth] = useState("");
    const [entity, setEntity] = useState<{
        entityId: string | number | null;
        businessUnit: string | number | null;
    }>({ entityId: null, businessUnit: null });
    const [archiveModal, setArchiveModal] = useState(false);
    const [candidateId, setCandidateId] = useState<number | undefined>(undefined);

    useEffect(() => {
        const postData = {
            currentPage: Number(history?.filters?.currentPage ?? 1),
            itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
            search: getSearchData(),
            initialCall: pageData?.initialCall
        };
        fetchCandidates(postData);
    }, [pageData?.refresh, pageData?.initialCall]);

    const fetchCandidates = async (postData: any) => {
        const response = await ApiCall.service(FETCH_CANDIDATE_OVERVIEW_DATA, "POST", postData, false, M_IDENTITY_MANAGER);
        if (response?.status === 200) {
            if (pageData?.initialCall) {
                setBusinessUnits(response?.dropDownData?.businessUnitList || []);
            }
            setCandidates(response?.data || []);
            setPageData((prev: any) => ({ ...prev, totalPages: response?.data?.totalPages, totalRecords: response?.data?.totalRecords, initialCall: false }))
        }
    };

    const handleClick = (id: number | undefined) => {
        if (id) {
            navigate(`${PATH_CANDIDATE_CORNER}/${id}?source=candidate`);
        }
    }

    const handleCloseSidebar = () => {
        setFollowupSideBar(false);
    };

    const getWidth = (width: any) => {
        setSidebarWidth(width);
    };

    const handleFollowup = (
        id: number | undefined,
        businessUnit: string | number | null
    ) => {
        if (entity.entityId === id) {
            setEntity({
                entityId: null,
                businessUnit: null,
            });
            setFollowupSideBar(false);
        } else {
            setEntity({
                entityId: id ?? null,
                businessUnit: businessUnit ?? null,
            });
            setFollowupSideBar(true);
        }
    };

    const handleArchive = (id: number | undefined) => {
        setArchiveModal(true);
        setCandidateId(id);
    }
    const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

    const handleArchiveConfirm = async () => {
        if (candidateId) {
            const response = await ApiCall.getService(`${CANDIDATE_ARCHIVE}/${candidateId}`, "GET", M_IDENTITY_MANAGER, true);
            if (response.status === 200) {
                handleRefresh();
                setArchiveModal(false);
                CustomNotify({ type: 'success', message: response.message });
            }
        }
    }

    const handleCloseArchiveModal = () => {
        setArchiveModal(false);
    }
    const filters = [
        {name: 'businessUnits', fieldType: "multiSelect", options: businessUnits, placeholder: "Business unit", filterType: 'search' },
        {name: 'name', fieldType: "text", placeholder: "Name", filterType: 'search' },
        {name: 'email', fieldType: "text", placeholder: "Email", filterType: 'search' },
        {name: 'mobileNumber', fieldType: "text", placeholder: "Mobile number", filterType: 'search' },
        {name: 'status', fieldType: "singleSelect", options: convertArrayToOptions(UserStatus), placeholder: "Status", filterType: 'search' },
    ];
    return (
        <>
            <div className="search-bar">
                <Title title={t("Manage candidates")} />
            </div>
            <div className="form-height-dashboard">
                <div className="p-5 border rounded-4 table-custom">
                    <div className="table-top-buttons-block">
                        <Link
                            to={CREATE_CANDIDATE}
                            className="form-button float-end d-flex align-items-center px-3 text-decoration-none text-uppercase"
                        >
                            + {t("Create candidate")}
                        </Link>
                    </div>
                    <div className="table-responsive manage-candidates">
                        <FilterOrganism
                            dispatch={dispatch}
                            history={history}
                            updatePageFilters={updatePageFilters}
                            filters={filters}
                            handleRefresh={handleRefresh}
                        />
                        <table className="table table-hover">
                            <thead>
                                <tr className="TableHeader">
                                    {CandidatesTableHeader?.map((header: any) => (
                                        <th key={header?.alias}>
                                            <>
                                                {t(header.name)}
                                                {header?.issort && (
                                                    <SortAtomForManagePage
                                                        value={{ alias: header?.alias }}
                                                        dispatch={dispatch}
                                                        history={history}
                                                        updatePageFilters={updatePageFilters}
                                                        handleRefresh={handleRefresh}
                                                    />
                                                )}
                                            </>
                                        </th>
                                    ))}
                                    <th>{t("Actions")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {candidates?.length > 0 ? (
                                    candidates?.map((candidate: CandidateData) => (
                                        <tr key={candidate.id}>
                                            <td>{candidate?.businessUnits}</td>
                                            <td style={{ cursor: 'pointer' }} onClick={() => handleClick(candidate.id)}>{candidate?.name}</td>
                                            <td>{candidate?.email}</td>
                                            <td>{candidate?.mobileNumber}</td>
                                            <td>
                                                <span
                                                    className={`d-inline-block rounded-circle status-icon bg-${candidate?.status === 0
                                                        ? "warning"
                                                        : candidate?.status === 1
                                                            ? "success"
                                                            : candidate?.status === 2
                                                                ? "danger"
                                                                : "secondary"
                                                        }`}
                                                    data-toggle="tooltip"
                                                    title={
                                                        candidate?.status === 0
                                                            ? UserStatus[0]
                                                            : candidate?.status === 1
                                                                ? UserStatus[1]
                                                                : candidate?.status === 2
                                                                    ? UserStatus[2]
                                                                    : UserStatus[3]
                                                    }
                                                ></span>
                                            </td>
                                            <td>
                                                <div>
                                                    <Link
                                                        to={`${EDIT_CANDIDATE}/${candidate.id}`}
                                                        className="btn p-0 border-0 me-2"
                                                        title="Edit"
                                                    >
                                                        <EditIcon />
                                                    </Link>
                                                    <Link
                                                        to={`${PATH_MATCHING_EMPLOYEE}/${candidate.id}`}
                                                        className="btn p-0 border-0 me-2"
                                                        title="Matching"
                                                    >
                                                        <Matching />
                                                    </Link>
                                                    <Button
                                                        title={t("")}
                                                        className="btn p-0 border-0"
                                                        handleClick={() => handleFollowup(candidate?.id, candidate?.businessUnitId)}
                                                    >
                                                        <FollowUpIcon />
                                                    </Button>
                                                    <Button
                                                        title={t("")}
                                                        className="btn p-0 border-0"
                                                        handleClick={() => handleArchive(candidate.id)}
                                                    >
                                                        <ArchieveIcon />
                                                    </Button>
                                                    <Link
                                                        to={`${MANAGE_WAGE_PROPOSAL}/${candidate.id}`}
                                                        className="btn p-0 border-0 me-2"
                                                        title="wage porposal"
                                                    >
                                                        <AddWageProposalIcon />
                                                    </Link>
                                                    <Link
                                                        to={`${MANAGE_CONTRACT_PROPOSAL}/${candidate.id}`}
                                                        className="btn p-0 border-0 me-2"
                                                        title="contract proposal"
                                                    >
                                                        <AddContractIcon />
                                                    </Link>
                                                </div>

                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr className="border">
                                        <td colSpan={6} className="border-0 text-center py-3">
                                            <span className="text-danger fw-bold">No records</span>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="">
                        <PaginationWithPerPage
                            handleRefresh={handleRefresh}
                            dispatch={dispatch}
                            history={history}
                            pageData={pageData}
                        />
                    </div>
                </div>
            </div>
            <div className="col-md-6 align-self-center mb-3">
                <Link
                    to="/"
                    className="text-uppercase back-btn text-decoration-underline"
                >
                    Back
                </Link>
            </div>
            {followupSideBar && (
                <RightSideBar
                    title={"Follow-ups"}
                    entityType={"candidate"}
                    businessUnit={entity.businessUnit}
                    entityId={entity.entityId}
                    onClose={handleCloseSidebar}
                    getWidth={getWidth}
                    recordsLimit={10}
                    className="right-sidebar p-3"
                    titleClassName="mt-4"
                    destination="/manage-candidates"
                />
            )}
            {archiveModal && (
                <ModalPopup
                    title={"Archive"}
                    show={archiveModal}
                    onHide={handleCloseArchiveModal}
                    body={t("Are you sure you want to archive this candidate ?")}
                    onConfirmButtonClick={handleArchiveConfirm}
                    onCloseButtonClick={handleCloseArchiveModal}
                    confirmTitle={t("Yes")}
                    closeTitle={t("No")}
                />
            )}
        </>
    );
};

export default ManageCandidates;
