import React, { useEffect, useState } from "react";
import {
  HotlistEmployeeTableHeader,
  HotlistProjectTableHeader,
} from "TableHeader";
import SearchModel from "components/atoms/SearchModel";
import TitleFieldMolecule from "components/molecules/TitleField";
import { t } from "../translation/Translation";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { M_MASTER_DATA } from "constants/Constants";
import { ApiCall } from "services/ApiServices";
import {
  DELETE_HOTLIST_DATA,
  GET_HOTLIST_DATA,
  SAVE_HOTLIST_DATA,
} from "routes/ApiEndpoints";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import Button from "components/atoms/Button";
import Pagination from "components/atoms/Pagination";
import ModalPopup from "components/atoms/ModalPopup";
import CustomNotify from "components/atoms/CustomNotify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddEmployeeModal from "../matching/addEmployeeModel";
import AddProjectModal from "../matching/addProjectModel";
import { PATH_MASTER_DATA, PATH_HR_PLANNING } from "constants/Paths";
import TableStructure from "components/atoms/TableStructure";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { IconData } from "services/IconData";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import BackButton from "components/atoms/BackButton";

export interface Hotlist {
  id?: number;
  value: string;
}
interface HotlistProps {
  onChange?: <React, changeHandler>() => void;
  handleSubmitAction?: (clear: boolean) => void;
}
interface HotlistData {
  id: number;
  name: string;
  location: string;
  contract_type: string;
  companyName: string;
  function: string;
  from: string;
  to: string;
  contract_sign: string;
}

const Hotlist: React.FC<HotlistProps> = ({ }) => {
  const [hotlistData, setHotlistData] = useState<HotlistData[]>([]);
  const [selectedOption, setSelectedOption] = useState("employee");
  const [currentPage, setCurrentPage] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<any>(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState<string>("");
  const [submit, setSubmit] = useState(true);
  const navigate = useNavigate();
  const options = [
    { label: "Employee", value: "employee" },
    { label: "Project", value: "project" },
  ];
  const [employeeId, setEmployeeId] = useState<number>(0);
  const [id, setId] = useState({
    projectId: null,
    companyId: null,
  });
  const [iconStatus, setIconStatus] = useState(false);
  var url = useLocation();
  const [searchData, setSearchData] = useState("");
  const employeeColumns = [
    "name",
    "location",
    "contractType",
    "function",
    "startDate",
    "endDate",
    "status",
  ];
  const projectColumns = [
    "name",
    "companyName",
    "location",
    "startDate",
    "endDate",
    //"projectStatus",
  ];

  // useEffect(() => {
  //   const fetchOData = async () => {
  //     try {
  //       const response = ApiCall.getService(
  //         GET_HOTLIST_DATA,
  //         "GET",
  //         M_MASTER_DATA
  //       );
  //       if (response?.status === 200) {
  //         setHotlistData(response.data);
  //         setTotalPages(response.totalPages);
  //       }
  //     } catch (error) {}
  //   };
  //   fetchOData();
  // }, [searchData, selectedOption]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const postData = {
          selectedOption: selectedOption,
          currentPage: currentPage,
        };
        const response = await ApiCall.service(
          GET_HOTLIST_DATA,
          "POST",
          postData,
          false,
          M_MASTER_DATA
        );

        if (response?.status === 200) {
          setHotlistData(response.data);
          setTotalPages(response.totalPages);
        }
      } catch (error) { }
    };
    fetchData();
  }, [currentPage, submit, selectedOption]);

  // Calculate the total number of pages
  const [totalPages, setTotalPages] = useState<number>(0);

  // Handle search input change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchData(e.target.value);
    setCurrentPage(1); // Reset to the first page when the search query changes
  };

  // Filter the data based on the search query
  const filteredData = hotlistData.filter(
    (item: HotlistData) =>
      item.name?.toLowerCase().includes(searchData.toLowerCase()) ||
      item.companyName?.toLowerCase().includes(searchData.toLowerCase()) ||
      item.location?.toLowerCase().includes(searchData.toLowerCase())
  );

  //Closing add modal
  const handleClose = () => {
    setShowAddModal(false);
  };

  // Handle dropdown change
  const handleDropdownChange = (option: any) => {
    setSelectedOption(option.value);
  };

  const handleDelete = (entities: any) => {
    setShowDeleteModal(true);
    setDeleteId(entities.id);
  };
  const handleModalClose = () => {
    setShowDeleteModal(false);
  };
  const handleSaveChanges = async () => {
    setShowDeleteModal(false);
    if (deleteId) {
      const id = {
        id: deleteId,
      };
      const response = await ApiCall.service(
        DELETE_HOTLIST_DATA,
        "POST",
        id,
        false,
        M_MASTER_DATA
      );
      if (response.status === 200) {
        setSubmit(!submit);
        CustomNotify({
          type: "success",
          message: "Deleted  successfully from  hotlist",
        });
      } else {
        CustomNotify({ type: "success", message: "unableto delete" });
      }
    }
  };

  //Handle page Changes
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //Handle reset & search buttons
  const handleSubmit = async (e: any, clear: boolean = false) => {
    e.preventDefault();
    if (clear) {
      navigate({ search: "" });
      setSearchData("");
    }
    setSubmit(!submit);
    setCurrentPage(1);
  };

  function handleAddPlanning(value: any): void {
    navigate(`${PATH_HR_PLANNING}?employee=${value.id}`);
  }

  //Handle add to project
  function handleAddProject(entities: any): void {
    setId({
      ...id,
      projectId: entities.projectId,
      companyId: entities.companyId,
    });
    setEmployeeId(entities.user_id);

    const postData = {
      id: entities.id,
    };
    // ApiCall.service(
    //   SAVE_HOTLIST_DATA,
    //   "POST",
    //   postData,
    //   false,
    //   M_MASTER_DATA
    // ).then((response) => {
    //   // console.log(response);
    // });

    setShowAddModal(true);
    setSelectedProject(entities.name);
  }
  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Hotlist",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <div className="search-bar">
        <Title title={t("Manage hotlist")} />
        <div className="row ">
          <div className="col-3">
            <SelectWithSearch
              title={t("Category")}
              name="category"
              isMandatory={true}
              search={true}
              options={options}
              placeHolder={t("Select")}
              value={selectedOption == "" ? "employee" : selectedOption}
              onChange={handleDropdownChange}
              isMulti={false}
              className="select-field"
            />
          </div>
          <div className="col-3 searchBtnWrapper">
            <SearchModel
              className="form-control"
              placeHolder={t("Search")}
              onChange={handleSearchChange}
              value={searchData}
            />
          </div>
          <div className="col-3 searchBtnWrapper">
            <div className="d-flex align-items-center">
              <ResetBtn handleResetClick={(e) => handleSubmit(e, true)} />
              <SearchBtn handleSearchClick={handleSubmit} />
            </div>
          </div>
        </div>
      </div>
      <div className="position-relative tableMainWrapper">
        <div className="table-responsive  tableSection">
          <TableStructure
            isAction
            headers={
              selectedOption === "project"
                ? HotlistProjectTableHeader
                : HotlistEmployeeTableHeader
            }
            data={filteredData}
            values={
              selectedOption === "project" ? projectColumns : employeeColumns
            }

            handleDelete={handleDelete}
            handleAddProject={handleAddProject} //add to project icon
            handleAddPlanning={handleAddPlanning}
            permissionType={"Hotlist"}
            // searchStatus={hotlistData.status}
          />

          <div
            className="pagination justify-content-center align-items-center"
            style={{ paddingTop: "0.25vw" }}
          >
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
      <ModalPopup
        show={showDeleteModal}
        onHide={handleModalClose}
        title={t("Delete confirmation")}
        body={t("Are you sure want to delete?")}
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle={t("No")}
        confirmTitle={t("Yes")}
      />
      {showAddModal ? (
        selectedOption === "project" ? (
          <AddEmployeeModal
            handleClose={handleClose}
            projectName={selectedProject}
            url={url.pathname + "/" + id.companyId + "/" + id.projectId}
          />
        ) : (
          <AddProjectModal
            handleClose={handleClose}
            url={url.pathname + "/" + employeeId}
          />
        )
      ) : (
        ""
      )}
      {/* Back Button */}
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ padding: "1vw 0" }}
      >
        <BackButton />
      </div>
    </AccessControl>
  );
};
export default Hotlist;
