import React from "react";
import Button from "./Button";
import { t } from "pages/microservices/masterData/translation/Translation";
import ArchieveIcon from "static/images/ArchiveIcon";
import Unarchive from "static/images/UnarchiveIcon";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import { UserStatus } from "constants/Constants";
import AddProjectIcon from "static/images/AddProjectIcon";
import AddPlanningIcon from "static/images/AddPlanningIcon";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import ViewIcon from "static/images/ViewIcon";
import CloneIcon from "static/images/CloneIcon";
import ScreeningIcon from "static/images/ScreeningIcon";
import SelectedEmployeeIcon from "static/images/SelectedEmployeeIcon";
import AddEmployeeIcon from "static/images/AddEmployeeIcon";
import AddedProjectIcon from "static/images/AddedProjectIcon";
import SendMailIcon from "static/images/sendMailIcon";
import BlackHeartIcon from "static/images/BlackHeartICon";
import WhiteHeartIcon from "static/images/whiteHeartIcon";
import PhoneCallIcon from "static/images/phoneIcon";
import FollowUpIcon from "static/images/FollowUpIcon";
import { useDispatch } from "react-redux";
import { updatePageFilters } from "store/pageHistory/pageHistorySlice";
import SortAtomForManagePage from "./SortAtomForManagePage";

interface Props {
  sort?: any;
  data?: any;
  headers?: any;
  values?: any;
  isAction?: boolean;
  actionText?: string;
  actionClass?: string;
  editClass?: string;
  viewClass?: string;
  cloneClass?: string;
  deleteClass?: string;
  archiveClass?: string;
  unArchiveClass?: string;
  barcodeClass?: string;
  AddProjectClass?: string;
  AddPlanningClass?: string;
  AddHotlistClass?: string;
  ScreeningClass?: string;
  handleEdit?: (value: any) => void;
  handleView?: (value: any) => void;
  handleClone?: (value: any) => void;
  handleDelete?: (value: any) => void;
  handleArchive?: (value: any) => void;
  handleUnArchive?: (value: any) => void;
  handleBarcode?: (value: any) => void;
  handleDownload?: (value: any) => void;
  handleMoveBackItem?: (value: any) => void;
  handleAddProject?: (value: any) => void;
  handleAddPlanning?: (value: any) => void;
  handleAddHotlist?: (value: any) => void;
  handleScreening?: (value: any) => void;
  handleClick?: (value: any) => void;
  handleAddEmployee?: (value: any) => void;
  handleSendMail?: (value: any) => void;
  handlePhoneCall?: (value: any) => void;
  handleFallowUp?: (value: any) => void;
  history?: any;
  permissionType?: any;
  searchStatus?: any;
  clickStatus?: any;
  employeeAddedToProject?: any;
  projectAddedToEmployee?: any;
  handleRefresh?: () => void;
}

const TableStructure: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const userData = useSelector(selectAuth);  
  return (
    <table className="table">
      <thead className="TableHeader">
        <tr>
          {props.headers?.map((value: any) => (
            <th key={value.name} className="border-bottom-0">
              {value.name}{" "}
              {value?.issort && (
                <SortAtomForManagePage value={value} dispatch={dispatch} history={props?.history} updatePageFilters={updatePageFilters} handleRefresh={props?.handleRefresh}/>
              )}
            </th>
          ))}
          {
            <th
              className={`border-bottom-0 ${props.actionClass ?? "table_actions"
                }`}
            >
              <AccessControl
                requiredPermissions={[
                  {
                    permission: props.permissionType,
                    update: true,
                    delete: true,
                  },
                ]}
                actions={true}
                strict={false}
                override={userData.isSuperAdmin}
              >
                {props.actionText ?? "Actions"}
              </AccessControl>
            </th>
          }
        </tr>
      </thead>

      <tbody>
        {props.data && Object.keys(props.data).length > 0 ? (
          Object.values(props.data).map((value: any, index) => (
            <tr key={index} className="align-middle">
              {props.values?.map((column: any, colIndex: number) => (
                <td
                  key={colIndex}
                  className={
                    column === "first_name" ||
                      column === "name" ||
                      column === "companyName" ||
                      column === "companyEmail" ||
                      (column === "companyPhoneNumber" && props.clickStatus)
                      ? "cursor-pointer nameHoverClass"
                      : "text-break "
                  }
                  onClick={
                    column === "first_name" ||
                      column === "name" ||
                      column === "companyName" ||
                      column === "companyEmail" ||
                      (column === "companyPhoneNumber" && props.clickStatus)
                      ? () => props.handleClick?.(value)
                      : undefined
                  }
                >
                  {column !== "status" ? (
                    value[column]
                  ) : (
                    <span
                      className={`d-inline-block rounded-circle status-icon bg-${value[column] == 0 || value[column] == "false"
                        ? "warning"
                        : value[column] == 1 || value[column] == "true"
                          ? "success"
                          : value[column] == 2
                            ? "danger"
                            : "secondary"
                        }`}
                      data-toggle="tooltip"
                      title={
                        value.status == 0 || value.status == "false"
                          ? UserStatus[0]
                          : value.status === 1 || value.status === true
                            ? UserStatus[1]
                            : value.status === 2
                              ? UserStatus[2]
                              : value.status === 5
                                ? UserStatus[5]
                                : value.status === 6
                                  ? UserStatus[6]
                                  : UserStatus[3]
                      }
                    ></span>
                  )}
                </td>
              ))}
              {
                <td className="table-action-icons px-2">
                  {props.isAction && props.handleEdit && value.status !== 3 && (
                    <AccessControl
                      requiredPermissions={[
                        {
                          permission: props.permissionType,
                          update: true,
                        },
                      ]}
                      override={userData.isSuperAdmin}
                    >
                      <Button
                        title={<EditIcon />}
                        className={
                          props.editClass ??
                          "table-action-btn border-0  p-0 me-2"
                        }
                        handleClick={() => props.handleEdit?.(value)}
                        tooltip={t("Edit")}
                      />
                    </AccessControl>
                  )}
                  {props.isAction && props.handleView && value.status !== 3 && (
                    <AccessControl
                      requiredPermissions={[
                        {
                          permission: props.permissionType,
                          read: true,
                        },
                      ]}
                      override={userData.isSuperAdmin}
                    >
                      <Button
                        title={<ViewIcon />}
                        className={
                          props.viewClass ??
                          "table-action-btn border-0  p-0 me-2"
                        }
                        handleClick={() => props.handleView?.(value)}
                        tooltip={t("View")}
                      />
                    </AccessControl>
                  )}
                  {props.isAction &&
                    props.handleClone &&
                    value.status !== 3 && (
                      <Button
                        title={<CloneIcon />}
                        className={
                          props.cloneClass ??
                          "table-action-btn border-0  p-0 me-2"
                        }
                        handleClick={() => props.handleClone?.(value)}
                        tooltip={t("Clone")}
                      />
                    )}
                  {props.isAction &&
                    props.handleClone &&
                    value.status !== 3 && (
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: props.permissionType,
                            delete: true,
                          },
                        ]}
                        override={userData.isSuperAdmin}
                      ></AccessControl>
                    )}
                  {props.isAction &&
                    props.handleDelete &&
                    value.status !== 3 && (
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: props.permissionType,
                            delete: true,
                          },
                        ]}
                        override={userData.isSuperAdmin}
                      >
                        <Button
                          title={<DeleteIcon />}
                          className={
                            props.deleteClass ??
                            "table-action-btn border-0  p-0 me-2"
                          }
                          handleClick={() => props.handleDelete?.(value)}
                          tooltip={t("Delete")}
                        />
                      </AccessControl>
                    )}
                  {props.isAction &&
                    props.handleFallowUp &&
                    value.status !== 3 && (
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: props.permissionType,
                            delete: true,
                          },
                        ]}
                        override={userData.isSuperAdmin}
                      >
                        <Button
                          title={<FollowUpIcon />}
                          className={
                            props.deleteClass ??
                            "table-action-btn border-0  p-0 me-2"
                          }
                          handleClick={() => props.handleFallowUp?.(value)}
                          tooltip={t("Delete")}
                        />
                      </AccessControl>
                    )}
                  {props.isAction &&
                    props.handlePhoneCall &&
                    value.status !== 3 && (
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: props.permissionType,
                            delete: true,
                          },
                        ]}
                        override={userData.isSuperAdmin}
                      >
                        <Button
                          title={<PhoneCallIcon />}
                          className={
                            props.deleteClass ??
                            "table-action-btn border-0  p-0 me-2"
                          }
                          handleClick={() => props.handlePhoneCall?.(value)}
                          tooltip={t("Phone call")}
                        />
                      </AccessControl>
                    )}
                  {props.isAction &&
                    props.handleUnArchive &&
                    value.status == 3 && (
                      <Button
                        title={value.status ? <Unarchive /> : <Unarchive />}
                        className={
                          props.unArchiveClass ??
                          "table-action-btn border-0  p-0 me-2"
                        }
                        handleClick={() => props.handleUnArchive?.(value)}
                        tooltip={t("Unarchive")}
                      />
                    )}
                  {props.isAction &&
                    props.handleArchive &&
                    value.status !== 3 && (
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: props.permissionType,
                            delete: true,
                          },
                        ]}
                        override={userData.isSuperAdmin}
                      >
                        <Button
                          title={
                            value.status ? <ArchieveIcon /> : <ArchieveIcon />
                          }
                          className={
                            props.archiveClass ??
                            "table-action-btn border-0  p-0 me-2"
                          }
                          handleClick={() => props.handleArchive?.(value)}
                          tooltip={t("Archive")}
                        />
                      </AccessControl>
                    )}
                  {props.isAction && props.handleAddProject && (
                    <Button
                      title={
                        value.addProjectStatus && value.addProjectStatus.length > 0 ? (
                          <AddedProjectIcon />
                        ) : (
                          <AddProjectIcon />
                        )
                      }
                      className={
                        props.AddProjectClass ??
                        "table-action-btn border-0  p-0 me-2"
                      }
                      handleClick={() => props.handleAddProject?.(value)}
                    />
                  )}
                  {props.isAction &&
                    props.handleSendMail &&
                    value.status !== 3 && (
                      <Button
                        title={<SendMailIcon />}
                        className={
                          props.AddProjectClass ??
                          "table-action-btn border-0  p-0 me-2"
                        }
                        handleClick={() => props.handleSendMail?.(value)}
                        tooltip={t("Send password reset mail")}
                      />
                    )}
                  {props.isAction && props.handleAddPlanning && (
                    <Button
                      title={
                        value.status ? <AddPlanningIcon /> : <AddPlanningIcon />
                      }
                      className={
                        props.AddPlanningClass ??
                        "table-action-btn border-0  p-0 me-2"
                      }
                      handleClick={() => props.handleAddPlanning?.(value)}
                    />
                  )}
                  {props.isAction && props.handleAddHotlist && (
                    <Button
                      title={value ? <BlackHeartIcon /> : <WhiteHeartIcon />}
                      className={
                        props.AddHotlistClass ??
                        "table-action-btn border-0  p-0 me-2"
                      }
                      handleClick={() => props.handleAddHotlist?.(value)}
                    />
                  )}
                  {props.isAction && props.handleScreening && (
                    <Button
                      title={value ? <ScreeningIcon /> : <ScreeningIcon />}
                      className={
                        props.ScreeningClass ??
                        "table-action-btn border-0  p-0 me-2"
                      }
                      handleClick={() => props.handleScreening?.(value)}
                    />
                  )}
                  {props.isAction && props.handleAddEmployee && (
                    <Button
                      title={
                        props.employeeAddedToProject === true ? (
                          <SelectedEmployeeIcon />
                        ) : (
                          <AddEmployeeIcon />
                        )
                      }
                      className={
                        props.AddProjectClass ??
                        "table-action-btn border-0  p-0 me-2"
                      }
                      handleClick={() => props.handleAddEmployee?.(value)}
                    />
                  )}
                </td>
              }
            </tr>
          ))
        ) : (
          <>
            <tr>
              <td colSpan={8} className="text-center">
                {t("No records found")}
              </td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};

export default TableStructure;
