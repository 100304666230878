import { t } from "pages/microservices/masterData/translation/Translation";
import React from "react";
import EmployeeBox from "../atoms/employeeBox";
import {
  useEmployee,
  useEmployeeDispatch,
} from "../planning-redux/context/EmployeeContext/EmployeeContext";

const PlanningEmployees = () => {
  const { initialEmployeeState } = useEmployee();
  const { dispatch } = useEmployeeDispatch();

  return (
    <>
      <table className="table border border-white table-bordered  planningLeftColumn mb-0 sidebar_bg">
        <thead>
          <tr className="border-0">
            <th
              className="border border-white align-middle p-0 text-center"
              style={{ height: "2vw", lineHeight: "2vw" }}
            >
              {t("Employees")}
            </th>
          </tr>
        </thead>
        <tbody>
          {initialEmployeeState.employees &&
            initialEmployeeState.employees.length > 0 &&
            initialEmployeeState.employees.map((employee) => (
              <tr key={employee.id} className={"p-0 " + (employee.id == 'total' ? "planningCostSticky" : "")}>
                <td style={{ height: "2.05vw" }} className="p-0">
                  {/* <EmployeeBox data={employee.name ? employee.name : ""} bg_color={"white"} color={"black"} /> */}
                  <EmployeeBox
                    data={employee.name ? employee.name : ""}
                    bg_color={"#e8f8fb"}
                    color={"black"}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default PlanningEmployees;

// import React from 'react';
// import EmployeeBox from '../atoms/employeeBox';
// import { useEmployee, useEmployeeDispatch } from '../planning-redux/context/EmployeeContext/EmployeeContext';

// const PlanningEmployees = () =>  {
//     const { initialEmployeeState } = useEmployee();
//     const { dispatch } = useEmployeeDispatch();

//     const setEmployees = () => {
//         return Object.values(initialEmployeeState).map((employee) => (
//             <tr key={employee.id} className='border-0'>
//             <td style={{ height: "70px"}} >
//                 {/* <EmployeeBox data={employee.name ? employee.name : ""} bg_color={"white"} color={"black"} /> */}
//                 <EmployeeBox data={employee.name ? employee.name : ""} bg_color={"#e8f8fb"} color={"black"} />
//             </td>
//         </tr>
//         ));

//       };

//     return (
//         <>
//             <table className='w-100 border-end'>
//                 <thead>
//                     <tr className='border-0'>
//                         <th className='py-3'>Employees</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {initialEmployeeState && Object.keys(initialEmployeeState).length > 0 &&
//                     setEmployees()
//                     }
//                 </tbody>
//             </table>
//         </>
//     );
// }

// export default PlanningEmployees;
