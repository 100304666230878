// Paths
// Business Unit paths
export const PATH_MANAGE_BUSINESS_UNIT = "/business-unit/manage";
export const PATH_CREATE_BUSINESS_UNIT = "/business-unit/create";
export const PATH_EDIT_BUSINESS_UNIT = "/business-unit/edit";

// Dashboards
export const PATH_MASTER_DATA = "/config/settings";
export const PATH_HOME = "/dashboard";

// Employee Types
export const MANAGE_EMPLOYEE_TYPE = "/manage/employee-type";

// Email templates

export const PATH_EMAIL_TEMPLATE_MANAGE = "/manage-email-templates";
export const PATH_EMAIL_TEMPLATE_CREATE = "/email-template/create";
export const PATH_EMAIL_TEMPLATE_EDIT = "/email-template/edit";
export const PATH_EMAIL_TEMPLATE_VIEW = "/email-template/view";

// Tags
export const PATH_MANAGE_TAGS = "/manage/tags";
export const PATH_CREATE_TAGS = "/create/tags/";

//shifts
export const PATH_MANAGE_SHIFTS = "/shifts";
export const PATH_CREATE_SHIFT = "/shift/create";
export const PATH_EDIT_SHIFT = "/shift/edit";

// Project
export const PATH_PROJECTS_MANAGE = "/projects";
export const PATH_PROJECTS_CREATE = "/project/create";
export const PATH_PROJECTS_EDIT = "/project/edit";
export const PATH_PROJECT_PROPOSAL = "/proposal";

//contact Perosn
export const MANAGE_CONTACT_PERSON = "/manage-contact/person/";

// Template
export const PATH_MANAGE_TEMPLATE = "/templates";
export const PATH_TEMPLATE_EDIT = "/template/edit";
export const PATH_TEMPLATE_VIEW = "/template/view";

//proposal agreement
export const PATH_PROPOSAL_AGREEMENT = "/proposal/agreement";

//Paritair comitee
export const PATH_PC_MANAGE = "/pc";
export const PATH_PC_CREATE = "/pc/create";
export const PATH_PROPOSAL_AGREEMENT_MANAGER = "/proposal/agreement/manager"
export const PATH_PC_EDIT = "/pc/edit";

//Salary Benefits
export const PATH_SALARY_BENEFITS_MANAGE = "/salary-benefits"
export const PATH_SALARY_BENEFITS_CREATE = "/salary-benefits/create"
export const PATH_SALARY_BENEFITS_EDIT = "/salary-benefits/edit"

// Employees
export const PATH_EMPLOYEE_MANAGE = "/employees";
export const PATH_EMPLOYEE_CREATE = "/employees/create";
export const PATH_EMPLOYEE_EDIT = "/employee/edit";
export const PATH_EMPLOYEE_FOLLOWUP = "/employee/followup";

export const PATH_MANAGE_EMPLOYEE_TYPE = "/manage/employee-type"
export const PATH_CREATE_EMPLOYEE_TYPE = "/create/employee-type"


export const PATH_CONFIG_ELEMENTS_MANAGE = "/master-data/config-elements"
export const PATH_CONFIG_ELEMENTS_CREATE = "/master-data/config-elements/edit"

//My profile
export const PATH_PROFILE = "/profile"
export const PATH_PROFILE_EDIT = "/profile/edit"

//competence
export const PATH_COMPETENCE_MANAGE = "/competence";
export const PATH_COMPETENCE_CREATE = "/competence/create";
export const PATH_COMPETENCE_EDIT = "/competence/edit";
export const PATH_COMPETENCE_VIEW = "/competence/view";

//Timetable
export const PATH_MANAGE_TIMETABLE = "/timetable";
export const PATH_TIMETABLE_CREATE = "/timetable/create";
export const PATH_TIMETABLE_EDIT = "/timetable/edit";

export const PATH_MATCHING_EMPLOYEE = "/matching/employee"

//Application configuration
export const PATH_APPLICATION_CONFIGURATION = "/app-configuration";

export const PATH_MANAGE_CONFIGURATION = "/app-configuration/manage";

export const PATH_EDIT_APPLICATION_CONFIG = "/app-config/edit";

export const PATH_MATCHING = "/matching"

// Vacancies

export const PATH_VACANCY_MANAGE = '/vacancies';
export const PATH_VACANCY_CREATE = "/vacancy/create";
export const PATH_VACANCY_EDIT = "/vacancy/edit";

// fleet and material
export const PATH_FLEET_AND_MATERIAL = '/fleetAndMaterial'
export const PATH_MANAGE_MATERIAL = "/material"
export const PATH_CREATE_MATERIAL = '/material/create'
export const PATH_EDIT_MATERIAL = '/material/edit'
export const PATH_MANAGE_CAR = "/car"
export const PATH_CREATE_CAR = '/car/create'
export const PATH_EDIT_CAR = '/car/edit'
export const PATH_MANAGE_CLOTHES = "/clothes"
export const PATH_CREATE_CLOTHES = "/clothes/create"
export const PATH_EDIT_CLOTHES = '/clothes/edit'
export const PATH_MANAGE_SUPPLIER = "/supplier"
export const PATH_CREATE_SUPPLIER = "/supplier/create"
export const PATH_EDIT_SUPPLIER = "/supplier/edit"
export const PATH_MANAGE_CLIENT = "/client"
export const PATH_CREATE_CLIENT = "/client/create"
export const PATH_EDIT_CLIENT = "/client/edit"
export const PATH_MANAGE_PURCHASE_ORDER = "/purchaseOrder"
export const PATH_CREATE_PURCHASE_ORDER = "/purchaseOrder/create"
export const PATH_EDIT_PURCHASE_ORDER = "/purchaseOrder/edit"
export const PATH_VIEW_PURCHASE_ORDER = "/purchaseOrder/view"
export const PATH_EMPLOYEE_FLEET_OVERVIEW = "/employee-fleetOverView"
export const PATH_MANAGE_FLEET_LOCATION = "/fleet-locations"
export const PATH_CREATE_FLEET_LOCATION = "/fleet-locations/create"
export const PATH_EDIT_FLEET_LOCATION = "/fleet-locations/edit"




//Hotlist
export const PATH_HOTLIST = "/hotlist";

//Document
export const PATH_DOCUMENT = "/documents";
//Questions
export const ADD_QUESTION = "/add/question/:category";
export const EDIT_QUESTION = "/edit/question/:category/:id";
export const CLONE_QUESTION = "/clone/question/:category/:id";
export const VIEW_QUESTION = "/view/question/:category/:id";
export const MANAGE_QUESIONS = "/manage-questions";

//Screening
export const PATH_CANDIDATE_SCREENING = "/candidate/screening";
export const PATH_MANAGE_APPLICANTS = "/manage-applicants";
export const PATH_SCREENING_ASSESSMENT = "/screening/assessment/:screeningId/:candidateId";
export const PATH_MANAGE_SCREENING = "/manage-screening/:candidateId";

//Planning
export const PATH_HR_PLANNING = "/hr-planning";
export const PATH_SALES_PLANNING = "/sales-planning";

// wage Proposal
export const PATH_WAGE_PROPOSAL = "/wage/proposal";
export const PATH_CREATE_CONTRACT = "/create/contract";
export const PATH_CONTRACT_OVERVIEW = "/contracts";
export const PATH_ACCESS_DENIED = "/access/denied";

//candidates
export const PATH_APPLICANT_CREATE = "/applicant/create"
//Holiday codes and public holidays
export const HOLIDAY_CONFIGS = "/holiday-config";
export const MANAGE_HOLIDAY_CODES = "/manage/holiday-codes";
export const CREATE_HOLIDAY_CODES = "/holiday-codes/create";
export const EDIT_HOLIDAY_CODE = "/holiday-codes/edit";

export const MANAGE_PUBLIC_HOLIDAYS = "/manage/public-holidays"
export const CREATE_PUBLIC_HOLIDAY = "/public-holiday/create";
export const EDIT_PUBLIC_HOLIDAY = "/public-holiday/edit";

//Holiday per PC
export const MANAGE_HOLIDAY_PER_PC = "/manage/holiday-per-pc";
export const LINK_HOLIDAY_PER_PC = "/link/holiday-per-pc";
export const EDIT_LINK_HOLIDAY_PER_PC = "/edit/holiday-per-pc";

//Leave flow
export const MANAGE_EMPLOYEE_LEAVES = "/manage/employee-leaves";
export const EMPLOYEE_LEAVE_COUNT = "/leave-count/employee";
export const MANAGE_EMPLOYER_LEAVES = "/manage/employer-leaves";
export const EMPLOYER_LEAVE_COUNT = "/leave-count/employer";

//Apply for leave
export const APPLY_LEAVE_OF_EMPLOYEE = "/leave/apply";
export const VIEW_LEAVE_OF_EMPLOYEE = "/leave/view";
export const EDIT_LEAVE_OF_EMPLOYEE = "/leave/edit";
export const ADD_LEAVE = "/employer/leave/add";
export const VIEW_LEAVE_THROUGH_EMPLOYER = "/employer/leave/view";
export const EDIT_LEAVE_THROUGH_EMPLOYER = "/employer/leave/edit";

// Timesheets
export const PATH_MANAGE_TIMEHSHEETS = '/timesheets'

// Follow up todo
export const PATH_TODO_CREATE = "/todo/create"
export const PATH_TODO_MANAGE = "/todo"
export const PATH_TODO_VIEW = "/todo/view"

//Contract template
export const PATH_ADD_CONTRACT_TEMPLATE = "/add/contract-template";
export const PATH_MANAGE_CONTRACT_TEMPLATE = "/manage/contract-templates";
export const PATH_VIEW_CONTRACT_TEMPLATE = "/view/contract-template";
export const PATH_CLONE_CONTRACT_TEMPLATE = "/clone/contract-template";

//Pay roll
export const PATH_WORK_PAY_BILL_ROLL = "/pay-roll";

//Translations
export const PATH_MANAGE_TRANSLATIONS = "/manage-translations";
//temp agency
export const PATH_MANAGE_TEMP_AGENCY = "/manage/temp-agency";
export const PATH_CREATE_TEMP_AGENCY = "/create/temp-agency";
export const PATH_EDIT_TEMP_AGENCY = "/edit/temp-agency";
export const PATH_MANAGECOEFF_DEVIATION = "/manage/coeff/deviation";
export const PATH_MANAGE_WAGE_ELEMENT = "/manage/wage/element";
export const PATH_EDIT_COEEFICIENT = "/edit/coeff-deviation";
export const PATH_TEMP_AGENCY_PROPOSAL = "/temp-agency/proposal";

//Margin calculation
export const PATH_MARGIN_CALCULATOR = "/margin-calculator";

//Partena
export const PATH_MANAGE_PARTENA = "/partena";
export const PATH_ADD_PARTENA = "/add/partena";
export const PATH_PARTENA_CALCULATION = "/partena/calculation";

//candidate 
export const CREATE_CANDIDATE = "/candidate/create";
export const MANAGE_CANDIDATES = "/manage-candidates";
export const EDIT_CANDIDATE = "/candidate/edit";

//candidate corner
export const PATH_CANDIDATE_CORNER = "/applicant/details";

//Wage proposal
export const CREATE_WAGE_PROPOSAL = "/employee/create/wage-proposal";
export const MANAGE_WAGE_PROPOSAL = "/employee/manage/wage-proposal";
export const CREATE_FULL_TIME_INDEFINITE_CONTRACT = "/employee/create/full-time/indefinite-contract";
export const CREATE_FULL_TIME_FIXED_TERM_CONTRACT = "/employee/create/full-time/fixed-term-contract";
export const CREATE_PART_TIME_INDEFINITE_CONTRACT = "/employee/create/part-time/indefinite-contract";
export const CREATE_PART_TIME_FIXED_TERM_CONTRACT = "/employee/create/part-time/fixed-term-contract";
export const MANAGE_CONTRACT_PROPOSAL = "/employee/manage/contract-proposal";
//Document type
export const PATH_CREATE_DOCUMENT_TYPE = "/document-type/create";
export const PATH_EDIT_DOCUMENT_TYPE = "/document-type/edit";
export const PATH_MANAGE_DOCUMENT_TYPE = "/document-type";
export const CREATE_CONTRACT = "/employee/create/contract-proposal";