import React from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useFormContext } from "pages/microservices/project/context/Context";
import ContactForm from "components/organism/contactForm";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import {
  validateForm,
  validateFormField,
} from "services/validation/ValidationService";
import { ITagAtomProps } from "components/atoms/TagAtom";
import { Option } from "components/common/CommonInterfaces";
import {
  SET_CONTACT_PERSON_OPTION,
  UPDATE_CONTACT_FIELD_ERROR,
  UPDATE_CONTACT_SUBFORM_FIELD,
  UPDATE_FIELD_ERROR,
  UPDATE_RESPONSIBLE_PERSON_FIELD,
  UPDATE_SELECTED_CONTACT_FIELD,
} from "../context/Constants";
import {
  ContactsTabSubformValidationRules,
  ContactsTabValidationRules,
} from "../validationRules/ProjectFormValidationRules";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import ResponsiblePersonForm from "components/organism/responsiblePerson";
import { ApiCall } from "services/ApiServices";
import { CREATE_CONTACT_PERSONS_FROM_PROJECT } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
interface ProjectsProps {
  id?: string | number;
}

const ContactsPersons: React.FC<ProjectsProps> = () => {
  const { state, dispatch } = useFormContext();

  // Init
  const contactsData = state.contactsPersons.data;
  const contactErrors = state.contactsPersons.errors;
  const responsiblePersons = state.responsiblePerson.data;
  const responsiblePersonData = {
    timeSheet: responsiblePersons.timeSheet,
    billing: responsiblePersons.billing,
    leave: responsiblePersons.leave,
    sickness: responsiblePersons.sickness,
    holiday: responsiblePersons.holiday,
    safety: responsiblePersons.safety,
  };
  const options = state.options;

  // Component based
  const selectedContactPersons: Option[] = contactsData.selectedContactPersons;

  // Options
  const contactPersonOptions = options.companyUsers;

  // Subform
  const contact = contactsData.contactsPersons;
  const contactSubformIsVisible = contactsData.contactSubformIsVisible;

  const validation = (
    name: string,
    value: string | boolean | Date | object[] | undefined,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationErrors = validateForm(
      { ...contact, [name]: value },
      ContactsTabSubformValidationRules,
      isSingleFieldValidation ? name : undefined
    );

    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      const updatedErrors = {
        ...contactErrors.contactsPersons,
        [name]: "",
      };

      contactErrors.contactsPersons = updatedErrors;
    } else {
      const updatedErrors = {
        ...contactErrors.contactsPersons,
        [name]: validationErrors[name],
      };
      contactErrors.contactsPersons = updatedErrors;
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleRemoveContact = (indexToRemove: number) => {
    dispatch({
      type: "REMOVE_CONTACT",
      indexToRemove,
    });
    dispatch({
      type: UPDATE_SELECTED_CONTACT_FIELD,
      field: "contactSubformIsVisible",
      value: !contactSubformIsVisible,
    });
  };

  const handleContactChange = (selectedOption: Option[], fieldName: string) => {
    dispatch({
      type: UPDATE_SELECTED_CONTACT_FIELD,
      field: fieldName,
      value: selectedOption,
    });

    // todo: add dispatch for error update
    let validatedFieldError = validateFormField(
      contactsData,
      ContactsTabValidationRules,
      fieldName,
      selectedOption
    );
    dispatch({
      type: UPDATE_FIELD_ERROR,
      fieldName: fieldName,
      error: validatedFieldError,
      tab: "contactsPersons",
    });
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = event.target;
    dispatch({
      type: UPDATE_CONTACT_SUBFORM_FIELD,
      field: name,
      value,
    });
    validation(name, value, true);
  };

  const handlePhoneNumberChange = (
    fieldName: string,
    value: string | undefined
    // index: number
  ) => {
    dispatch({
      type: UPDATE_CONTACT_SUBFORM_FIELD,
      // index,
      field: fieldName,
      value: value,
    });
    validation(fieldName, value, true);
  };

  const handleSelectChange = (selectedOption: any, name: string) => {
    dispatch({
      type: UPDATE_CONTACT_SUBFORM_FIELD,
      field: name,
      value: selectedOption,
      // index,
    });
    validation(name, selectedOption, true);
  };

  const toggleFormVisibility = () => {
    dispatch({
      type: UPDATE_SELECTED_CONTACT_FIELD,
      field: "contactSubformIsVisible",
      value: !contactSubformIsVisible,
    });

    // setIsFormVisible((prevIsVisible) => !prevIsVisible);
  };
  const updateError = (fieldName: string, errorMessage: string) => {
    dispatch({
      type: UPDATE_CONTACT_FIELD_ERROR,
      fieldName,
      error: errorMessage,
      tab: "contactsPersons",
      // index: index, // Make sure you're updating the correct part of the state
    });
  };

  const handleSaveContact = async (contact: any) => {
    const validationErrors = validateForm(
      contact,
      ContactsTabSubformValidationRules
    );

    // If there are validation errors, update the state
    if (Object.keys(validationErrors).length > 0) {
      Object.keys(validationErrors).forEach((field) => {
        updateError(field, validationErrors[field]);
      });
      return;
    }
    const response = await ApiCall.service(
      CREATE_CONTACT_PERSONS_FROM_PROJECT,
      "POST",
      contact,
      true,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      const contactPesonOption = response?.contactPerson;
      dispatch({
        type: SET_CONTACT_PERSON_OPTION,
        field: "companyUsers",
        value: contactPesonOption,
      });
      dispatch({
        type: UPDATE_SELECTED_CONTACT_FIELD,
        field: "contactSubformIsVisible",
        value: !contactSubformIsVisible,
      });
    }
  };
  const renderContactPersonCreateForm = () => {
    return contactSubformIsVisible ? (
      <div>
        <ContactForm
          // key={key}
          // index={index}
          contacts={contact}
          contactErrors={contactErrors.contactsPersons}
          onRemoveContact={handleRemoveContact}
          onFieldChange={handleFieldChange}
          onPhoneNumberChange={handlePhoneNumberChange}
          onSelectChange={handleSelectChange}
          companyOptions={options.company}
          genderOptions={options.gender}
          languageOptions={options.language}
          companyBusinessUnitOptions={options.companyBusinessUnit}
          rolesOptions={options.roles}
          onSaveContact={handleSaveContact}
          locationsOptions={options.locations}
          fromType={"project"}
        />
      </div>
    ) : null;
  };
  const handleResponsiblePersonChange = (
    selectedOption: Option[],
    fieldName: string
  ) => {
    dispatch({
      type: UPDATE_RESPONSIBLE_PERSON_FIELD,
      field: fieldName,
      value: selectedOption,
    });
  };
  return (
    <>
      <div className="form-border" style={{ paddingTop: "3vw" }}>
        <div className="row">
          <div className="col-6">
            <SelectWithSearch
              title="Select contact person"
              name="personcontact"
              isMandatory={true}
              search={true}
              options={contactPersonOptions}
              value={selectedContactPersons}
              placeHolder={t("Select")}
              onChange={(e) => handleContactChange(e, "selectedContactPersons")}
              isMulti={true}
              className="select-field"
              error={contactErrors.selectedContactPersons}
            />
          </div>
          <div className="col-6 addBtnMargin table-action-icons pt-1">
            <span
              onClick={toggleFormVisibility}
              className="table-action-btn cursor-pointer"
              title={contactSubformIsVisible ? t("Minimise") : t("Expand")}
            >
              <FontAwesomeIcon
                icon={contactSubformIsVisible ? faMinus : faPlus}
              />
            </span>
          </div>
        </div>
        {renderContactPersonCreateForm()}
        <ResponsiblePersonForm
          onSelectChange={handleResponsiblePersonChange}
          companyUsers={options.companyUsers}
          responsiblePersonData={responsiblePersonData}
          // responsiblePersonErrors={responsiblePersonErrors}
        />
      </div>

      {/* <Navigation /> */}
    </>
  );
};

export default ContactsPersons;
