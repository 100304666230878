import TitleFieldMolecule from 'components/molecules/TitleField';
import { M_MASTER_DATA } from 'constants/Constants';
import React, { useEffect, useState } from 'react';
import { GET_WAGE_PROPOSAL_DATA } from 'routes/ApiEndpoints';
import { ApiCall } from 'services/ApiServices';
import { t } from '../translation/Translation';
import EditIcon from 'static/images/EditIcon';
import DeleteIcon from 'static/images/DeleteIcon';
import LinkTo from 'components/atoms/LinkTo';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Button from 'components/atoms/Button';
import { CREATE_WAGE_PROPOSAL } from 'constants/Paths';

interface ManageWageProposalProps {
    'id': number | null;
    'user_id': number | null;
    'employeeContractType': string;
    "businessUnit": string;
    "paritairComitee": string;
    "salary": string;
    // "function_name": string;
    // "salary_category": number | null;
    // "salary_type": number | null;
    // "salary_type_description": string;
    // "salary_benefit_types": number[];
    "status": number | null;
}
const ManageWageProposal = () => {
    const queryParam = useParams();
    const candidateId = queryParam.candidateId
    const navigate = useNavigate();
    const [searchData, setSearchData] = useState();
    const [manageData, setManageData] = useState<ManageWageProposalProps[]>([{
        'id': null,
        'user_id': Number(candidateId),
        'employeeContractType': '',
        "businessUnit": '',
        "paritairComitee": '',
        "salary": '',
        // "function_name": '',
        // "salary_category": null,
        // "salary_type": null,
        // "salary_type_description": '',
        // "salary_benefit_types": [],
        "status": null,
    }]);
    const currentPage = 1;
    useEffect(() => {
        const postData = {
            userId: candidateId,
            search: searchData,
            page: currentPage
        }
        ApiCall.service(GET_WAGE_PROPOSAL_DATA, "POST", postData, true, M_MASTER_DATA).then((response) => {
            if (response?.status === 200) {
                setManageData(response?.data);
            }
        });
    }, []);

    const handleSave = () => {

    }
    
    return (
        <>
            <TitleFieldMolecule title={t("Manage wage proposal")} />
            <div className="row w-25 m-auto">
                <LinkTo
                    pagelink={`${CREATE_WAGE_PROPOSAL}/${candidateId}`}
                    title={t("Create wage proposal")}
                    icon={faPlus}
                />
            </div>
            <table className='table border'>
                <thead className='tableHeader'>
                    <tr>
                        <td>Business unit</td>
                        <td>Paritair comitee</td>
                        <td>Employee contract type</td>
                        <td>Salary</td>
                        <td>Actions</td>
                    </tr>
                </thead>
                <tbody>
                    {manageData.length > 0 && manageData.map((data: any) => (
                        <tr>
                            <td>{data.businessUnit}</td>
                            <td>{data.paritairComitee}</td>
                            <td>{data.employeeContractType}</td>
                            <td>{data.salary}</td>
                            <td>
                                <Link
                                    to={`${CREATE_WAGE_PROPOSAL}/${data.userId}/${data.id}`}
                                    className="back-btn text-decoration-underline"
                                >
                                    <EditIcon />
                                </Link>

                                <DeleteIcon />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="row">
                <div className="col">
                    <Link
                        to=""
                        className="back-btn text-decoration-underline"
                        onClick={() => navigate(-1)}
                    >
                        {t("Back")}
                    </Link>
                </div>
                <div className="col">
                    <Button
                        type="submit"
                        title={t("Save")}
                        handleClick={handleSave}
                        className="float-end form-button px-3 shadow-none"
                    />
                </div>
            </div>
        </>
    );
}

export default ManageWageProposal;