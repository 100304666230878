import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "components/atoms/Button";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import { PATH_CREATE_CAR, PATH_EDIT_CAR } from "constants/Paths";
import TableActions from "components/organism/Actions/TableAction";
import { useNavigate } from "react-router-dom";
import {
  CAR_DELETE,
  FLEET_EMP_DELETE,
  FLEET_EMP_CREATE,
} from "routes/ApiEndpoints";
import { ERROR, M_MASTER_DATA } from "constants/Constants";
import Pagination from "components/atoms/Pagination";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import FilterElementsRender from "../FilterElement";
import { fetchOptions } from "../utils/FetchOptions";
import { getStatusColor, getTooltipMessage } from "services/util/UtilService";
import FleetEmployeeModal from "../FleetEmployeeModal";
import {
  validateForm,
  validateRequired,
} from "services/validation/ValidationService";
import { IconData } from "services/IconData";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { updatePageFilters } from "store/pageHistory/pageHistorySlice";
import FilterOrganism from "components/organism/FilterOrganism";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import { CarTableHeader } from "TableHeader";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";

interface ManageCarProps {
  data?: any;
  fleetProps?: any;
  setMaterialData: any;
  handleGetVacany: any;
  dispatch: any;
  history: any;
  pageData: any;
  handleRefresh: () => void;
}

interface CompanyItem {
  value: number;
  label: string;
}

const ManageCar: React.FC<ManageCarProps> = ({
  data,
  fleetProps,
  setMaterialData,
  dispatch,
  history,
  pageData,
  handleRefresh,
  handleGetVacany,
}) => {
  const [id, setId] = useState<number | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    businessUnit: [],
    status: [],
    carType: [],
    location: [],
    companies: [],
  });
  const { id: routeId, userType: userType } = useParams();
  const [showFleetEmployeeModal, setShowFleetEmployeeModal] = useState(false);
  const [fleetData, setFleetData] = useState({
    fromDate: "",
    toDate: "",
    type: "car",
    userId: routeId,
    fleetId: "",
    status: false,
    purchaseDate: "",
    userType,
  });

  const navigate = useNavigate();

  const handleSearchChange = (event: any, name: string, type: string) => {
    let value = "";
    switch (type) {
      case "text":
        value = event.target.value;
        break;
      case "date":
        if (event) {
          const date = `${event.getFullYear()}-${event.getMonth() + 1 < 10
            ? "0" + (event.getMonth() + 1)
            : event.getMonth() + 1
            }-${event.getDate() < 10 ? "0" + event.getDate() : event.getDate()}`;
          value = date;
        }
        break;
      case "select":
        value = event.value;
        break;
    }
    dispatch(updatePageFilters({ filters: { [name]: value, currentPage: "1" } }));
    handleRefresh();
  };

  /** Adding fleet material to employee */
  const handleAddFleetEmp = async () => {
    if (validationFunction()) {
      try {
        const response = await ApiCall.service(
          FLEET_EMP_CREATE,
          "POST",
          fleetData,
          false,
          M_MASTER_DATA
        );
        if (response.status == 200) {
          CustomNotify({ type: "success", message: response.message });
          setShowFleetEmployeeModal(false);
          handleRefresh && handleRefresh();
        } else {
          CustomNotify({ type: "warning", message: response.message });
        }
      } catch (error) {
        console.log("Error");
      }
    }
  };

  const handleFleetEmpModalClose = () => {
    setFleetData({
      fromDate: "",
      toDate: "",
      type: "car",
      userId: routeId,
      fleetId: "",
      status: false,
      purchaseDate: "",
      userType,
    });
    setShowFleetEmployeeModal(false);
  };

  const handleRemoveCar = async () => {
    try {
      const response = await ApiCall.service(
        FLEET_EMP_DELETE,
        "POST",
        { fleetId: fleetData.fleetId, userId: routeId, type: "car" },
        false,
        M_MASTER_DATA
      );
      if (response.status == 200) {
        CustomNotify({ type: "success", message: response?.message });
        setShowFleetEmployeeModal(false);
        handleRefresh();
      } else {
        CustomNotify({ type: "error", message: response?.message });
      }
    } catch (error) {
      console.log("Error");
    }
  };

  const handleLink = (id: number) => {
    setShowFleetEmployeeModal(true);
    setFleetData((prevState: any) => ({ ...prevState, fleetId: id }));
  };

  const handleRemove = (id: any) => {
    const filteredData = data.filter(
      (eachItem: any) => eachItem.status == 3 && eachItem.id == id
    );
    if (filteredData.length > 0) {
      const { fromDate, toDate, purchaseDate } = filteredData[0];
      setFleetData({
        fromDate: fromDate,
        toDate: toDate,
        type: "car",
        userId: routeId,
        fleetId: id,
        status: true,
        purchaseDate,
        userType,
      });
    } else {
      setFleetData((prevState: any) => ({ ...prevState, fleetId: id }));
    }
    setShowFleetEmployeeModal(true);
  };

  const handleSaveChanges = async () => {
    setShowModal(false);
    let postData = {
      id: id,
    };

    if (id) {
      const response = await ApiCall.service(
        CAR_DELETE,
        "POST",
        postData,
        false,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        handleRefresh();
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  const [error, setErrors] = useState({
    fromDate: "",
    toDate: "",
  });

  const validationFunction = (
    name: any = null,
    value: any = null,
    isSingleFieldValidation = false
  ) => {
    const validationRules: any = {
      fromDate: [validateRequired],
      toDate: [validateRequired],
    };
    const validationErrors: any = validateForm(
      { ...fleetData, [name]: value },
      isSingleFieldValidation ? validationRules[name] : validationRules
    );
    if (isSingleFieldValidation) {
      setErrors((prevErrors: any) => {
        if (validationErrors.hasOwnProperty(name)) {
          return { ...prevErrors, [name]: validationErrors[name] };
        }
        const { [name]: ty, ...remains } = prevErrors;
        return { ...remains };
      });
    } else {
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length > 0) {
        return false;
      } else {
        const fromDate = new Date(fleetData.fromDate);
        const lastDate = new Date(fleetData.toDate);
        const purchaseDate = new Date(fleetData.purchaseDate);
        if (fromDate > lastDate) {
          setErrors((prevState: any) => ({
            ...prevState,
            fromDate: "From date should be on or before to date",
          }));
          return false;
        }
        if (fromDate < purchaseDate) {
          setErrors((prevState: any) => ({
            ...prevState,
            fromDate: "From date should be on or after purchase date",
          }));
          return false;
        }
        return true;
      }
    }
  };

  useEffect(() => {
    getFilterData();
  }, []);

  const getFilterData = async () => {
    try {
      const filterData = await fetchOptions();
      if (filterData?.status == 200) {
        setFilterOptions((prevState: any) => ({
          ...prevState,
          businessUnit: filterData.data.businessUnit,
          status: [
            { value: '0', label: "Draft" },
            { value: '1', label: "Open" },
            { value: '2', label: "Maintainance" },
            { value: '3', label: "Assigned" },
            { value: '4', label: "Reserved" }
          ],
          carType: filterData.data.carType,
          location: filterData.data.location,
          // companies: filterData?.data?.carCompany,
        }));
      } else {
        CustomNotify({ type: "error", message: filterData.message });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleEdit = (id: number) => {
    navigate(`${PATH_EDIT_CAR}/${id}`);
  };

  const handleDelete = (id: number) => {
    setShowModal(true);
    setId(id);
  };
  const userData = useSelector(selectAuth);
  const filters = [
    { name: 'businessUnits', fieldType: "multiSelect", options: filterOptions?.businessUnit, placeholder: "Business unit", filterType: 'search' },
    { name: 'ownerCompany', fieldType: "text", placeholder: "Company", filterType: 'search' },
    { name: 'carType', fieldType: "singleSelect", options: filterOptions?.carType, placeholder: "Car type", filterType: 'search' },
    { name: 'brand', fieldType: "text", placeholder: "Brand", filterType: 'search' },
    { name: 'model', fieldType: "text", placeholder: "Model", filterType: 'search' },
    { name: 'licencePlate', fieldType: "text", placeholder: "Licence plate", filterType: 'search' },
    { name: 'driver', fieldType: "text", placeholder: "Driver", filterType: 'search' },
    { name: 'chassisNumber', fieldType: "text", placeholder: "Chassis number", filterType: 'search' },
    { name: 'contractNumber', fieldType: "text", placeholder: "Contract number", filterType: 'search' },
    { name: 'startDate', fieldType: "date", end: history?.filters?.endDate, placeholder: "Contract start date", filterType: 'search' },
    { name: 'endDate', fieldType: "date", start: history?.filters?.startDate, placeholder: "Contract end date", filterType: 'search' },
    { name: 'fromDate', fieldType: "date", end: history?.filters?.toDate, placeholder: "From date", filterType: 'search' },
    { name: 'toDate', fieldType: "date", start: history?.filters?.fromDate, placeholder: "To date", filterType: 'search' },
    { name: 'location', fieldType: "singleSelect", options: filterOptions?.location, placeholder: "Location", filterType: 'search' },
    { name: 'status', fieldType: "singleSelect", options: filterOptions?.status, placeholder: "Status", filterType: 'search' },
  ];

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Car",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          <Title title={t("Manage car")} />
          {fleetProps !== "fleet" ? (
            <div>
              <FilterOrganism
                dispatch={dispatch}
                history={history}
                class={{
                  rowClass: 'row',
                  colClass: 'col-2',
                  searchBtnClass: 'col-2 ms-auto'
                }}
                updatePageFilters={updatePageFilters}
                filters={filters}
                handleRefresh={handleRefresh}
              />
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="position-relative tableMainWrapper">
          {fleetProps !== "fleet" && (
            <div className="">
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Car",
                    create: true,
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <div className="row">
                  <div className="ManageCreateBtn">
                    <LinkTo
                      pagelink={PATH_CREATE_CAR}
                      title={t("Create car")}
                      icon={faPlus}
                    />
                  </div>
                </div>
              </AccessControl>
            </div>
          )}
          <div className="table-responsive manage-projects tableSection">
            <div className="manageCarHeight">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    {CarTableHeader.map((header) => (
                      <th key={header.alias}>
                        <>
                          {t(header.name)}
                          {header.issort && (
                            <SortAtomForManagePage
                              value={{ alias: header.alias }}
                              dispatch={dispatch}
                              history={history}
                              updatePageFilters={updatePageFilters}
                              handleRefresh={handleRefresh}
                            />
                          )}
                        </>
                      </th>
                    ))}
                    <th style={{ width: "10%" }}>
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: "Car",
                            update: true,
                            delete: true,
                          },
                        ]}
                        actions={true}
                        strict={false}
                        override={userData.isSuperAdmin}
                      >
                        {t("Action")}
                      </AccessControl>
                    </th>
                  </tr>
                </thead>
                {/* if isStatus=false then enable all the options else give option for only status is 3 for other things disable that */}
                <tbody>
                  {data && data.length > 0 ? (
                    data.map((record: any, key: any) => {
                      return (
                        <tr key={key}>
                          <td className="align-middle">
                            {record?.businessUnits}
                          </td>
                          <td className="align-middle">
                            {record?.ownerCompany}
                          </td>
                          <td className="align-middle">{record?.carType}</td>
                          <td className="align-middle">
                            {record?.brand}
                          </td>
                          <td className="align-middle">
                            {record?.model}
                          </td>
                          <td className="align-middle">
                            {record.licencePlate}
                          </td>
                          <td className="align-middle">{record?.driver}</td>
                          <td className="align-middle">
                            {record.chassisNumber}
                          </td>
                          <td className="align-middle">
                            {record.contracNumber}
                          </td>
                          <td className="align-middle">
                            {record.startDate}
                          </td>
                          <td className="align-middle">
                            {record.endDate}
                          </td>
                          <td className="align-middle">
                            {record?.fromDate}
                          </td>
                          <td className="align-middle">
                            {record?.toDate}
                          </td>
                          <td className="align-middle">
                            {record.location}
                          </td>
                          <td className="align-middle">
                            <div
                              className={getStatusColor(record.status)}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={getTooltipMessage(record.status)}
                            ></div>
                          </td>
                          <td className="align-middle">
                            <TableActions
                              isAction={true}
                              {...(fleetProps !== "fleet"
                                ? {
                                  handleEdit: handleEdit,
                                  handleArchive: handleDelete,
                                }
                                : record.status == 1
                                  ? { handleAddFleet: handleLink }
                                  : { handleRemove: handleRemove })}
                              value={record.id}
                              permission={"Car"}
                              isDisable={
                                record.isStatus == false
                                  ? false
                                  : record.status == 1
                                    ? true
                                    : false
                              }
                              signature={record.project_pdf_path}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={15} className="text-center">
                        {t("No records found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div
              className=""
              style={{ paddingTop: "0.25vw" }}
            >
              <PaginationWithPerPage
                handleRefresh={handleRefresh}
                dispatch={dispatch}
                history={history}
                pageData={pageData}
              />
            </div>
          </div>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle="No"
          confirmTitle="Yes"
        />
        {showFleetEmployeeModal && (
          <FleetEmployeeModal
            handleClose={handleFleetEmpModalClose}
            data={fleetData}
            errors={error}
            setFleetData={setFleetData}
            handleSave={handleAddFleetEmp}
            handleRemove={handleRemoveCar}
            validationFn={validationFunction}
          />
        )}
      </>
    </AccessControl>
  );
};
export default ManageCar;
