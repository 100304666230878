import SelectWithSearch from 'components/atoms/SelectWithSearch';
import React, { useEffect, useState } from 'react';
import { Option, ValidationRules } from 'utils/TypeAnnotations';
import { t } from '../translation/Translation';
import LabelWithTextAreaField from 'components/molecules/LabelWithTextAreaField';
import EditIcon from 'static/images/EditIcon';
import DeleteIcon from 'static/images/DeleteIcon';
import TitleAtom from 'components/atoms/Title';
import { LabelWithInputField } from 'components/molecules/LabelWithInputField';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Button from 'components/atoms/Button';
import { ApiCall } from 'services/ApiServices';
import { CREATE_WAGE_PROPOSAL, GET_FUNCTION_FOR_PC, GET_WAGE_PROPOSAL_DATA, GET_WAGE_PROPOSAL_ELEMENTS } from 'routes/ApiEndpoints';
import { M_MASTER_DATA } from 'constants/Constants';
import CustomNotify from 'components/atoms/CustomNotify';
import { LabelWithInputFieldAndUnit } from '../templates/atoms/LabelWithInputFieldAndUnit';
import { validateForm, validateNumber, validatePercentage, validateRequired, validateSelectField } from 'services/validation/ValidationService';
import { mapToSelect } from 'utils/MapToSelect';

interface SalaryBenefitProps {
    value: number | null;
    label: string;
    benefitValueType: number | Option | null;
    benefitValue: number | null;
}
interface WageProposalProps {
    userId: number;
    employeeContractType: Option | null;
    businessUnit: Option | null;
    paritairComitee: Option | null;
    function: Option | null;
    salaryCategory: Option | null;
    salary: number;
    salaryBenefits: SalaryBenefitProps[] | null;
    salaryType: Option | null;
    info: string;
}

const CreactWageProposal = () => {
    const queryParam = useParams();
    const candidateId = queryParam.candidateId;
    const wageId = queryParam.wageId;
    const navigate = useNavigate();
    const initialWageProposal = {
        userId: Number(candidateId),
        employeeContractType: null,
        businessUnit: null,
        paritairComitee: null,
        function: null,
        salaryCategory: null,
        salaryType: null,
        salary: 0,
        salaryBenefits: null,
        info: ''
    }
    const initialDropdownData = {
        employeeContractType: [],
        businessUnit: [],
        paritairComitee: [],
        function: [],
        salary: [],
        salaryBenefits: [],
        salaryCategory: [],
        salaryType: []
    }
    const [formData, setFormData] = useState<WageProposalProps>(initialWageProposal);
    const [dropdownData, setDropdownData] = useState(initialDropdownData);
    const [salaryBenefits, setSalaryBenefits] = useState<SalaryBenefitProps[]>([{
        value: null,
        label: '',
        benefitValueType: null,
        benefitValue: null,
    }]);
    const [errors, setErrors] = useState<any>({
        benefitValue: ''
    });

    useEffect(() => {
        //dropdown data
        ApiCall.getService(GET_WAGE_PROPOSAL_ELEMENTS, "GET", M_MASTER_DATA, true).then((response) => {
            if (response.status === 200) {
                setDropdownData(response.data);
            }
        });
        if (wageId) {
            ApiCall.service(GET_WAGE_PROPOSAL_DATA, "POST", { id: wageId }, true, M_MASTER_DATA).then((response) => {
                if (response?.status === 200) {
                    const wageProposalData = response?.data;
                    setFormData(wageProposalData);
                    setSalaryBenefits(wageProposalData?.salaryBenefits);
                    if (dropdownData?.paritairComitee.length > 0) {
                        const pc: Object = dropdownData?.paritairComitee.filter((pc: Option) => pc.value === wageProposalData?.paritairComitee);
                        fetchPcRetaltedFunctions(pc);
                    }
                }
            });
        }
    }, []);

    const validation = (
        name: any = null,
        value: any = null,
        isSingleFieldValidation = false
    ) => {
        const validationRules: ValidationRules = {
            employeeContractType: [validateSelectField],
            businessUnit: [validateSelectField],
            paritairComitee: [validateSelectField],
            function: [validateSelectField],
            salary: [validateRequired],
            salaryType: [validateSelectField],
            // salaryBenefits.benefitValue: [validateRequired],
        };

        const validationErrors = validateForm(
            {
                ...formData,
                [name]: value,
            },
            validationRules,
            isSingleFieldValidation ? name : undefined
        );
        if (isSingleFieldValidation) {
            setErrors((prevErrors: any) => ({
                ...prevErrors,
                [name]: validationErrors[name],
            }));
        } else {
            setErrors(validationErrors);
        }

        if (Object.keys(validationErrors).length > 0) {
            return false;
        }
        return true;
    };

    const fetchPcRetaltedFunctions = async (data: any) => {
        const functionResponse = await ApiCall.service(GET_FUNCTION_FOR_PC, "POST", data, true, M_MASTER_DATA);
        if (functionResponse?.status == 200) {
            setDropdownData((prevData: any) => ({
                ...prevData,
                function: functionResponse.data?.functions ? mapToSelect(functionResponse.data?.functions) : [],
            }));
        }
    }

    const handleSelectChange = async (selectedOption: Option | Object[] | any, fieldName: string, index?: number) => {
        if (fieldName === 'paritairComitee') {
            fetchPcRetaltedFunctions(selectedOption);
        }
        if (fieldName === 'salaryBenefits') {
            // Handle salary benefits selection
            const selectedBenefits = selectedOption.map((option: any) => ({
                value: option.value,
                label: option.label,
                benefitValueType: option.benefitValueType,
                benefitValue: option.benefitValue,
            }));

            setSalaryBenefits(selectedBenefits);
            setFormData((prevData: WageProposalProps) => ({
                ...prevData,
                salaryBenefits: selectedBenefits,
            }));
            return;
        } else if (fieldName === 'benefitValueType') {
            // Handle changes to benefit value type or value
            const updatedSalaryBenefits = [...salaryBenefits];
            if (index !== undefined) {
                updatedSalaryBenefits[index] = {
                    ...updatedSalaryBenefits[index],
                    [fieldName]: selectedOption?.value, // For benefit value, can be a number
                };
            }
            setSalaryBenefits(updatedSalaryBenefits);
            setFormData((prevData: WageProposalProps) => ({
                ...prevData,
                salaryBenefits: updatedSalaryBenefits
            }));
            return;
        }

        // Update the form data for other fields
        setFormData((prevData: WageProposalProps) => ({
            ...prevData,
            [fieldName]: selectedOption?.value
        }));
        validation(fieldName, selectedOption, true);
    };
    const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index?: number) => {
        const { name, value } = e.target;
        if (name === 'benefitValue') {
            // Handle changes to benefit value type or value
            const updatedSalaryBenefits = [...salaryBenefits];
            if (index !== undefined) {
                updatedSalaryBenefits[index] = {
                    ...updatedSalaryBenefits[index],
                    [name]: Number(value), // For benefit value, can be a number
                };
            }
            setSalaryBenefits(updatedSalaryBenefits);
            setFormData((prevData: WageProposalProps) => ({
                ...prevData,
                salaryBenefits: updatedSalaryBenefits
            }));
            return;
        }
        setFormData((prevData: WageProposalProps) => ({
            ...prevData,
            [name]: value
        }));
        validation(name, value, true);
    }
    const handleSave = async (e: React.FormEvent) => {
        const { name, value } = e.target as HTMLInputElement;
        if (validation(name, value)) {
            Object.assign(formData, { id: Number(wageId) ?? null });
            const response = await ApiCall.service(CREATE_WAGE_PROPOSAL, "POST", formData, false, M_MASTER_DATA);
            if (response.status === 200) {
                CustomNotify({ type: 'success', message: response.message });
                navigate(-1);
            }
        }
    }

    return (
        <>
            <div className="full-container mx-5">
                <div className="row">
                    <TitleAtom title='Create wage proposal' />
                </div>
                <div className="row">
                    <div className="col">
                        <SelectWithSearch
                            title={t("Employee contract type")}
                            isMandatory={true}
                            name="employeeContractType"
                            search={true}
                            options={dropdownData.employeeContractType}
                            onChange={(e) => handleSelectChange(e, "employeeContractType")}
                            isMulti={false}
                            value={formData.employeeContractType}
                            error={errors.employeeContractType}
                            placeHolder="Select"
                        />
                    </div>
                    <div className="col">
                        <SelectWithSearch
                            title={t("Business unit")}
                            isMandatory={true}
                            name="businessUnit"
                            search={true}
                            options={dropdownData.businessUnit}
                            onChange={(e) => handleSelectChange(e, "businessUnit")}
                            isMulti={false}
                            value={formData.businessUnit}
                            error={errors.businessUnit}
                            placeHolder="Select"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <SelectWithSearch
                            title={t("Paritair comitee")}
                            isMandatory={true}
                            name="paritairComitee"
                            search={true}
                            options={dropdownData.paritairComitee}
                            onChange={(e) => handleSelectChange(e, "paritairComitee")}
                            isMulti={false}
                            value={formData.paritairComitee}
                            error={errors.paritairComitee}
                            placeHolder="Select"
                        />
                    </div>
                    <div className="col-6">
                        <SelectWithSearch
                            title={t("Function")}
                            isMandatory={true}
                            name="function"
                            search={true}
                            options={dropdownData.function}
                            onChange={(e) => handleSelectChange(e, "function")}
                            isMulti={false}
                            value={formData.function}
                            error={errors.function}
                            placeHolder="Select"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <SelectWithSearch
                            title={t("Salary category")}
                            isMandatory={false}
                            name="salaryCategory"
                            search={true}
                            options={dropdownData.salaryCategory}
                            onChange={(e) => handleSelectChange(e, "salaryCategory")}
                            isMulti={false}
                            value={formData.salaryCategory}
                            placeHolder="Select"
                        />
                    </div>
                    <div className="col me-0 pe-0">
                        <LabelWithInputField
                            isMandatory={true}
                            name="salary"
                            handleChange={(e) => handleFieldChange(e)}
                            value={formData.salary}
                            id="salary"
                            label={t("Salary")}
                            type="text"
                            error={errors.salary}
                            placeholder="XX.XX.XX-XXX.XX"
                        />
                    </div>
                    <div className="col-1 ms-0 ps-0 mt-4">
                        <SelectWithSearch
                            isMandatory={true}
                            name="salaryType"
                            search={true}
                            options={dropdownData.salaryType}
                            onChange={(e) => handleSelectChange(e, "salaryType")}
                            isMulti={false}
                            value={formData.salaryType}
                            error={errors.salaryType}
                            placeHolder="hour/month"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <SelectWithSearch
                            title={t("Salary benefits")}
                            isMandatory={false}
                            name="salaryBenefits"
                            search={true}
                            options={dropdownData.salaryBenefits}
                            onChange={(e) => handleSelectChange(e, "salaryBenefits")}
                            isMulti={true}
                            value={formData.salaryBenefits}
                            placeHolder="Select"
                        />
                    </div>
                    {formData.salaryBenefits && formData.salaryBenefits.length > 0 && formData.salaryBenefits.map((benefit: SalaryBenefitProps, index: number) => (
                        <div className='row' key={index}>
                            <div className='col-2 fw-bold'>{benefit.label}:</div>
                            <div className="col">
                                <SelectWithSearch
                                    name="benefitValueType"
                                    id="benefitValueType"
                                    isMandatory={true}
                                    search={true}
                                    options={[
                                        { name: "euro", label: "Salary in euro", value: 1 },
                                        { name: "percentage", label: "Salary in percentage", value: 2 },
                                    ]}
                                    placeHolder="Select"
                                    value={benefit.benefitValueType}
                                    onChange={(e) => handleSelectChange(e, "benefitValueType", index)}
                                    isMulti={false}
                                    className="select-field"
                                    containerClassName="col-12 position-relative"
                                />
                            </div>
                            <div className="col">
                                <LabelWithInputFieldAndUnit
                                    labelClassName="form-label field-label"
                                    name="benefitValue"
                                    id="benefitValue"
                                    isMandatory={true}
                                    handleChange={(e) => handleFieldChange(e, index)}
                                    value={benefit.benefitValue ?? ''} // Convert null to an empty string
                                    placeholder={t("Select")}
                                    className="form-control field-shadow"
                                    type="text"
                                    // unit={salaryBenefits?.benefitValueType ===2}
                                    unit={(benefit && benefit?.benefitValueType === 1) || (salaryBenefits.length > 0 && salaryBenefits[index]?.benefitValueType === 1) ? '€' : '%'}
                                    containerClassName="col-12 position-relative"
                                    error={errors.benefitValue}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="row">
                    <div className="col">
                        <LabelWithTextAreaField
                            label={t("Info")}
                            name="info"
                            value={formData.info}
                            placeholder={t("info")}
                            isDisabled={false}
                            isMandatory={false}
                            handleChange={(e) => handleFieldChange(e)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Link
                            to=""
                            className="back-btn text-decoration-underline"
                            onClick={() => navigate(-1)}
                        >
                            {t("Back")}
                        </Link>
                    </div>
                    <div className="col">
                        <Button
                            type="submit"
                            title={t("Save")}
                            handleClick={handleSave}
                            className="float-end form-button px-3 shadow-none"
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreactWageProposal;