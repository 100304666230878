import React, { useState } from "react";
import CheckBoxField from "components/atoms/CheckBoxField";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import "../css/Company.css";
import Button from "components/atoms/Button";
import { useFormContext } from "pages/microservices/companyCreation/context/Context";
import { Navigation } from "../formNavigation/Navigation";
import { ValidationRules } from "utils/TypeAnnotations";

import {
  validateForm,
  validateRequired,
  validateAlphaNumeric,
  validateMaxLength,
  Option,
  validateMinLength,
  scrollToTop,
  validateNoSpaces,
  validateSelectField,
  validateVatNumber,
} from "services/validation/ValidationService";
import { ApiCall } from "services/ApiServices";
import {
  REGISTER_COMPANY,
  COMPANY_VAT_VALIDATION,
  COMPANY_VAT_EXISTENCE,
} from "routes/ApiEndpoints";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";
import {
  M_COMPANY_CREATION,
  M_WEB_CONNECTOR,
} from "../../../../../constants/Constants";
import { selectAuth } from "features/auth/AuthSlice";
import { useSelector } from "react-redux";

const Basic = () => {
  const { state, dispatch } = useFormContext();
  const navigate = useNavigate();
  const { companyId } = useParams<{ companyId: string }>();
  const userData = useSelector(selectAuth);
  const location = useLocation();
  // Check if 'mode=view' is present in the query string
  const isViewMode = location.search.includes("mode=view");
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validation = (
    name: string,
    value: string | boolean | number | Option | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      vat: [
        validateRequired,
        (value: string) => validateVatNumber(value),
        // validateAlphaNumeric,
        // (value: string) => validateMaxLength(value, 15),
        // (value: string) => validateMinLength(value, 11),
        validateNoSpaces,
      ],
      companyName: [validateRequired],
      officialName: [validateRequired],
      // pc: [validateMultiSelectField],
      // naceCodes: [validateMultiSelectField],
      // website: [validateRequired, validateWebsiteField],
      companyType: [validateSelectField],
      // bussinessUnit: [validateSelectField],
    };
    if (state.basic.isBussinessUnit) {
      validationRules.businessUnit = [validateSelectField];
    }
    const validationErrors = validateForm(
      { ...state.basic, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    dispatch({
      type: "UPDATE_FIELD_ERROR",
      fieldName: name,
      error: validationErrors[name],
    });
    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      state.fieldError[name] = "";
    }
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;

    if (type === "checkbox") {
      if (name === "hq" && checked) {
        dispatch({
          type: "UPDATE_BASIC_FIELD",
          field: "parentCompanies",
          value: null,
        });
        dispatch({
          type: "UPDATE_BASIC_FIELD",
          field: "coEfficient",
          value: 0,
        });
      }

      const newValue = checked ? 1 : 0;
      dispatch({ type: "UPDATE_BASIC_FIELD", field: name, value: newValue });
      validation(name, checked, true);
    } else {
      const formattedValue =
        name === "companyName" || name === "officialName"
          ? value.toUpperCase()
          : value;
      dispatch({
        type: "UPDATE_BASIC_FIELD",
        field: name,
        value: formattedValue,
      });
      validation(name, formattedValue, true);
    }
  };

  const handleSelectChange = (selectedOption: any, name: string) => {
    if (name === "companyType") {
      if (selectedOption.value === 2 && selectedOption.label === "External") {
        dispatch({
          type: "UPDATE_BASIC_FIELD",
          field: "isBussinessUnit",
          value: true,
        });
        dispatch({
          type: "UPDATE_BASIC_FIELD",
          field: name,
          value: selectedOption,
        });
        validation(name, selectedOption, true);
      } else if (
        selectedOption.value === 1 &&
        selectedOption.label === "Internal"
      ) {
        dispatch({
          type: "UPDATE_BASIC_FIELD",
          field: name,
          value: selectedOption,
        });
        dispatch({
          type: "UPDATE_BASIC_FIELD",
          field: "isBussinessUnit",
          value: false,
        });
        dispatch({
          type: "UPDATE_BASIC_FIELD",
          field: "bussinessUnit",
          value: null,
        });
        validation(name, selectedOption, true);
      }
    } else {
      dispatch({
        type: "UPDATE_BASIC_FIELD",
        field: name,
        value: selectedOption,
      });

      validation(name, selectedOption, true);
    }
  };

  const validStatus = (validation: any) => {
    setValidationStatus({
      // isValid: true,
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const verifyVatHandler = async (e: any, field: string, value: string) => {
    validation(field, value, true);
    const formData = { [field]: value };

    if (state.fieldError.vat || !value) {
      dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
      return;
    }
    const vatData = {
      vat_number: value,
      key: `${process.env.REACT_APP_WEBCONNECTOR_UNIQUE_KEY}`,
    };
    const vatExistence = await ApiCall.service(
      COMPANY_VAT_EXISTENCE,
      "POST",
      vatData,
      false,
      M_COMPANY_CREATION
    );
    if (vatExistence?.status === 400) {
      dispatch({
        type: "UPDATE_FIELD_ERROR",
        fieldName: "vat",
        error: vatExistence?.msg,
      });
      scrollToTop();
      return;
    }
    const url = COMPANY_VAT_VALIDATION;
    const response = await ApiCall.service(
      url,
      "POST",
      vatData,
      true,
      M_WEB_CONNECTOR
    );
    if (response?.status !== 200) {
      CustomNotify({ type: "error", message: response?.error });
      dispatch({
        type: "UPDATE_BASIC_FIELD",
        field: "companyName",
        value: "",
      });
      dispatch({
        type: "UPDATE_BASIC_FIELD",
        field: "officialName",
        value: "",
      });
      handleAddressUpdate("", "", "", "", "");
      dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
      scrollToTop();
      state.fieldError.vat = response?.error;
    } else {
      const value = response?.data?.name;
      const { street, number, box, postal, city } = response?.data?.address;

      handleAddressUpdate(street, number, box, postal, city);
      dispatch({
        type: "UPDATE_FIELD_ERROR",
        fieldName: "companyName",
        error: "",
      });
      dispatch({ type: "UPDATE_BASIC_FIELD", field: "officialName", value: value.toUpperCase() });
      dispatch({
        type: "UPDATE_FIELD_ERROR",
        fieldName: "officialName",
        error: "",
      });
      dispatch({ type: "UPDATE_BASIC_FIELD", field: "companyName",  value: value.toUpperCase()});
      CustomNotify({ type: "success", message: "VAT number is verified" });
      dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: false });
    }
  };
  const handleAddressUpdate = (
    street: string,
    number: string,
    unit: string,
    postal: string,
    city: string,
    countryValue: { value: number; label: string } = {
      value: 29,
      label: "Belgium",
    }
  ) => {
    const addressFields = [
      { field: "street", value: street },
      { field: "number", value: number },
      { field: "box", value: unit },
      { field: "zipCode", value: postal },
      { field: "city", value: city },
      { field: "country", value: countryValue },
    ];

    addressFields.forEach((field) => {
      dispatch({
        type: "UPDATE_ADDRESS_FIELD",
        field: field.field,
        value: field.value,
      });
      dispatch({
        type: "UPDATE_FIELD_ERROR",
        fieldName: field.field,
        error: "",
      });
      dispatch({
        type: "UPDATE_ADDRESS_FIELD",
        field: `bi${
          field.field.charAt(0).toUpperCase() + field.field.slice(1)
        }`,
        value: field.value,
      });
      dispatch({
        type: "UPDATE_FIELD_ERROR",
        fieldName: `bi${
          field.field.charAt(0).toUpperCase() + field.field.slice(1)
        }`,
        error: "",
      });
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const formData = {
      basic: state.basic,
    };
    formData.basic.companyId = companyId;

    if (state.basic.vat !== "" && state.basic.companyName !== "") {
      const url = !companyId
        ? REGISTER_COMPANY
        : `${REGISTER_COMPANY}/${companyId}`;
      const response = await ApiCall.service(
        url,
        "POST",
        formData,
        false,
        M_COMPANY_CREATION
      );
      if (response?.status === 200) {
        if (validationStatus.type === "draft") {
          CustomNotify({
            type: "success",
            message: "Draft saved successfully!",
          });
          navigate("/manage-companies");
        } else {
          navigate(`/company/${response.companyId}`);
        }
      } else if (response?.status === 400) {
        CustomNotify({ type: "error", message: response.msg.vat_number });
        dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
        scrollToTop();
        state.fieldError.vat = response.msg.vat_number;
      }
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-border" style={{ paddingTop: "3vw" }}>
        <div className="row">
          <div className="col-6 generalTabVAT position-relative">
            <div className="position-absolute fieldPrefix">BE</div>
            <LabelWithInputField
              isMandatory={true}
              name="vat"
              handleChange={handleFieldChange}
              value={state.basic.vat}
              id="vat"
              label="VAT number"
              type="text"
              error={state.fieldError.vat}
              isDisabled={state.basic.companyStatus == 1 || isViewMode}
            />
            <Button
              type="button"
              title="Verify"
              handleClick={(e) => verifyVatHandler(e, "vat", state.basic.vat)}
              className={`form-button companyVatVerify`}
            />
          </div>
          <div className="col-sm-6">
            <LabelWithInputField
              isMandatory={true}
              name="companyName"
              handleChange={handleFieldChange}
              value={state.basic.companyName}
              id="company"
              label="Company name"
              type="text"
              error={state.fieldError.companyName}
              isDisabled={isViewMode}
            />
          </div>
          <div className="col-6">
            <LabelWithInputField
              isMandatory={true}
              name="officialName"
              handleChange={handleFieldChange}
              value={state.basic.officialName}
              id="official"
              label="Official name"
              type="text"
              error={state.fieldError.officialName}
              isDisabled={isViewMode}
            />
          </div>
          <div className="col-6">
            <LabelWithInputField
              isMandatory={false}
              name="website"
              handleChange={handleFieldChange}
              value={state.basic.website}
              id="website"
              label="Website"
              type="text"
              isDisabled={isViewMode}
              //  error={state.fieldError.website}
            />
          </div>
          <div className="col-sm-4">
            <SelectWithSearch
              title="Paritair comite"
              name="pc"
              isMandatory={false}
              search={true}
              options={state.pcList}
              placeHolder="Select"
              value={state.basic.pc}
              onChange={(e) => handleSelectChange(e, "pc")}
              isMulti={true}
              className="select-field"
              // error={state.fieldError.pc}
              isDisabled={isViewMode}
            />
          </div>
          <div className="col-sm-4">
            <SelectWithSearch
              title="NACE codes"
              name="naceCodes"
              isMandatory={false}
              search={true}
              options={state.naceCodesList}
              placeHolder="Select"
              value={state.basic.naceCodes}
              onChange={(e) => handleSelectChange(e, "naceCodes")}
              isMulti={true}
              className="select-field"
              isDisabled={isViewMode}
              //  error={state.fieldError.naceCodes}
            />
          </div>

          <div className="col-sm-12 col-md-4">
            <SelectWithSearch
              title="Company type"
              name="companyType"
              isMandatory={true}
              search={true}
              options={[
                { value: 1, label: "Internal" },
                { value: 2, label: "External" },
              ]}
              placeHolder="Select"
              value={state.basic.companyType}
              onChange={(e) => handleSelectChange(e, "companyType")}
              isMulti={false}
              className="select-field"
              error={state.fieldError.companyType}
              isDisabled={
                isViewMode ||
                userData.role.includes("HR") ||
                userData.role.includes("Sales")
              }
            />
          </div>

          {state.basic.isBussinessUnit && (
            <div className="col-sm-12 col-md-6">
              <SelectWithSearch
                title="Bussiness unit"
                name="bussinessUnit"
                isMandatory={true}
                search={true}
                options={state.bussinessUnitList}
                placeHolder="Select"
                value={state.basic.bussinessUnit}
                onChange={(e) => handleSelectChange(e, "bussinessUnit")}
                isMulti={true}
                className="select-field"
                error={state.fieldError.bussinessUnit}
                isDisabled={isViewMode}
              />
            </div>
          )}

          <div className="col-md-12" style={{ marginBottom: "1vw" }}>
            <CheckBoxField
              label="This is parent company"
              name="hq"
              onChangeHandler={handleFieldChange}
              isChecked={state.basic.hq === 1}
              disable={isViewMode}
              id="hq"
              lineHeight="1.6vw"
            />
          </div>
          <div className="col-6">
            <SelectWithSearch
              title="Select parent company"
              name="parentCompanies"
              isMandatory={false}
              search={true}
              options={state.companiesList}
              placeHolder="Select"
              isDisabled={state.basic.hq === 1 || isViewMode}
              value={state.basic.parentCompanies}
              onChange={(e) => handleSelectChange(e, "parentCompanies")}
              isMulti={false}
              className="select-field"
              error={state.fieldError.parentCompanies}
            />
          </div>
          <div className="col-md-12">
            <CheckBoxField
              label="Active"
              name="active"
              onChangeHandler={handleFieldChange}
              isChecked={state.basic.active}
              disable={isViewMode}
              id="active"
              lineHeight="1.7vw"
            />
          </div>
          {/* {state.basic.hq === 0 && (
            <div className="col-md-12">
              <CheckBoxField
                label="Follow the same coefficient as the parent company"
                name="coEfficient"
                onChangeHandler={handleFieldChange}
                isChecked={state.basic.coEfficient === 1}
              />
            </div>
          )} */}
        </div>
      </div>
      <div style={{ padding: "1vw 0" }}>
        <Navigation validStatus={validStatus} />
      </div>
    </form>
  );
};

export default Basic;
