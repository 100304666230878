import {
  ContactPersonTableHeader,
  ExcludeContactCompanyData,
  RoleTableHeader,
} from "TableHeader";
import SearchModel from "components/atoms/SearchModel";
import TableStructure from "components/atoms/TableStructure";
import { t } from "pages/microservices/masterData/translation/Translation";
import React, { useEffect, useState, Suspense } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ApiCall } from "services/ApiServices";
import {
  CONTACT_PERSON_DATA,
  EDIT_CONTACT_PERSON,
  DELETE_CONTACT_PERSON,
  GET_COMPANY,
} from "routes/ApiEndpoints";
import { ERROR, M_COMPANY_CREATION } from "constants/Constants";
import ModalPopup from "components/atoms/ModalPopup";
import CustomNotify from "components/atoms/CustomNotify";
import Pagination from "components/atoms/Pagination";
import Popup from "components/molecules/Popup";
import FormatData from "components/molecules/FormatData";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Button from "components/atoms/Button";
import Search from "components/atoms/Search";
import { IconData } from "services/IconData";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import BackButton from "components/atoms/BackButton";
import { selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { useDispatch } from "react-redux";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import FilterOrganism from "components/organism/FilterOrganism";

interface ContactPerson {
  id?: number;
  name?: string;
  company?: string;
  location?: string;
  companyEmail?: string;
  companyPhoneNumber?: string;
  user_id: number;
  businessUnit: string;
}

interface ContactData {
  contactPerson: any;
  company: any;
}
const ManageContactPerson: React.FC = () => {
  // State to manage the message
  const [contactPerson, setContactPerson] = useState<ContactPerson[]>([]);
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModelBody] = useState(t("Are you sure want to delete?"));
  const userData = useSelector(selectAuth);
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
  const dispatch = useDispatch();
  const [filterOptions, setFilterptions] = useState({
    businessUnit: [],
  });
  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    initialCall: true,
    refresh: true,
  });
  const getSearchData = () => {
      return {
        businessUnits: history?.filters?.businessUnits ?? [],
        companyName: history?.filters?.companyName ?? "",
        name: history?.filters?.name ?? "",
        location: history?.filters?.location ?? "",
        companyEmail: history?.filters?.companyEmail ?? "",
        companyPhoneNumber: history?.filters?.companyPhoneNumber ?? "",
        orderBy: history?.filters?.orderBy ?? "id",
        sortDirection: history?.filters?.sortDirection ?? "desc",
      }
  };
  //Modal popup for company details
  const [popup, setPopup] = useState({
    isModelOpen: false,
  });
  //set company details in modal
  const [modalData, setModalData] = useState<any>(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const postData = {
          currentPage: Number(history?.filters?.currentPage ?? 1),
          itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
          search: getSearchData(),
          initialCall: pageData?.initialCall
        };
        const response = await ApiCall.service(
          CONTACT_PERSON_DATA,
          "POST",
          postData,
          false,
          M_COMPANY_CREATION
        );
        const modifiedContactPersons: ContactPerson[] = response.data.map(
          (person: ContactPerson) => ({
            ...person,
          })
        );
        setPageData((prev: any) => ({ ...prev, totalPages: response.totalPages, totalRecords: response?.totalRecords, initialCall: false}));
        setContactPerson(modifiedContactPersons);
        if (pageData?.initialCall) {
          setFilterptions((prevState) => ({
            ...prevState,
            businessUnit: response?.options?.businessUnits,
          }));
        }
      } catch (error) {
        console.error(ERROR, error);
      }
    };
    fetchData();
  }, [pageData?.refresh, pageData?.initialCall]);

  const closeModal = () => {
    setPopup((prev) => ({ ...prev, isModelOpen: false }));
  };

  // Handle delete change
  const deleteContactPerson = (person: ContactPerson | any) => {
    setShowModal(true);
    setDeleteId(person.user_id);
  };

  // Handle edit change
  const editContactPerson = (person: ContactPerson | any) => {
    if (person.user_id) {
      navigate(`/create/contact-person/?edit=${person.user_id}`);
    }
  };

  //handle close model
  const handleModalClose = () => {
    setShowModal(!showModal);
    setModelBody(t("Are you sure want to delete?"));
  };

  //handle archive changes
  const handleSaveChanges = async () => {
    setShowModal(false);

    if (deleteID) {
      const url = `${DELETE_CONTACT_PERSON}/${deleteID}`;
      const response = await ApiCall.getService(url, "GET", M_COMPANY_CREATION);
      if (response.status === 403) {
        setModelBody(response.msg);
        setShowModal(true);
      } else if (response.status === 200) {
        const newContactPersonObj = contactPerson.filter(
          (item: ContactPerson) => item.user_id !== deleteID
        );
        setContactPerson(newContactPersonObj);
        CustomNotify({ type: "success", message: response.msg });
      } else {
        CustomNotify({ type: "error", message: response.msg });
      }
    }
  };


  const columns = [
    "businessUnit",
    "name",
    "companyName",
    "location",
    "companyEmail",
    "companyPhoneNumber",
  ];

  function handleClick(userData: any): void {
    navigate(`/create/contact-person/?edit=${userData.user_id}`);
  }

  const handleSendMail = (value: any) => {
    window.location.href = `mailto:${value.companyEmail}`;
    //need to implement as job
  };
  const handlePhoneCall = (value: any) => {
    //need to implement as job
  };
  const handleFallowUp = (value: any) => {
    //need to implement as job
  };
  const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

  const filters = [
    {name: 'businessUnits', fieldType: "multiSelect", options: filterOptions.businessUnit, placeholder: "Business units", filterType: 'search' },
    {name: 'name', fieldType: "text", placeholder: "Name", filterType: 'search' },
    {name: 'companyName', fieldType: "text", placeholder: "Company", filterType: 'search' },
    {name: 'location', fieldType: "text", placeholder: "Location", filterType: 'search' },
    {name: 'companyEmail', fieldType: "text", placeholder: "Email", filterType: 'search' },
    {name: 'companyPhoneNumber', fieldType: "text", placeholder: "Phone number", filterType: 'search' },
  ];
  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Contact person",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          <Title title={t("Manage contact person")} />
        </div>
        <div className="position-relative tableMainWrapper">
          <AccessControl
            requiredPermissions={[
              {
                permission: "Contact person",
                create: true,
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <div className="row">
              <div className="ManageCreateBtn">
                <LinkTo
                  pagelink="/create/contact-person"
                  title={t("Create Contact Person")}
                  icon={faPlus}
                />
              </div>
            </div>
          </AccessControl>
          <div className="table-responsive manage-contact-person tableSection">
            <FilterOrganism
              dispatch={dispatch}
              history={history}
              updatePageFilters={updatePageFilters}
              filters={filters}
              handleRefresh={handleRefresh}
            />
            <div className="TableHeight">
              <TableStructure
                isAction
                headers={ContactPersonTableHeader}
                data={contactPerson}
                values={columns}
                handleEdit={editContactPerson}
                handleDelete={deleteContactPerson}
                handleClick={handleClick}
                handleSendMail={handleSendMail}
                handlePhoneCall={handlePhoneCall}
                handleFallowUp={handleFallowUp}
                permissionType={"Contact person"}
                clickStatus={true}
                history={history}
                handleRefresh={handleRefresh}
              />
            </div>
            <div
              className=""
              style={{ paddingTop: "0.25vw" }}
            >
              <PaginationWithPerPage
                handleRefresh={handleRefresh}
                dispatch={dispatch}
                history={history}
                pageData={pageData}
              />
            </div>
          </div>
        </div>
        <div
          className="col-md-6"
          style={{ marginTop: "1vw", marginBottom: "1vw" }}
        >
          <BackButton />
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Delete confirmation")}
          body={modalBody}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle={t("No")}
          confirmTitle={"Yes"}
        />
        {popup.isModelOpen && (
          <Popup
            title={t("Company Details")}
            body={
              <FormatData
                data={modalData}
                excludeData={ExcludeContactCompanyData}
              />
            }
            cancel={closeModal}
            modalSize="lg"
            notext="Close"
            cancelButtonClass="shadow-none float-end d-flex justify-content-center"
          />
        )}
      </>
    </AccessControl>
  );
};

export default ManageContactPerson;
