import {
  PURCHASE_ORDER_CREATE,
  PURCHASE_ORDER_EDIT,
} from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import {
  PATH_CREATE_PURCHASE_ORDER,
  PATH_VIEW_PURCHASE_ORDER,
} from "constants/Paths";
import { useLocation, useParams } from "react-router-dom";

import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { Suspense } from "react";
import FormBuilder from "services/form/FormBuilder";
import { PurchaseOrderValidation } from "services/validation/ValidationRules";
import { getCompanyOptions } from "../utils/FetchOptions";

export type CustomFnType = (payload: any) => any;

const AddPurchaseOrder = () => {
  const location = useLocation();
  const path = location.pathname;
  const { id: routeId } = useParams<{ id: string }>();

  const permissionType = routeId ? "update" : "create";
  const permissionObject: any = {
    permission: "PurchaseOrder",
  };
  permissionObject[permissionType] = true;
  const userData = useSelector(selectAuth);

  const customFunction = async (formInputs: any, selectedOption: any, dependency: any, dependencyArray: any) => {
    if (dependency === 'ownerCompany') {
      const newState = await Promise.all(
        formInputs.map(async (record: any) => {
          if (dependencyArray.includes(record.alias)) {
            const response = await getCompanyOptions(selectedOption);
            return { ...record, options: response, value: '' };
          }
          return record;
        })
      );

      return newState;
    }
    return formInputs;
  }

  return (
    <>
      <FormBuilder
        actionType={path === PATH_CREATE_PURCHASE_ORDER ? "create" : "edit"} // action type create or view
        entryId={routeId} // required only if actionType is view
        getDataAPI={"puchaseOrder"} //required only if actionType is view
        SaveDataAPI={PURCHASE_ORDER_CREATE} //Post's data to this endpoint
        formName={"PurchaseOrder"} //Form name
        title={
          path === PATH_CREATE_PURCHASE_ORDER
            ? "Create puchase order"
            : (path.includes(PATH_VIEW_PURCHASE_ORDER) ? "View purchase order" : "Edit puchase order")
        } // Form title
        redirect={"/fleetOverview/purchaseOrder"} // After submit redirect to this screen/route
        validationRules={PurchaseOrderValidation} //Validation rules to validate form on submit
        microserviceName={M_MASTER_DATA}
        mode={path.includes(PATH_VIEW_PURCHASE_ORDER)}
        customFunction={customFunction}
      />
    </>
  );
};

export default AddPurchaseOrder;
