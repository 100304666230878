import CustomNotify from "components/atoms/CustomNotify";
import Title from "components/atoms/Title";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { DELETE_QUESTIONS, MANAGE_QUESTIONS } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { mapToSelect } from "utils/MapToSelect";
import { OptionProps } from "utils/TypeAnnotations";
import { t } from "../translation/Translation";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import Button from "components/atoms/Button";
import Reset from "static/images/ResetIcon";
import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "react-bootstrap";
import EditIcon from "static/images/EditIcon";
import ViewIcon from "static/images/ViewIcon";
import DeleteIcon from "static/images/DeleteIcon";
import Pagination from "components/atoms/Pagination";
import ModalPopup from "components/atoms/ModalPopup";
import CloneIcon from "static/images/CloneIcon";
import LinkTo from "components/atoms/LinkTo";
import { M_MASTER_DATA } from "constants/Constants";
import TableStructure from "components/atoms/TableStructure";
import "../../../../../src/static/css/questions.css";
import { GeneralQuestionsTableHeader, QuestionsTableHeader } from "TableHeader";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { IconData } from "services/IconData";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import BackButton from "components/atoms/BackButton";
interface manageData {
  domainName: string;
  functnName: string;
  title: string;
  PC: number;
  questionId: number;
  id: number;
}

interface generalData {
  category: string;
  title: string;
  questionId: number;
  id: number;
}

interface dropDownsProps {
  domain: OptionProps[];
  functn: OptionProps[];
  pcSection: OptionProps[];
  pcCategory: OptionProps[];
}

const ManageQuestions: React.FC = () => {
  //   const canCreate = CanPermissions(CONST.CREATE, CONST.QUESTIONS);
  //   const canUpdate = CanPermissions(CONST.UPDATE, CONST.QUESTIONS);
  //   const canDelete = CanPermissions(CONST.DELETE, CONST.QUESTIONS);
  //   const canView = CanPermissions(CONST.READ, CONST.QUESTIONS);

  //initiliaze the formData state for search fields
  const [formData, setFormData] = useState<dropDownsProps>({
    domain: [],
    functn: [],
    pcSection: [],
    pcCategory: [],
  });
  const columns = ["PC", "functnName"];
  const generalCategoryColumns = ["category"];
  const [searchParams, setSearchParams] = useSearchParams();
  const [isGeneralQuestionsAdded, setIsGeneralQuestionsAdded] = useState(true);
  //initiliaze the state for dropdown values
  const [dropDowns, setDropDowns] = useState({
    functn: [] as OptionProps[],
    pcSection: [] as OptionProps[],
    pcCategory: [] as OptionProps[],
  });

  const [category, setCategory] = useState("");

  //state for to store the functions linked countries
  const [questions, setQuestions] = useState([] as manageData[]);

  const [pcSection, setPcSection] = useState([] as manageData[]);
  const [generalData, setGeneralData] = useState([] as generalData[]);
  //state for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deleteID, setDeleteId] = useState<number | undefined>();
  // const [isFilter, setIsFilter] = useState<boolean>(true); // Example boolean state
  // const [manageData, setManageData] = useState([]);

  // const handleFunctionChange = (selectedFunctionIds: any) => {
  //   const fetchDomainForFunctionDetails = async () => {
  //     const response = await ApiCall.service(
  //       ENDPOINTS.GET_DOMAIN_FOR_FUNCTION,
  //       "POST",
  //       selectedFunctionIds,
  //       false,
  //       M_MASTER_DATA
  //     );
  //     if (response.status === 200) {
  //       setFormData((prevData) => ({
  //         ...prevData,
  //         domain: response.data,
  //       }));
  //     }
  //   };
  //   fetchDomainForFunctionDetails();
  // };

  // const handleDomainChange = (selectedDomainIds: any) => {
  //   const fetchFunctionDetailsForDomain = async () => {
  //     const response = await ApiCall.service(
  //       ENDPOINTS.GET_FUNCTION_FOR_DOMAIN,
  //       "POST",
  //       selectedDomainIds,
  //       false,
  //       M_MASTER_DATA
  //     );
  //     if (response.status === 200) {
  //       setDropDowns((prevData) => ({
  //         ...prevData,
  //         functn: response.data,
  //       }));
  //     }
  //   };
  //   fetchFunctionDetailsForDomain();
  // };

  useEffect(() => {
    fetchQuestionsBySearch(formData);
  }, [formData.pcSection, formData.pcCategory]);

  //API call for to fetch questions
  const fetchQuestionsBySearch = async (formData = {}, page = 1) => {
    const type = searchParams.get("type");
    const requestData = {
      type: type === null ? "1" : type,
      form: formData,
      page: page,
    };

    const response = await ApiCall.service(
      MANAGE_QUESTIONS,
      "POST",
      requestData,
      false,
      M_MASTER_DATA
    );
    if (response.status === 200) {
      const functions = response.functn ? mapToSelect(response?.functn) : [];
      const pcSections = mapToSelect(response.pcSection, "pc_number");
      const pcCategories = response.pcCategory
        ? mapToSelect(response?.pcCategory)
        : [];
      setDropDowns((prevData) => ({
        ...prevData,
        pcSection: pcSections,
        functn: functions,
        pcCategory: pcCategories,
      }));
      setIsGeneralQuestionsAdded(response.isGeneralQuestionsAdded);
      type === "2"
        ? setQuestions(response.data)
        : setGeneralData(response.data);
      setPcSection(response.pcSection);
      setTotalPages(response.totalPages);
      setLoading(false);
      // Category
      type === "1" || type === null
        ? setCategory("General")
        : setCategory("Function");
      // }
    }
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (
      // formData.domain.length === 0 &&
      formData.functn.length === 0 &&
      formData.pcSection.length === 0
    ) {
      CustomNotify({ type: "warning", message: "Search input not provided." });
      fetchQuestionsBySearch();
    } else {
      fetchQuestionsBySearch(formData, 1);
    }
  };

  //changehandler for search fields
  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
    if (fieldName === "pcSection") {
      setFormData((prev) => ({
        ...prev,
        // pcCategory: [],
        functn: [],
      }));
    }
    // commonValidation(fieldName, selectedOption, true);
  };

  const handleReset = () => {
    // Clear the selected values in the dropdowns
    setFormData({
      domain: [],
      functn: [],
      pcSection: [],
      pcCategory: [],
    });
    setCurrentPage(1);
    fetchQuestionsBySearch();
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchQuestionsBySearch(formData, newPage);
  };

  const deleteQuestions = (id: number | undefined) => {
    setShowModal(true);
    setDeleteId(id);
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const url = `${DELETE_QUESTIONS}/${deleteID}`;
      const response = await ApiCall.getService(url, "GET", M_MASTER_DATA);
      if (response.status === 200) {
        fetchQuestionsBySearch();
        setCurrentPage(1);
        CustomNotify({ type: "success", message: response.msg });
      }
    }
  };

  const handleTypeFilter = (
    type: string,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    if (type === "") {
      const currentUrl = window.location.href;
      if (currentUrl.includes("?")) {
        const newUrl = currentUrl.split("?")[0];
        window.location.replace(newUrl);
      }
    } else {
      searchParams.set("type", type);
      setSearchParams(searchParams);
      setFormData({
        domain: [],
        functn: [],
        pcSection: [],
        pcCategory: [],
      });
    }
    setCurrentPage(1);
    fetchQuestionsBySearch();
  };

  const type: any = searchParams.get("type");

  const editQuestions = (entity: any) => {
    window.location.href = `/edit/question/${category}/${entity.questionId}`;
  };
  const deleteQuestion = (entity: any) => {
    deleteQuestions(entity.questionId);
  };
  const viewQuestions = (entity: any) => {
    window.location.href = `/view/question/${category}/${entity.questionId}`;
  };
  const cloneQuestions = (entity: any) => {
    window.location.href = `/clone/question/${category}/${entity.questionId}`;
  };
  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Questions",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <div className="search-bar">
        <Title title={t("Manage questions")} />
        <div className="row">
          <div className="col-12 vacancy-tabs question-tabs ">
            <button
              className={`link ${
                type === "1" || type === null ? "active" : ""
              } text-decoration-none border-0 bg-transparent me-3 ps-0`}
              onClick={(e) => handleTypeFilter("1", e)}
              style={{ cursor: "pointer" }}
            >
              {t("General")}
            </button>
            <button
              className={`link ${
                type === "2" ? "active" : ""
              } text-decoration-none border-0 bg-transparent ps-0`}
              onClick={(e) => handleTypeFilter("2", e)}
              style={{ cursor: "pointer" }}
            >
              {t("Function")}
            </button>
          </div>
        </div>
        {type === "2" && (
          <div className="row" style={{ paddingTop: "0.5vw" }}>
            <div className="col-3">
              <SelectWithSearch
                isMandatory={false}
                search={true}
                options={dropDowns.pcSection}
                onChange={(e) => handleSelectChange(e, "pcSection")}
                isMulti={false}
                name="pc"
                value={formData.pcSection}
                placeHolder={t("Select paritair committee")}
              />
            </div>
            <div className="col-3">
              <SelectWithSearch
                isMandatory={false}
                search={true}
                options={dropDowns.functn}
                onChange={(e) => handleSelectChange(e, "functn")}
                isMulti={true}
                name="funct"
                value={formData.functn}
                placeHolder={t("Select function")}
              />
            </div>
            <div className="col-3">
              <div className="d-flex align-items-center">
                <ResetBtn handleResetClick={handleReset} />
                <SearchBtn handleSearchClick={handleSubmit} />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="pb-3">
        {/* Category */}
        {/* <div className="row">
          <div className="col-md-12 manage-function-search">
          <div className="row">
                    <div className="col-md-12">
                      <SelectWithSearch
                        search={true}
                        options={dropDowns.functn}
                        title={t("Function")}
                        onChange={(e) => handleSelectChange(e, "functn")}
                        isMulti={true}
                        name="functn"
                       value={formData.functn}
                        placeHolder="select"
                      />
                    </div>
                 </div> */}
        <div className="position-relative tableMainWrapper">
          {((isGeneralQuestionsAdded === false &&
            (type === null || type === "1")) ||
            type === "2") && (
            <div className="table-top-buttons-block">
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Questions",
                    create: true,
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <div className="row">
                  <div className="ManageCreateBtn">
                    <LinkTo
                      pagelink={`/add/question/${category}`}
                      title={t("Add question")}
                      icon={faPlus}
                    />
                  </div>
                </div>
              </AccessControl>
            </div>
          )}
          <div className="table-responsive tableSection">
            <TableStructure
              isAction
              headers={
                type === "2"
                  ? QuestionsTableHeader
                  : GeneralQuestionsTableHeader
              }
              // data={type === "2" ? questions : generalCategory}
              data={type === "2" ? questions : generalData}
              // data={ questions }
              values={type === "2" ? columns : generalCategoryColumns}
              handleEdit={editQuestions}
              handleView={viewQuestions}
              handleClone={type === "2" ? cloneQuestions : undefined}
              handleDelete={deleteQuestion}
              permissionType={"Questions"}
            />
            {totalPages > 1 && (
              <div
                className="pagination justify-content-center align-items-center"
                style={{ paddingTop: "0.25vw" }}
              >
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-md-6 align-self-center" style={{ padding: "1vw 0" }}>
        <BackButton />
      </div>
      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title={t("Delete confirmation")}
        body={t("Are you sure want to delete?")}
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle={t("No")}
        confirmTitle={t("Yes")}
      />
    </AccessControl>
  );
};

export default ManageQuestions;
