import React from 'react'

const AddWageProposalIcon: React.FC = () => {
  return (
    <>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="black" xmlns="http://www.w3.org/2000/svg">
<path d="M20.1854 19.1941H27.1864C28.3594 19.1941 29.3144 18.2391 29.3144 17.0661V10.0651C29.3144 8.89206 28.3594 7.93706 27.
1864 7.93706H20.1854C19.0124 7.93706 18.0574 8.89206 18.0574 10.0651V17.0661C18.0564 18.2401 19.0114 19.1941 20.1854 19.1941ZM2
0.4564 10.3361H26.9154V16.7951H20.4564V10.3361ZM33.8124 16.6791C33.8124 16.0161 34.3494 15.4791 35.0124 15.4791H56.7954C57.4584 15
.4791 57.9954 16.0161 57.9954 16.6791C57.9954 17.3421 57.4584 17.8791 56.7954 17.8791H35.0124C34.3504 17.8791 33.8124 17.3421 33.812
4 16.6791ZM26.0874 11.6321C26.5624 12.0941 26.5734 12.8531 26.1114 13.3281L24.0254 15.4751C23.7904 15.7171 23.4774 15.8391 23.1644 15.
8391C22.8654 15.8391 22.5654 15.7281 22.3334 15.5041L21.2874 14.4991C20.8094 14.0401 20.7944 13.2801 21.2534 12.8031C21.7124 12.3251 2
2.4724 12.3101 22.9494 12.7691L23.1354 12.9471L24.3904 11.6561C24.8524 11.1811 25.6114 11.1701 26.0874 11.6321ZM33.8124 23.4111C33.8124 
22.7481 34.3494 22.2111 35.0124 22.2111H56.7954C57.4584 22.2111 57.9954 22.7481 57.9954 23.4111C57.9954 24.0741 57.4584 24.6111 56.7954 24.
6111H35.0124C34.3504 24.6111 33.8124 24.0741 33.8124 23.4111ZM20.1854 32.1531H27.1864C28.3594 32.1531 29.3144 31.1981 29.3144 30.0251V23
.0241C29.3144 21.8511 28.3594 20.8961 27.1864 20.8961H20.1854C19.0124 20.8961 18.0574 21.8511 18.0574 23.0241V30.0251C18.0564 31.1991 19.0
114 32.1531 20.1854 32.1531ZM20.4564 23.2961H26.9154V29.7551H20.4564V23.2961ZM33.8124 29.6381C33.8124 28.9751 34.3494 28.4381 35.0124 28.43
81H56.7954C57.4584 28.4381 57.9954 28.9751 57.9954 29.6381C57.9954 30.3011 57.4584 30.8381 56.7954 30.8381H35.0124C34.3504 30.8381 33.8124 3
0.3011 33.8124 29.6381ZM26.0874 24.5911C26.5624 25.0531 26.5734 25.8121 26.1114 26.2871L24.0254 28.4341C23.7904 28.6761 23.4774 28.7981 23.16
44 28.7981C22.8654 28.7981 22.5664 28.6871 22.3334 28.4631L21.2874 27.4581C20.8094 26.9991 20.7944 26.2391 21.2534 25.7621C21.7124 25.2841 22
.4724 25.2691 22.9494 25.7281L23.1354 25.9061L24.3904 24.6151C24.8524 24.1401 25.6114 24.1291 26.0874 24.5911ZM33.8124 36.3711C33.8124 
35.7081 34.3494 35.1711 35.0124 35.1711H56.7954C57.4584 35.1711 57.9954 35.7081 57.9954 36.3711C57.9954 37.0341 57.4584 37.5711 56.7954 3
7.5711H35.0124C34.3504 37.5701 33.8124 37.0331 33.8124 36.3711ZM21.2884 40.4171C20.8104 39.9581 20.7954 39.1981 21.2544 38.7211C21.7134 3
8.2431 22.4734 38.2281 22.9504 38.6871L23.1364 38.8651L24.3914 37.5741C24.8524 37.0991 25.6124 37.0881 26.0874 37.5491C26.5624 38.0111 26.5
734 38.7701 26.1114 39.2451L24.0254 41.3921C23.7904 41.6341 23.4774 41.7561 23.1644 41.7561C22.8654 41.7561 22.5664 41.6451 22.3334 41.421
1L21.2884 40.4171ZM75.1104 51.2381V37.2621C75.1104 35.6601 74.2564 34.2721 72.8264 33.5501L64.0574 29.1201V14.9631C64.0574 13.2681 63.4494 
11.8001 62.2504 10.6011L53.6414 1.99206C52.4424 0.793058 50.9754 0.185059 49.2794 0.185059H16.9594C14.2214 0.185059 11.9944 2.41206 11.9944 
5.15006V29.1201L3.22541 33.5501C1.79541 34.2721 0.941406 35.6601 0.941406 37.2621V70.1951C0.941406 72.4881 2.80741 74.3541 5.10041 74.3541H5
0.2084C52.6304 81.9201 59.7294 87.4141 68.0894 87.4141C78.4394 87.4141 86.8594 78.9941 86.8594 68.6441C86.8594 60.7761 81.9914 54.0241 75.1104
 51.2381ZM68.0894 49.8741C63.7214 49.8741 59.6974 51.3741 56.5054 53.8851L49.3824 46.1981L72.7074 37.1911C72.7084 37.2141 72.7124 37.2371 7
 2.7124 37.2611V50.4501C71.2324 50.0751 69.6834 49.8741 68.0894 49.8741ZM70.9594 35.2951L64.0574 37.9601V31.8081L70.9594 35.2951ZM59.9614 11.
 7061H52.5804C52.5594 11.7061 52.5374 11.6851 52.5374 11.6631V4.28206L59.9614 11.7061ZM16.9594 2.58506H49.2804C49.5814 2.58506 49.8664 2
 .61806 50.1394 2.67806V9.25306H35.0124C34.3494 9.25306 33.8124 9.79006 33.8124 10.4531C33.8124 11.1161 34.3494 11.6531 35.0124 11.6531H5
 0.1384V11.6641C50.1384 13.0101 51.2334 14.1061 52.5804 14.1061H61.5654C61.6254 14.3791 61.6584 14.6641 61.6584 14.9651V38.8881L55.1544
  41.4001H35.0124C34.3494 41.4001 33.8124 41.9371 33.8124 42.6001C33.8124 43.2631 34.3494 43.8001 35.0124 43.8001H48.9404L38.0254 48.0151L
  28.7444 44.4311C29.0964 44.0511 29.3134 43.5451 29.3134 42.9871V35.9861C29.3134 34.8131 28.3584 33.8581 27.1854 33.8581H20.1844C19.0114 33
  .8581 18.0564 34.8131 18.0564 35.9861V40.3031L14.3934 38.8881V5.15106C14.3934 3.73606 15.5444 2.58506 16.9594 2.58506ZM3.34041 70.1691V37.2
  621C3.34041 37.2381 3.34441 37.2161 3.34541 37.1921L26.4094 46.0981L3.34041 70.1691ZM26.9144 42.7131H24.3044L20.4554 41.2271V36.2541H26.9144V42
  .7131ZM11.9944 37.9601L5.09241 35.2951L11.9944 31.8081V37.9601ZM5.10041 71.9551C5.05241 71.9551 5.00541 71.9521 4.95941 71.9481L28.8354 4
  7.0351L37.5944 50.4171C37.7334 50.4711 37.8804 50.4971 38.0264 50.4971C38.1724 50.4971 38.3194 50.4701 38.4584 50.4171L46.9724 47.1291L54.7
  174 55.4861C51.3814 58.8761 49.3194 63.5231 49.3194 68.6441C49.3194 69.7731 49.4194 70.8801 49.6114 71.9551H5.10041ZM68.0894 85.0151C59.0624
   85.0151 51.7184 77.6711 51.7184 68.6441C51.7184 59.6171 59.0624 52.2731 68.0894 52.2731C77.1164 52.2731 84.4604 59.6171 84.4604 68.6441C84
   .4604 77.6711 77.1164 85.0151 68.0894 85.0151ZM68.0894 54.7621C60.4344 54.7621 54.2064 60.9901 54.2064 68.6451C54.2064 76.3001 60.4344 82.5
   281 68.0894 82.5281C75.7444 82.5281 81.9724 76.3001 81.9724 68.6451C81.9724 60.9901 75.7444 54.7621 68.0894 54.7621ZM68.0894 80.1281C61.757
   4 80.1281 56.6064 74.9771 56.6064 68.6451C56.6064 62.3131 61.7574 57.1621 68.0894 57.1621C74.4214 57.1621 79.5724 62.3131 79.5724 68.6451C7
   9.5724 74.9761 74.4214 80.1281 68.0894 80.1281ZM69.2884 67.5931V63.1221C69.4534 63.2231 69.6094 63.3431 69.7504 63.4851C70.1894 63.9291 70
   .4264 64.5021 70.4194 65.0991C70.4114 65.7621 70.9424 66.3051 71.6054 66.3131C71.6104 66.3131 71.6154 66.3131 71.6194 66.3131C72.2754 66
   .3131 72.8114 65.7851 72.8184 65.1271C72.8334 63.8841 72.3494 62.7021 71.4564 61.7991C70.8454 61.1811 70.0994 60.7511 69.2884 60.5381V59.6
   981C69.2884 59.0351 68.7514 58.4981 68.0884 58.4981C67.4254 58.4981 66.8884 59.0351 66.8884 59.6981V60.5381C64.8594 61.0701 63.3574 62.9201
    63.3574 65.1141C63.3574 66.3871 63.8564 67.5831 64.7624 68.4811C65.3634 69.0771 66.0944 69.4911 66.8884 69.6961V74.1671C66.7234 74.0661 66
    .5674 73.9461 66.4264 73.8041C65.9874 73.3601 65.7504 72.7871 65.7574 72.1901C65.7654 71.5271 65.2344 70.9841 64.5714 70.9761C64.5664 70
    .9761 64.5614 70.9761 64.5574 70.9761C63.9014 70.9761 63.3654 71.5041 63.3584 72.1621C63.3444 73.4051 63.8274 74.5871 64.7204 75.4901C65
    .3314 76.1081 66.0774 76.5381 66.8894 76.7511V77.5911C66.8894 78.2541 67.4264 78.7911 68.0894 78.7911C68.7524 78.7911 69.2894 78.2541 69.2
    894 77.5911V76.7511C71.3184 76.2191 72.8204 74.3691 72.8204 72.1751C72.8204 70.9021 72.3214 69.7071 71.4154 68.8081C70.8134 68.2121 70.083
    4 67.7981 69.2884 67.5931ZM66.4524 66.7771C66.0044 66.3331 65.7574 65.7421 65.7574 65.1131C65.7574 64.2671 66.2124 63.5261 66.8894 63.1181
    V67.1151C66.7334 67.0211 66.5864 66.9091 66.4524 66.7771ZM69.2884 74.1701V70.1731C69.4444 70.2681 69.5914 70.3791 69.7254 70.5111C70.1734 70.9551 70.4204 7
    1.5461 70.4204 72.1741C70.4204 7
    3.0221 69.9654 73.7621 69.2884 74.1701Z" fill="black"/>
</svg>

  </>
  )
}

export default AddWageProposalIcon
