import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Button from "components/atoms/Button";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import Calender from "components/molecules/Calender";
import TableActions from "components/organism/Actions/TableAction";
import { PATH_PROJECTS_MANAGE } from "constants/Paths";
import { t } from "pages/microservices/masterData/translation/Translation";
import CompetenceModal from "./competenceModel";
import AddProjectModal from "./addProjectModel";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import {
  DELETE_HOTLIST_DATA,
  GET_COMPETENCE_TYPE,
  GET_EMPLOYEES,
  GET_LOCATION_FUNCTION_OPTION,
  SAVE_HOTLIST_DATA,
} from "routes/ApiEndpoints";
import CustomNotify from "components/atoms/CustomNotify";
import { useNavigate } from "react-router-dom";
import Pagination from "components/atoms/Pagination";
import { PATH_HR_PLANNING } from "constants/Paths";
import { IconData } from "services/IconData";
import BackButton from "components/atoms/BackButton";
interface EmployeeData {
  id: number;
  name: string;
  location: string;
  contractType: string;
  function: [];
  margin: string;
  marginPercentage: string;
  fromDate: string;
  toDate: string;
  score: number;
  hotlistStatus: any;
  addProjectStatus: [];
}
interface CompetenceSection {
  id: any;
  key: string;
  label: string;
  options: { label: string; value: string }[];
}
interface Option {
  label: string;
  value: string;
  range: string;
}
interface DataProps {
  page: number;
  search?: any;
}
interface SearchData {
  location: string | null;
  function: string | null;
  radius: number | null;
  startDate: string | null;
  endDate: string | null;
}

export const MatchingSearch: React.FC = ({}) => {
  const url = useLocation();
  const pathname = url.pathname;
  const segments = pathname.split("/");
  // const companyId = segments[segments.length - 1];
  // const projectId = segments[segments.length - 2];

  const [showCompetenceModal, setShowCompetenceModal] = useState(false);
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);
  const [option, setOptions] = useState({
    location: [],
    function: [],
    radius: [],
  });
  const [submit, setSubmit] = useState(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [selectedCompetenceOptions, setSelectedCompetenceOptions] = useState<{
    [id: string]: {
      key: string;
      label: string;
      options: Option[];
    };
  }>({});
  const queryParams = new URLSearchParams(window.location.search);

  const [isRadiusMandatory, setIsRadiusMandatory] = useState(false);

  useEffect(() => {
    const data: DataProps = {
      page: currentPage,
      search: selectedOptions,
    };
    fetchEmployees(data);
  }, [currentPage, submit]);

  const [competenceData, setCompetenceData] = useState<CompetenceSection[]>([]);
  const [selectedOptions, setSelectedOptions] = useState({
    location: null,
    function: null,
    radius: null,
    startDate: "",
    endDate: "",
    competance: null,
  });
  const [employeData, setEmployeeData] = useState<EmployeeData[]>([]);
  const [selectedemployee, setEmployee] = useState("");
  const params = useParams();
  const projectId = params.element;
  const companyId = params.id;
  const navigate = useNavigate();

  const [employeeId, setEmployeeId] = useState(0);
  const [removeStatus, setRemoveStatus] = useState<boolean>(false);

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [fieldName]: selectedOption.value,
    }));
    if (fieldName === "location" && selectedOption.value) {
      setIsRadiusMandatory(true);
    } else if (fieldName === "location" && !selectedOption.value) {
      setIsRadiusMandatory(false);
    }
  };
  const handleDateChangeCheck = (value: Date | null, fieldName: string) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };
  const handleAddCompetenceClick = () => {
    setShowCompetenceModal(true);
  };
  const createQueryParams = (data: SearchData) => {
    const queryParams = Object.entries(data)
      .filter(
        ([key, value]) => value !== undefined && value !== "" && value != null
      )
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return queryParams;
  };
  const handleModalClose = () => {
    setShowCompetenceModal(false);
    setShowAddProjectModal(false);
    setSelectedCompetenceOptions({});
    const data: DataProps = {
      page: currentPage,
      search: selectedOptions,
    };
    fetchEmployees(data);
  };
  const handleSubmitAction = (clear = false) => {
    const newPage = 1;
    setCurrentPage(newPage);
    if (!clear) {
      if (isRadiusMandatory && !selectedOptions.radius) {
        CustomNotify({
          type: "error",
          message: "Please select radius",
        });
        return;
      }
    }
    if (clear) {
      setSelectedOptions({
        location: null,
        function: null,
        radius: null,
        startDate: "",
        endDate: "",
        competance: null,
      });
      setSelectedCompetenceOptions({});
      navigate({
        pathname: window.location.pathname,
        search: `?page=${newPage}`,
      });
      setIsRadiusMandatory(false);
    } else {
      const queryParams = createQueryParams(selectedOptions);
      if (queryParams) {
        navigate({
          pathname: window.location.pathname,
          search: `?${queryParams}&page=${newPage}`,
        });
      } else {
        CustomNotify({
          type: "warning",
          message: t("Search input not provided."),
        });
      }
    }

    setSubmit((prevSubmit) => !prevSubmit);
  };
  const handleSearch = async (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const handleAddPlanning = (value: any) => {
    navigate(`${PATH_HR_PLANNING}?employee=${value.id}`);
  };

  const handleAddProject = (item: any) => {
    setEmployeeId(item.id);
    setShowAddProjectModal(true);
    setRemoveStatus(false);
  };

  const handleAddHotlist = async (item: any) => {
    const postData = {
      category: "employee",
      categoryValue: item.id,
    };
    try {
      const response = await ApiCall.service(
        SAVE_HOTLIST_DATA,
        "POST",
        postData,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setSubmit(!submit);
        CustomNotify({
          type: "success",
          message: "Employee successfully added to hotlist",
        });
        const data: DataProps = {
          page: currentPage,
          search: selectedOptions,
        };
        fetchEmployees(data);
      } else if (item.hotlistStatus) {
        if (item.id) {
          const id = {
            id: item.id,
            page: "matching",
          };
          const response = await ApiCall.service(
            DELETE_HOTLIST_DATA,
            "POST",
            id,
            false,
            M_MASTER_DATA
          );
          if (response.status === 200) {
            setSubmit(!submit);
            CustomNotify({
              type: "success",
              message: "Deleted  successfully from  hotlist",
            });
            const data: DataProps = {
              page: currentPage,
              search: selectedOptions,
            };
            fetchEmployees(data);
          } else {
            CustomNotify({ type: "success", message: "unableto delete" });
          }
          // setSubmit(!submit);
        }
      } else {
        CustomNotify({
          type: "warning",
          message: response.message,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const fetchCompetenceType = async () => {
      try {
        const response = await ApiCall.getService(
          GET_COMPETENCE_TYPE,
          "GET",
          M_MASTER_DATA
        );
        if (response?.status === 200) {
          setCompetenceData(response?.data);
        }
      } catch (error) {
        // Handle error
      }
    };
    fetchCompetenceType();
  }, []);

  useEffect(() => {
    const fetchLocationAndFunctionOption = async () => {
      try {
        const response = await ApiCall.service(
          GET_LOCATION_FUNCTION_OPTION,
          "POST",
          { projectId: projectId },
          false,
          M_MASTER_DATA
        );

        if (response?.status === 200) {
          setOptions({
            location: response?.data.location || [],
            function: response?.data.function || [],
            radius: response?.data.radius || [],
          });
        }
      } catch (error) {
        //   console.error("Error fetching data:", error);
      }
    };
    const data: DataProps = {
      page: currentPage,
      search: selectedOptions,
    };
    fetchLocationAndFunctionOption();
    fetchEmployees(data);
  }, []);
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const fetchEmployees = async (data: any) => {
    try {
      const response = await ApiCall.service(
        GET_EMPLOYEES,
        "POST",
        data,
        false,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        setTotalPages(response?.totalPages);
        const employeeDataFromAPI: EmployeeData[] = response.data.map(
          (item: any) => ({
            id: item.id,
            name: item.full_name,
            location: "", // Fill this with appropriate data
            contractType: item.contract_type,
            function: item.functions,
            margin: "",
            marginPercentage: "",
            fromDate: "",
            toDate: "",
            score: item.score, // Set it to an appropriate boolean value
            hotlistStatus: item.user_hot_list
              ? item.user_hot_list.status
              : false,
            addProjectStatus: item.existingEmployees,
          })
        );
        setEmployeeData(employeeDataFromAPI);
      }
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };
  const handleAddCompetence = (selectedOptions: any) => {
    setSelectedCompetenceOptions(selectedOptions);
    setSelectedOptions((prevState) => ({
      ...prevState,
      ["competance"]: selectedOptions,
    }));
    setShowCompetenceModal(false);
  };

  const handleRemoveEmployee = (item: EmployeeData) => {
    setEmployeeId(item.id);
    setShowAddProjectModal(true);
    const statusCheck: any =
      item.addProjectStatus &&
      item.addProjectStatus.length > 0 &&
      item.addProjectStatus.filter(
        (record) =>
          record["project_id"] == projectId && record["company_id"] == companyId
      ).length !== 0;
    setRemoveStatus(statusCheck);
  };
  return (
    <>
      <h1 className="py-4 page-title mb-0">{t("Matching project")}</h1>
      <div className="row">
        {/* Location Select */}
        <div className="col-4">
          <SelectWithSearch
            title="Location"
            name="location"
            isMandatory={false}
            search={true}
            placeHolder="Select Location"
            options={option.location}
            value={selectedOptions.location}
            onChange={(e) => handleSelectChange(e, "location")}
            className="select-field"
            error={""}
          />
        </div>
        {/* Radius Select */}
        <div className="col-4">
          <SelectWithSearch
            title="Radius"
            name="radius"
            isMandatory={isRadiusMandatory}
            search={true}
            placeHolder="Select Radius"
            options={option.radius}
            value={selectedOptions.radius}
            onChange={(e) => handleSelectChange(e, "radius")}
            className="select-field"
            error={""}
          />
        </div>
        {/* Function Select */}
        <div className="col-4">
          <SelectWithSearch
            title="Function"
            name="function"
            isMandatory={false}
            search={true}
            placeHolder="Select Function"
            options={option.function}
            value={selectedOptions.function}
            onChange={(e) => handleSelectChange(e, "function")}
            className="select-field"
            error={""}
          />
        </div>
        {/* Start Date Calender */}
        <div className="col-6 col-xl-3">
          <Calender
            onChange={(date) => handleDateChangeCheck(date, "startDate")}
            selectedDate={selectedOptions.startDate}
            label={t("Start date")}
            isMandatory={false}
            name={"startDate"}
            error={""}
            isDisabled={false}
          />
        </div>
        {/* End Date Calender */}
        <div className="col-6 col-xl-3">
          <Calender
            onChange={(date) => handleDateChangeCheck(date, "endDate")}
            selectedDate={selectedOptions.endDate}
            label={t("End date")}
            isMandatory={false}
            name={"endtDate"}
            error={""}
            isDisabled={false}
          />
        </div>
        {/* Search Button */}
        <div className="col-6 col-xl-3 text-end align-self-center">
          <Button
            title={IconData.ResetIcon}
            type="submit"
            handleClick={(e) => handleSearch(e, true)}
            className="btn delete-btn  shadow-none text-uppercase me-3 "
          />
          <Button
            title={t("Search")}
            type="submit"
            handleClick={handleSearch}
            className="btn form-button  shadow-none text-uppercase search-btns"
          />
        </div>
      </div>
      <div className="p-5 border rounded-4 table-custom">
        {/* Add Competence Button */}
        <div className="table-top-buttons-block">
          <Button
            title={"+ " + t("Add Competence")}
            handleClick={handleAddCompetenceClick}
            className="btn form-button float-end text-uppercase shadow-none"
          />
        </div>
        {/* Table */}
        <div className="table-responsive manage-companies">
          <table className="table table-hover">
            <thead>
              <tr className="TableHeader">
                <th>{t("Name")}</th>
                <th>{t("Location")}</th>
                <th>{t("Contract type")}</th>
                <th>{t("Function")}</th>
                <th>{t("Margin")}</th>
                <th>{t("Margin%")}</th>
                <th>{t("From")}</th>
                <th>{t("To")}</th>
                <th>{t("Matching score")}</th>
                <th className="table_actions">{t("Action")}</th>
              </tr>
            </thead>
            <tbody>
              {/* Render dummy data */}
              {employeData.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.location}</td>
                  <td>{item.contractType}</td>
                  <td>
                    {/* Display employee functions */}
                    {item.function.length > 0 ? item.function.join(", ") : "N/A"}
                  </td>
                  <td>{item.margin}</td>
                  <td>{item.marginPercentage}</td>
                  <td>{item.fromDate}</td>
                  <td>{item.toDate}</td>
                  <td data-label={t("Score")}>
                    <div className="d-flex align-items-center">
                      <div>
                        <span className="color-dark-pink">
                          {Math.round(item.score)}%
                        </span>
                      </div>
                      <div className="progress flex-1">
                        <div
                          className={`progress-bar`}
                          role="progressbar"
                          aria-valuenow={item.score}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          style={{ width: `${item.score}%` }}
                        ></div>
                      </div>
                    </div>
                  </td>
                  <td className="align-middle">
                    <TableActions
                      isAction={true}
                      handleAddPlanning={() => handleAddPlanning(item)}
                      handleAddProject={() => {
                        if (
                          item?.addProjectStatus &&
                          item?.addProjectStatus.length > 0
                        ) {
                          handleRemoveEmployee(item);
                        } else {
                          handleAddProject(item);
                        }
                      }}
                      projectAddedToEmployee={
                        item?.addProjectStatus &&
                          item?.addProjectStatus.length > 0 &&
                          item.addProjectStatus.filter(
                            (record) =>
                              record["project_id"] == projectId &&
                              record["company_id"] == companyId
                          ).length !== 0
                          ? true
                          : false
                      }
                      handleAddHotlist={() => handleAddHotlist(item)}
                      value={item.hotlistStatus}
                      permission={null}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="pagination justify-content-center align-items-center my-3 my-lg-0">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
      {/* Back Button */}
      <div className="d-flex justify-content-between align-items-center my-4">
        <BackButton />
      </div>
      {/* Competence Modal */}
      {showCompetenceModal && (
        <CompetenceModal
          handleClose={handleModalClose}
          handleAdd={handleAddCompetence}
          selectedCompetenceOptions={selectedCompetenceOptions}
          setSelectedCompetenceOptions={setSelectedCompetenceOptions}
          competenceData={competenceData}
        />
      )}
      {/* Project Modal */}
      {showAddProjectModal && (
        <AddProjectModal
          removeStatus={removeStatus}
          handleClose={handleModalClose}
          url={url.pathname + "/" + employeeId}
        />
      )}
    </>
  );
};

export default MatchingSearch;
