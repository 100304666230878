import React from "react";

interface FormSubTitleProps {
  title?: string;
}

const FormSubTitle: React.FC<FormSubTitleProps> = ({ title }) => {
  return (
    <div className="col-md-12">
      <div className="tab-subtitle pb-1">{title}</div>
    </div>
  );
};
export default FormSubTitle;
