import BackButton from "components/atoms/BackButton";
import Button from "components/atoms/Button";
import { t } from "pages/microservices/masterData/translation/Translation";
import { Modal } from "react-bootstrap";

const FormNavigationModel = (props: any) => {

    const { setModalChange, setBack } = props;

    const handleClose = () => {
        setModalChange(false);
    }

    const handleBackClick = () => {
        setModalChange(false)
    }

    return (
        <Modal
            size="lg"
            show={true}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="bitter-italic-normal-medium-24">
                    {t(`Are you sure want to go back`)}
                </Modal.Title>
            </Modal.Header>
            {/* <Modal.Body className="border-0">


            </Modal.Body> */}
            <Modal.Footer className="border-0">
                <div className="d-flex align-items-center">
                    <Button
                        type="button"
                        title={t("No")}
                        className="close-button me-3"
                        handleClick={handleClose}
                    />
                    <BackButton title={"Yes"} className="form-button" />
                </div>
            </Modal.Footer>
        </Modal>
    )
}
export default FormNavigationModel;