import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormContext } from "../../context/Context";
import { DOCUMENTS, GENERAL, JOB_PROFILE, NEXT_STEP, PREVIOUS_STEP, UPDATE_ERROR_FIELD, UPDATE_FIELD, UPDATE_TAB_DETAILS } from "../../annotations/CandidateConstants";
import { t } from "pages/microservices/masterData/translation/Translation";
import Button from "components/atoms/Button";
import LoadingIcon from "utils/LoadingIcon";
import { ApiCall } from "services/ApiServices";
import { M_IDENTITY_MANAGER } from "constants/Constants";
import { CANDIDATE_CREATION } from "routes/ApiEndpoints";
import { validateMandatoryFields } from "./FormValidation";
import CustomNotify from "components/atoms/CustomNotify";
import { CREATE_CANDIDATE, MANAGE_CANDIDATES } from "constants/Paths";
import { UPDATE_TAB_ERROR } from "pages/microservices/vacancies/annotation/VacancyConstants";

export interface MandatoryFields {
  [key: string]: string[];
}

interface NavProps {
  validStatus: (value: { isValid: boolean; type: string }) => void;
  isLoading?: boolean;
}

const Navigation: React.FC<NavProps> = ({ validStatus, isLoading = false }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useFormContext();
  const { id } = useParams<{ id: string }>();

  const currentTab = state.tabDetails.find((tab) => tab.showStatus);
  const isGeneralTab = currentTab && currentTab.id === GENERAL;
  const isEmployeeDocumentsTab = currentTab && currentTab.id === DOCUMENTS;
  const formStatus = state.jobProfile.formStatus;

  const handleNextStep = () => {
    dispatch({ type: NEXT_STEP });
    handleSubmitFormData("next");
  };

  const handlePreviousStep = () => {
    dispatch({ type: PREVIOUS_STEP });
  };

  const handleSubmitFormData = async (type: string) => {
    const { dropDownData, errors, tabDetails, emergencyContactErrors, workExperienceErrors, ...postData } = state;
    const formStatus = type === "submit" ? 1 : state.jobProfile.formStatus;
    Object.assign(postData, { 'id': id }, { 'formStatus': formStatus }, { 'competence': state.dropDownData.competence });

    if (
      state.general.firstName !== "" &&
      state.general.lastName !== "" &&
      state.general.email !== "" &&
      state.general.phoneNumber !== ""
    ) {
      const response = await ApiCall.service(CANDIDATE_CREATION, "POST", postData, false, M_IDENTITY_MANAGER);
      if (response.status === 200) {
        if (type === 'draft') {
          CustomNotify({ type: 'success', message: "Candidate drafted successfully" });
          navigate(MANAGE_CANDIDATES);
        } else if (type === 'submit') {
          CustomNotify({ type: 'success', message: response.data.message });
          navigate(MANAGE_CANDIDATES);
        } else if (type === "next") {
          // navigate(`/candidate/create/${response.data.userId}?tabId=${nextTab.id}`);
          navigate(`/candidate/create/${response.data.userId}`);
        }
      } else if (response?.success === false) {
        if (response.message.length > 0) {
          dispatch({
            type: UPDATE_TAB_ERROR,
            tabIndex: 0,
            error: true,
          });
          response?.message.forEach((msg: string) => {
            // Extract the field name from the message
            let fieldName = msg.split(" ")[0] ?? "";
            const field = fieldName
              .replace(/([A-Z])/g, ' $1') // Add space before each capital letter
              .replace(/^./, char => char.toUpperCase()) // Capitalize the first letter
              .replace(/\s+[A-Z]/g, char => char.toLowerCase()); // Lowercase letters after spaces

            const updatedMessage = msg.replace(fieldName, field);
            if (field) {
              dispatch({
                type: UPDATE_ERROR_FIELD,
                field: fieldName,
                error: updatedMessage,
              });
            }
          });
          CustomNotify({ type: 'error', message: "Please check some errors are there" });
        }
      }
    }
  }

  const handleDraft = () => {
    const update = state.tabDetails.map((tab: any, index: any) => ({
      ...tab,
      draft: true,
    }));

    dispatch({ type: UPDATE_TAB_DETAILS, tabDetails: update });
    const isValid = validateMandatoryFields(state, dispatch, 'draft');

    if (isValid) {
      handleSubmitFormData('draft');
    }
  };

  const handleSubmit = async () => {
    const update = state.tabDetails.map((tab, index) => ({
      ...tab,
      draft: false,
    }));
    dispatch({ type: UPDATE_TAB_DETAILS, tabDetails: update });
    const isValid = validateMandatoryFields(state, dispatch, 'submit');
    if (isValid) {
      handleSubmitFormData('submit');
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6 align-self-center">
          {isGeneralTab && (
            <Link
              to=""
              className="back-btn text-decoration-underline"
              onClick={() => navigate(-1)}
            >
              {t("BACK")}
            </Link>
          )}
          {!isGeneralTab && (
            <Button
              type="button"
              title={t("BACK")}
              handleClick={handlePreviousStep}
              className="backBtn p-0 text-decoration-underline border-0"
            />
          )}
        </div>
        <div className="col-md-6 ">
          {!loading ? (
            <Button
              type="submit"
              title={isEmployeeDocumentsTab ? t("Submit") : t("Next")}
              handleClick={isEmployeeDocumentsTab ? handleSubmit : handleNextStep}
              className="float-end form-button px-3 ms-3 shadow-none"
            />
          ) : (
            <LoadingIcon
              iconType="bars"
              color="#e5007d"
              className="ms-auto mb-3"
              height={"3vw"}
              width={"3vw"}
            />
          )}
          {formStatus !== 1 && !isEmployeeDocumentsTab && (
            <Button
              type="submit"
              title={t("Save as draft")}
              handleClick={handleDraft}
              className="float-end form-button px-3 shadow-none"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Navigation;