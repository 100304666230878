import React, { useState } from "react";
import Search from "components/atoms/Search";
import Pagination from "components/atoms/Pagination";
import { Link, useNavigate } from "react-router-dom";
import { UserStatus } from "constants/Constants";
import { UserData } from "../users/ManageUsers";
import ModalPopup from "components/atoms/ModalPopup";
import CustomNotify from "components/atoms/CustomNotify";
import { ApiCall } from "services/ApiServices";
import Button from "components/atoms/Button";
import "static/css/users.css";
import { DELETE_USER, UNARCHIVE_USER } from "routes/ApiEndpoints";
import { t } from "pages/microservices/masterData/translation/Translation";
import TableStructure from "components/atoms/TableStructure";
import { ApplicantTableHeader } from "TableHeader";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { CandidateData } from "./ManageApplicants";
import { PATH_CANDIDATE_CORNER, PATH_CANDIDATE_SCREENING } from "constants/Paths";
import { IconData } from "services/IconData";
import { convertArrayToOptions } from "services/util/UtilService";
import FilterOrganism from "components/organism/FilterOrganism";
import { updatePageFilters } from "store/pageHistory/pageHistorySlice";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";

interface ManageUsersPageProps {
  usersObj: CandidateData[];
  deleteFilter: (deleteId: number) => void;
  dispatch: any;
  history: any;
  pageData: any;
  handleRefresh: () => void;
}

const ManageApplicantsPage: React.FC<ManageUsersPageProps> = ({
  usersObj,
  deleteFilter,
  dispatch,
  history,
  pageData,
  handleRefresh,
}) => {
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  // start of index
  const columns = ["first_name", "last_name", "email", "status"];
  const handleModalClose = () => setShowModal(!showModal);
  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const url = `${DELETE_USER}/${deleteID}`;
      const response = await ApiCall.getService(url, "GET");
      if (response?.status === 200) {
        // const url = `archiveUser/${deleteID}`; //archive user in candidate-creation
        // await ApiCall.getService(url, "GET", "candidate-creation");
        deleteFilter(deleteID);
        CustomNotify({ type: "success", message: response.msg });
      }
    }
  };

  const handleUnArchiveChanges = async (userId: number) => {
    if (userId) {
      const url = `${UNARCHIVE_USER}/${userId}`;
      const response = await ApiCall.getService(url, "GET");
      if (response?.status === 200) {
        deleteFilter(userId);
        CustomNotify({ type: "success", message: response.msg });
      }
    }
  };


  const handleArchive = (userData: UserData | any) => {
    setShowModal(true);
    setDeleteId(userData.id);
  };

  const handleUnArchive = (userData: UserData | any) => {    
    if (userData.id) {
      handleUnArchiveChanges(userData.id);
    }
  };

  const userData = useSelector(selectAuth);
  const handleScreening = (userData: UserData | any) => {
    navigate(`${PATH_CANDIDATE_SCREENING}/${userData.id}`);
  };

  const handleView = (userData: UserData | any) => {
    if (userData.id) {
      sessionStorage.setItem("originPage", "ManageCandidates");
      navigate(`/applicant/view/${userData.id}`);
    }
  };

  function handleClick(userData: UserData | any): void {
    navigate(`${PATH_CANDIDATE_CORNER}/${userData.id}`, { state: { element: 'candidate' } });
  }
  const filters = [
    {name: 'firstName', fieldType: "text", placeholder: "First name", filterType: 'search' },
    {name: 'lastName', fieldType: "text", placeholder: "Last name", filterType: 'search' },
    {name: 'email', fieldType: "text", placeholder: "Email", filterType: 'search' },
    {name: 'status', fieldType: "singleSelect", options: convertArrayToOptions(UserStatus), placeholder: "Status", filterType: 'search' },
  ];
  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Candidates",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div>
          <div className="form-height-dashboard">
            <div className="row header-sticky position-sticky">
              <div className="col-md-12">
                <h1 className="py-4 page-title mb-0">
                  {t("Manage applicants")}
                </h1>
              </div>
            </div>
            <div className="p-5 border rounded-4 table-custom">
              <FilterOrganism
                dispatch={dispatch}
                history={history}
                updatePageFilters={updatePageFilters}
                filters={filters}
                handleRefresh={handleRefresh}
              />
              <div className="table-top-buttons-block">
                <AccessControl
                  requiredPermissions={[
                    {
                      permission: "Candidates",
                      create: true,
                    },
                  ]}
                  override={userData.isSuperAdmin}
                >
                  <Link
                    to="/applicant/create"
                    className="table-button"
                  >
                    + {t("Add applicant")}
                  </Link>
                </AccessControl>
              </div>
              <div className="table-responsive manage-users">
                <TableStructure
                  isAction
                  headers={ApplicantTableHeader}
                  data={usersObj}
                  values={columns}
                  handleView={handleView}
                  handleScreening={handleScreening}
                  handleArchive={handleArchive}
                  handleUnArchive={handleUnArchive}
                  permissionType={"Candidates"}
                  handleClick={handleClick}
                  clickStatus={true}
                  handleRefresh={handleRefresh}
                  history={history}
                />
              </div>
              <div className="">
                <PaginationWithPerPage
                  handleRefresh={handleRefresh}
                  dispatch={dispatch}
                  history={history}
                  pageData={pageData}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 align-self-center my-4">
            <Link
              to="/"
              className="text-uppercase back-btn text-decoration-underline"
            >
              Back
            </Link>
          </div>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle={t("No")}
          confirmTitle={t("Yes")}
        />
      </>
    </AccessControl>
  );
};

export default ManageApplicantsPage;
