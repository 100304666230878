import { error } from "console";
import { M_MASTER_DATA } from "constants/Constants";
import { FLEET_AND_MATERIAL_FILTER_OPTIONS, MATERIAL_TYPES, USERS } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";

export const fetchOptions = async () => {
    const response = await ApiCall.getService(
        FLEET_AND_MATERIAL_FILTER_OPTIONS,
        "get",
        M_MASTER_DATA
    );
    return response;
};





export const getOptions = (fieldValue: any, dataObj: any, entityType: any) => {
    return ApiCall.service(
        USERS,
        'POST',
        { type: fieldValue, entityType, isEdit: dataObj },
        false,
        M_MASTER_DATA,
    )
        .then((res: any) => {
            return res;
        })
        .catch((error: any) => {
            console.error(error);
            throw error;  // Re-throw the error after logging it
        });
};

export const getCompanyOptions = async (selectedOption: any) => {
    try {
        const response = await ApiCall.service(
            MATERIAL_TYPES,
            'POST',
            { type: (selectedOption?.value !== null || selectedOption?.value !== undefined) ? selectedOption?.value : selectedOption },
            false,
            M_MASTER_DATA
        )
        if (response?.status == 200) {
            console.log(response?.data)
            return response?.data;
        }
    }
    catch (error) {
        console.log(error);
    }


}



