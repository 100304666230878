import { useState } from "react";
import Navigation from "../form-navigation/Navigation";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { t } from "pages/microservices/masterData/translation/Translation";
import PhoneInput from "react-phone-number-input";
import Calender from "components/molecules/Calender";
import LabelField from "components/atoms/LabelField";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import CheckBoxField from "components/atoms/CheckBoxField";
import { useFormContext } from "../../context/Context";
import { ADD_EMERGENCY_CONTACT_FIELDS, EMERGENCY_CONTACT_DETAILS, GENERAL, OTHERS, REMOVE_EMERGENCY_CONTACT_FIELDS, UPDATE_DOMICILE_ADDRESS_FIELDS, UPDATE_EMERGENCY_CONTACT_DETAILS, UPDATE_EMERGENCY_CONTACT_FIELD_ERROR, UPDATE_ERROR_FIELD, UPDATE_FIELD } from "../../annotations/CandidateConstants";
import { validateEmail, validateEmailCanBeNull, validateForm, validateNumber, validatePhoneNumber, validatePhoneNumberCanBeNull, validateRequired, validateSSN, validateSelectField, validateTextFieldAlphaCanBeNull, validateTextFiled } from "services/validation/ValidationService";
import { Option, ValidationRules } from "utils/TypeAnnotations";
import { YesOrNoOptions, genderOptions, resetSSNRelatedFields } from "../../helpers/CandidateHelperFunctions";
import Button from "components/atoms/Button";
import { TextAreaMolecule } from "components/molecules/TextAreaMolecule";
import Close from "static/images/Close";
const GeneralTab: React.FC = () => {
    const { state, dispatch } = useFormContext();
    const [validationStatus, setValidationStatus] = useState({
        isValid: false,
        type: "",
    });

    const validStatus = (validation: any) => {
        setValidationStatus({
            isValid: validation.isValid,
            type: validation.type,
        });
    };

    const validation = (
        name: string,
        value: any,
        isSingleFieldValidation: boolean = false
    ) => {
        const validationRules: ValidationRules = {
            ssn: [validateSSN],
            firstName: [validateRequired, validateTextFiled],
            lastName: [validateRequired, validateTextFiled],
            email: [validateEmail],
            phoneNumber: [validatePhoneNumber],
            businessUnit: [validateSelectField],
            countryOfBirth: [validateSelectField],
            street: [validateRequired, validateTextFiled],
            number: [validateRequired, validateNumber],
            zipCode: [validateRequired, validateNumber],
            city: [validateRequired, validateTextFiled],
            country: [validateSelectField],
        };
        const validationErrors = validateForm(
            { ...state.general, [name]: value },
            validationRules,
            isSingleFieldValidation ? name : undefined
        );

        dispatch({
            type: UPDATE_ERROR_FIELD,
            field: name,
            error: validationErrors[name]
        });
        const isFieldValid = Object.keys(validationErrors).length === 0;
        if (isFieldValid) {
            dispatch({
                type: UPDATE_ERROR_FIELD,
                field: name,
                error: ''
            });
        }
        if (Object.keys(validationErrors).length > 0) {
            return false;
        }
        return true;
    };
    const getAge = (birthDate: any) => {
        if (!birthDate) {
            dispatch({
                type: UPDATE_FIELD,
                tab: GENERAL,
                field: 'age',
                value: '',
            });
            return null;
        }

        const birthYear = birthDate.getFullYear(); // Get birth year

        const currentDate = new Date(); // Get current date
        const currentYear = currentDate.getFullYear(); // Get current year

        const age = currentYear - birthYear; // Calculate and return the age
        dispatch({
            type: UPDATE_FIELD,
            tab: GENERAL,
            field: 'age',
            value: age,
        });
    };

    const getGenderById = (genderId: number) => {
        return genderOptions.find(option => option.value === genderId);
    }
    const getFormattedSSN = (value: string, tab: string) => {
        const numericValue = value.replace(/[^0-9]/g, "");
        let formattedSSN = "";
        let dob = "";

        if (numericValue.length <= 11) {
            for (let i = 0; i < numericValue.length; i++) {
                if (i === 2 || i === 4 || i === 9) {
                    formattedSSN += ".";
                } else if (i === 6) {
                    formattedSSN += "-";
                }
                formattedSSN += numericValue[i];
            }
            const validatioResult = validation("ssn", formattedSSN, true);
            if (
                validatioResult &&
                formattedSSN !== "" &&
                formattedSSN.length === 15
            ) {
                const birthdateString = formattedSSN
                    .substring(0, 8)
                    .replace(/\./g, ""); // Extract YYMMDD part and remove dots
                const year = birthdateString.substring(0, 2);
                let month = parseInt(birthdateString.substring(2, 4), 10);
                const day = parseInt(birthdateString.substring(4, 6));
                // Pad month and day with leading zeros if necessary
                const paddedMonth = month.toString().padStart(2, '0');
                const paddedDay = day.toString().padStart(2, '0');
                const century = parseInt(year) < 50 ? "20" : "19"; // Determine the century based on the year
                const fullYear = parseInt(century + year);
                if (month >= 1 && month <= 12 && day >= 1 && day <= 31) {
                    // Create a Date object with the extracted birthdate
                    dob = fullYear + "-" + paddedMonth + "-" + paddedDay;
                    dispatch({
                        type: UPDATE_FIELD,
                        tab: tab,
                        field: "dob",
                        value: dob
                    });
                    getAge(new Date(dob));
                    const genderId =
                        parseInt(formattedSSN.substring(9, 12)) % 2 === 0 ? 2 : 1;

                    const gender: Option | undefined = getGenderById(genderId);
                    if (gender) {
                        dispatch({
                            type: UPDATE_FIELD,
                            tab: tab,
                            field: 'gender',
                            value: gender // Assuming you want to use the value property of Gender
                        });
                    } else {
                        dispatch({
                            type: UPDATE_FIELD,
                            tab: tab,
                            field: 'gender',
                            value: null // Assuming you want to use the value property of Gender
                        });
                    }
                }
            } else {
                resetSSNRelatedFields(dispatch);

            }
        }
        return formattedSSN;
    }

    async function getBICForIBAN(iban: string, tab: string) {
        const url = `https://openiban.com/validate/${iban}?getBIC=true&validateBankCode=true`;
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const ibanData = await response.json();
            if (ibanData.valid) {
                dispatch({
                    type: UPDATE_FIELD,
                    tab: tab,
                    field: 'bic',
                    value: ibanData.bankData.bic
                });
            }
        } catch (error) {
            console.error("Error:", error);
            return null;
        }
    }

    const emergencyContactValidation = (
        name: string,
        value: string | boolean | Date | object[] | undefined,
        index: number,
        isSingleFieldValidation: boolean = false
    ) => {
        const validationRules: ValidationRules = {
            emergencyContactName: [validateTextFieldAlphaCanBeNull],
            emergencyContactEmail: [validateEmailCanBeNull],
            emergencyContactPhoneNumber: [validatePhoneNumberCanBeNull],
        };
        const validationErrors = validateForm(
            { ...state.emergencyContactDetails[index], [name]: value },
            validationRules,
            isSingleFieldValidation ? name : undefined
        );
        dispatch({
            type: UPDATE_EMERGENCY_CONTACT_FIELD_ERROR,
            field: name,
            error: validationErrors[name],
            index,
        });
        const isFieldValid = Object.keys(validationErrors).length === 0;

        if (isFieldValid) {
            dispatch({
                type: UPDATE_EMERGENCY_CONTACT_FIELD_ERROR,
                field: name,
                error: "",
                index,
            });
        }

        if (Object.keys(validationErrors).length > 0) {
            return false;
        }

        return true;
    };

    const handleEmergencyDataChange = (e: any, index: number) => {
        let { name, value } = e.target;
        dispatch({
            type: UPDATE_EMERGENCY_CONTACT_DETAILS,
            field: name,
            value: value,
            index: index
        });
        emergencyContactValidation(name, value, index, true);
    }

    const handleEmergencyPhoneNumberChange = (number: any, fieldName: string, index: number) => {
        dispatch({
            type: UPDATE_EMERGENCY_CONTACT_DETAILS,
            field: fieldName,
            value: number,
            index: index
        });
        emergencyContactValidation(fieldName, number, index, true);
    }
    const handleFieldChange = (e: any, tab = GENERAL) => {
        let { name, value, type, checked } = e.target;

        let updatedValue = value;

        if (type === 'checkbox') {
            updatedValue = checked ? 1 : 0;
            dispatch({
                type: UPDATE_DOMICILE_ADDRESS_FIELDS,
                checked: updatedValue
            });
        } else if (name === "ssn") {
            updatedValue = getFormattedSSN(value, tab);
        } else if (name == "iban") {
            getBICForIBAN(value, tab);
        }


        // Define the fields to synchronize with dom* versions
        const fieldsToSync = ['street', 'number', 'box', 'zipCode', 'city'];

        // Check if the current name is in the fields to sync and sameAsAddress is 1
        if (fieldsToSync.includes(name)) {
            if (state.general.sameAsResidence === 1) {
                dispatch({
                    type: UPDATE_FIELD,
                    tab: tab,
                    field: `dom${name.charAt(0).toUpperCase() + name.slice(1)}`, // Convert to domFieldName
                    value: updatedValue
                });
            }
        }
        // Dispatch the regular field update
        dispatch({
            type: UPDATE_FIELD,
            tab: tab,
            field: name,
            value: updatedValue
        });
        validation(name, value, true);
    }


    const handlePhoneNumberChange = (number: any, fieldName: string, tab = GENERAL) => {
        dispatch({
            type: UPDATE_FIELD,
            tab: tab,
            field: fieldName,
            value: number,
        });
        validation(fieldName, number, false)

    }

    const handleDateChange = (date: Date | null, fieldName: string, tab = "general") => {
        dispatch({
            type: UPDATE_FIELD,
            tab: tab,
            field: fieldName,
            value: date,
        });
        getAge(date);
        validation(fieldName, date, false)

    }

    const handleSelectChange = (selectedOption: any, fieldName: string, tab = "general") => {
        dispatch({
            type: UPDATE_FIELD,
            tab: tab,
            field: fieldName,
            value: selectedOption,
        });
        if (fieldName === "country" && state.general.sameAsResidence === 1) {
            dispatch({
                type: UPDATE_FIELD,
                tab: tab,
                field: 'domCountry',
                value: selectedOption,
            });
        }
        validation(fieldName, selectedOption, false)
    }

    const handleAdd = () => {
        dispatch({ type: ADD_EMERGENCY_CONTACT_FIELDS });
    }

    const handleRemove = (index: number) => {
        dispatch({
            type: REMOVE_EMERGENCY_CONTACT_FIELDS,
            indexToRemove: index
        });
    }

    return <>
        <div className="form-border" style={{ paddingTop: "3vw" }}>
            <div className="row">
                <div className="col">
                    <LabelWithInputField
                        isMandatory={false}
                        name="ssn"
                        handleChange={(e) => handleFieldChange(e)}
                        value={state.general.ssn}
                        id="ssn"
                        label={t("SSN number")}
                        type="text"
                        error={state.errors.ssn}
                        placeholder="XX.XX.XX-XXX.XX"
                    />
                </div>
                <div className="col">
                    <LabelWithInputField
                        isMandatory={true}
                        name="firstName"
                        handleChange={(e) => handleFieldChange(e)}
                        id="firstName"
                        label="First name"
                        type="text"
                        value={state.general.firstName}
                        error={state.errors.firstName}
                    />
                </div>
                <div className="col">
                    <LabelWithInputField
                        isMandatory={true}
                        name="lastName"
                        handleChange={(e) => handleFieldChange(e)}
                        id="lastName"
                        label="Last name"
                        type="text"
                        value={state.general.lastName}
                        error={state.errors.lastName}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <LabelWithInputField
                        isMandatory={true}
                        name="email"
                        handleChange={(e) => handleFieldChange(e)}
                        id="email"
                        label="Email address"
                        type="email"
                        value={state.general.email}
                        error={state.errors.email}
                    />
                </div>
                <div className="col">
                    <LabelField
                        title={t("Phone number")}
                        isMandatory={true}
                        key="PhoneInput"
                    />
                    <div className="form-control field-shadow">
                        <PhoneInput
                            name={'phoneNumber'}
                            label={'phonenumber'}
                            required={true}
                            defaultCountry="BE"
                            initialValueFormat="national"
                            placeholder={t("Enter phone number")}
                            onChange={(mobileNumber) =>
                                handlePhoneNumberChange(mobileNumber, "phoneNumber")}
                            value={state.general.phoneNumber}
                        />
                    </div>
                    <div>
                        {state.errors.phoneNumber && <span className="text-danger">{state.errors.phoneNumber}</span>}
                    </div>
                </div>
                <div className="col">
                    <div className="row">
                        <div className="col">
                            <Calender
                                onChange={(date) => handleDateChange(date, "dob")}
                                label={t("Date of birth")}
                                isMandatory={false}
                                name={'dob'}
                                error={''}
                                selectedDate={state.general.dob}
                                maxDate={state.general.dob ? new Date(Date.now() - 86400000) : null}
                            ></Calender>
                        </div>
                        <div className="col">
                            <LabelWithInputField
                                isMandatory={false}
                                name="age"
                                handleChange={(e) => handleFieldChange(e)}
                                id="age"
                                label="Age"
                                type="text"
                                value={state.general.age}
                                error={''}
                            />
                        </div>
                    </div>

                </div>
                <div className="col">
                    <SelectWithSearch
                        title={t("Gender")}
                        isMandatory={false}
                        search={true}
                        options={genderOptions}
                        onChange={(e) => handleSelectChange(e, "gender")}
                        isMulti={false}
                        name="gender"
                        value={state.general.gender}
                        error={""}
                        placeHolder="Select"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <LabelWithInputField
                        isMandatory={false}
                        name="shortCode"
                        handleChange={(e) => handleFieldChange(e)}
                        id="shortCode"
                        label="Short code"
                        type="text"
                        value={state.general.shortCode}
                        error={state.errors.shortCode}
                    />
                </div>
                <div className="col">
                    <SelectWithSearch
                        title={t("Business unit")}
                        isMandatory={true}
                        search={true}
                        options={state.dropDownData.businessUnitList}
                        onChange={(e) => handleSelectChange(e, "businessUnit")}
                        isMulti={false}
                        name=" businessUnit"
                        value={state.general.businessUnit}
                        error={state.errors.businessUnit}
                        placeHolder="Select"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <LabelWithInputField
                        isMandatory={false}
                        name="placeOfBirth"
                        handleChange={(e) => handleFieldChange(e)}
                        id="placeOfBirth"
                        label="Place of birth"
                        type="text"
                        value={state.general.placeOfBirth}
                        error={''}
                    />
                </div>
                <div className="col">
                    <SelectWithSearch
                        title={t("Country of birth")}
                        isMandatory={true}
                        search={true}
                        options={state.dropDownData.countriesList}
                        onChange={(e) => handleSelectChange(e, "countryOfBirth")}
                        isMulti={false}
                        name="countryOfBirth"
                        value={state.general.countryOfBirth}
                        error={state.errors.countryOfBirth}
                        placeHolder="Select"
                    />
                </div>
                <div className="col">
                    <SelectWithSearch
                        title={t("Nationality")}
                        isMandatory={false}
                        search={true}
                        options={state.dropDownData.countriesList}
                        onChange={(e) => handleSelectChange(e, "nationality")}
                        isMulti={false}
                        name="nationality"
                        value={state.general.nationality}
                        error={""}
                        placeHolder="Select"
                    />
                </div>
            </div>
            <LabelField title={t("Payment info")} className="tab-subtitle" />
            <div className="row">
                <div className="col">
                    <SelectWithSearch
                        title={t("IBAN-country")}
                        isMandatory={false}
                        search={true}
                        options={state.dropDownData.countriesList}
                        onChange={(e) => handleSelectChange(e, "ibanCountry")}
                        isMulti={false}
                        name="ibanCountry"
                        value={state.general.ibanCountry}
                        error={""}
                        placeHolder="Select"
                    />
                </div>
                <div className="col">
                    <LabelWithInputField
                        isMandatory={false}
                        name="iban"
                        handleChange={(e) => handleFieldChange(e)}
                        id="iban"
                        label="IBAN"
                        type="text"
                        value={state.general.iban}
                        error={state.errors.iban}
                    />
                </div>
                <div className="col">
                    <LabelWithInputField
                        isMandatory={false}
                        name="bic"
                        handleChange={(e) => handleFieldChange(e)}
                        id="bic"
                        label="BIC code"
                        type="text"
                        value={state.general.bic}
                        error=""
                    />
                </div>
            </div>
            <LabelField title={t("Residence address")} className="tab-subtitle" />

            <div className="row">
                <div className="col">
                    <LabelWithInputField
                        isMandatory={true}
                        name="street"
                        handleChange={(e) => handleFieldChange(e)}
                        id="street"
                        label="Street"
                        type="text"
                        value={state.general.street}
                        error={state.errors.street}
                    />
                </div>
                <div className="col">
                    <LabelWithInputField
                        isMandatory={true}
                        name="number"
                        handleChange={(e) => handleFieldChange(e)}
                        id="number"
                        label="Number"
                        type="text"
                        value={state.general.number}
                        error={state.errors.number}
                    />
                </div>
                <div className="col">
                    <LabelWithInputField
                        isMandatory={false}
                        name="box"
                        handleChange={(e) => handleFieldChange(e)}
                        id="box"
                        label="Box"
                        type="text"
                        value={state.general.box}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <LabelWithInputField
                        isMandatory={true}
                        name="zipCode"
                        handleChange={(e) => handleFieldChange(e)}
                        id="zipCode"
                        label="Zip code"
                        type="text"
                        value={state.general.zipCode}
                        error={state.errors.zipCode}
                    />
                </div>
                <div className="col">
                    <SelectWithSearch
                        title={t("Country")}
                        isMandatory={true}
                        search={true}
                        options={state.dropDownData.countriesList}
                        onChange={(e) => handleSelectChange(e, "country")}
                        isMulti={false}
                        name="country"
                        value={state.general.country}
                        error={state.errors.country}
                        placeHolder="Select"
                    />
                </div>
                <div className="col">
                    <LabelWithInputField
                        isMandatory={true}
                        name="city"
                        handleChange={(e) => handleFieldChange(e)}
                        id="city"
                        label="City"
                        type="text"
                        value={state.general.city}
                        error={state.errors.city}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <CheckBoxField
                        label={t("Same as residence address")}
                        name="sameAsResidence"
                        onChangeHandler={(e) => handleFieldChange(e)}
                        isChecked={state.general.sameAsResidence === 1}
                        className="document-checkbox"
                        id="address"
                        lineHeight="1.7vw"
                    />
                </div>
            </div>
            <LabelField title={t("Domicile")} className="tab-subtitle" />
            <div className="row">
                <div className="col">
                    <LabelWithInputField
                        isMandatory={false}
                        name="domStreet"
                        handleChange={(e) => handleFieldChange(e)}
                        id="domStreet"
                        label="Street"
                        type="text"
                        value={state.general.domStreet}
                        error=""
                    />
                </div>
                <div className="col">
                    <LabelWithInputField
                        isMandatory={false}
                        name="domNumber"
                        handleChange={(e) => handleFieldChange(e)}
                        id="domNumber"
                        label="Number"
                        type="text"
                        value={state.general.domNumber}
                        error=""
                    />
                </div>
                <div className="col">
                    <LabelWithInputField
                        isMandatory={false}
                        name="domBox"
                        handleChange={(e) => handleFieldChange(e)}
                        id="domBox"
                        label="Box"
                        type="text"
                        value={state.general.domBox}
                        error=""
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <LabelWithInputField
                        isMandatory={false}
                        name="domZipCode"
                        handleChange={(e) => handleFieldChange(e)}
                        id="domZipCode"
                        label="Zip code"
                        type="text"
                        value={state.general.domZipCode}
                        error=""
                    />
                </div>
                <div className="col">
                    <SelectWithSearch
                        title={t("Country")}
                        isMandatory={false}
                        search={true}
                        options={state.dropDownData.countriesList}
                        onChange={(e) => handleSelectChange(e, "domCountry")}
                        isMulti={false}
                        name="domCountry"
                        value={state.general.domCountry}
                        error={""}
                        placeHolder="Select"
                    />
                </div>
                <div className="col">
                    <LabelWithInputField
                        isMandatory={false}
                        name="domCity"
                        handleChange={(e) => handleFieldChange(e)}
                        id="city"
                        label="City"
                        type="text"
                        value={state.general.domCity}
                        error=""
                    />
                </div>
            </div>
            <LabelField title={t("Personal details")} className="tab-subtitle" />

            <div id="others">
                <div className="row">
                    <div className="col">
                        <SelectWithSearch
                            title={t("Civil status")}
                            isMandatory={false}
                            search={true}
                            options={state.dropDownData.civilStatusList}
                            onChange={(e) => handleSelectChange(e, "civilStatus", OTHERS)}
                            isMulti={false}
                            name="civilStatus"
                            value={state.others.civilStatus}
                            error={""}
                            placeHolder="Select"
                        />
                    </div>
                    <div className="col">
                        <SelectWithSearch
                            title={t("Dependant spouse")}
                            isMandatory={false}
                            search={true}
                            options={YesOrNoOptions}
                            onChange={(e) => handleSelectChange(e, "dependantSpouse", OTHERS)}
                            isMulti={false}
                            name="dependantSpouse"
                            value={state.others.dependantSpouse}
                            error={""}
                            placeHolder="Select"
                        />
                    </div>
                    <div className="col">
                        <LabelWithInputField
                            isMandatory={false}
                            name="dependantChildren"
                            handleChange={(e) => handleFieldChange(e, OTHERS)}
                            id="dependantChildren"
                            label="Dependant children"
                            type="number"
                            value={state.others.dependantChildren}
                            min={0}
                            step={1}
                            error=""
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <SelectWithSearch
                            title={t("Language of documents")}
                            isMandatory={false}
                            search={true}
                            options={state.dropDownData.languagesList}
                            onChange={(e) => handleSelectChange(e, "languageOfDocuments", OTHERS)}
                            isMulti={false}
                            name="languageOfDocuments"
                            value={state.others.languageOfDocuments}
                            placeHolder="Select"
                        />
                    </div>
                    <div className="col">
                        <LabelWithInputField
                            isMandatory={false}
                            name="drivingLicense"
                            handleChange={(e) => handleFieldChange(e, OTHERS)}
                            id="drivingLicense"
                            label="Driving license"
                            type="text"
                            value={state.others.drivingLicense}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <SelectWithSearch
                            title={t("Transport")}
                            isMandatory={false}
                            search={true}
                            options={state.dropDownData.transportList}
                            onChange={(e) => handleSelectChange(e, "transport", OTHERS)}
                            isMulti={false}
                            name="transport"
                            value={state.others.transport}
                            error={""}
                            placeHolder="Select"
                        />
                    </div>
                    <div className="col-6">
                        <TextAreaMolecule
                            label={t('Additional')}
                            name={t('additional')}
                            id={t('additional')}
                            placeholder={"Additional info"}
                            handleChange={(e) => handleFieldChange(e, OTHERS)}
                            isMandatory={false}
                            value={state.others.additional}
                        />
                    </div>
                </div>
            </div>
            <LabelField title={t("Emergency contact details")} className="tab-subtitle" />
            {state.emergencyContactDetails && state.emergencyContactDetails.length > 0 && state.emergencyContactDetails.map((contactDetails: any, index) => (
                <div className="row border rounded-3 p-3" key={index}>
                    {index != 0 && <div className="float-end" onClick={() => handleRemove(index)}><Close /></div>}
                    <div className="col">
                        <LabelWithInputField
                            isMandatory={false}
                            name="emergencyContactName"
                            handleChange={(e) => handleEmergencyDataChange(e, index)}
                            id="emergencyContactName"
                            label="Name"
                            type="text"
                            value={contactDetails.emergencyContactName}
                            error={state.emergencyContactErrors[index]?.emergencyContactName}
                        />
                    </div>
                    <div className="col">
                        <LabelWithInputField
                            isMandatory={false}
                            name="emergencyContactEmail"
                            handleChange={(e) => handleEmergencyDataChange(e, index)}
                            id="emergencyContactEmail"
                            label="Email"
                            type="email"
                            value={contactDetails.emergencyContactEmail}
                            error={state.emergencyContactErrors[index]?.emergencyContactEmail}
                        />
                    </div>
                    <div className="col">
                        <LabelField
                            title={t("Phone number")}
                            isMandatory={false}
                            key="PhoneInput"
                        />
                        <div className="form-control field-shadow">
                            <PhoneInput
                                defaultCountry="BE"
                                international
                                placeholder="Enter phone number"
                                onChange={(mobileNumber) =>
                                    handleEmergencyPhoneNumberChange(mobileNumber, "emergencyContactPhoneNumber", index)
                                }
                                value={contactDetails.emergencyContactPhoneNumber}
                            // error={state.emergencyContactErrors[index].emergencyContactPhoneNumber}
                            />
                        </div>
                        <div>
                            {state.emergencyContactErrors[index]?.emergencyContactPhoneNumber &&
                                <span className="text-danger">{state.emergencyContactErrors[index]?.emergencyContactPhoneNumber}</span>}
                        </div>
                    </div>
                </div>
            ))
            }
            <div className="row">
                <div className="col-12">
                    <Button handleClick={() => { handleAdd() }} className="form-button float-end" title={t("+ Add another")} />
                </div>
            </div>
            <div style={{ padding: "1vw 0" }}>
                <Navigation validStatus={validStatus} />
            </div>
        </div>
    </>
}
export default GeneralTab;

