import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { t } from "pages/microservices/masterData/translation/Translation";
import Button from "components/atoms/Button";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { M_MASTER_DATA } from "constants/Constants";
import { ApiCall } from "services/ApiServices";

import {
  ADD_TO_PROJECT,
  GET_COMPANY_PROJECT_OPTION,
  GET_EMPLOYEE_PROJECT_OPTION,
  REMOVE_FROM_PROJECT,
} from "routes/ApiEndpoints";
import {
  validateForm,
  validateMultiSelectField,
  validateRequired,
} from "services/validation/ValidationService";
import { useParams } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";

interface Option {
  label: string;
  value: number;
}

interface AddProjectModalProps {
  handleClose: () => void;
  url?: any;
  removeStatus?: any;
}
interface ValidationRules {
  [key: string]: Function[];
}
const AddProjectModal: React.FC<AddProjectModalProps> = ({
  handleClose,
  url,
  removeStatus,
}) => {
  const urlParts = url.split("/");
  let employeeId = 0;
  let projectId = 0;
  let companyID = 0;
  employeeId = parseInt(urlParts[urlParts.length - 1]);
  if (url.includes("matching")) {
    projectId = parseInt(urlParts[urlParts.length - 3]);
    companyID = parseInt(urlParts[urlParts.length - 2]);
  }


  const [selectedOptions, setSelectedOptions] = useState({
    company: [],
    project: [],
  });

  const [options, setOptions] = useState({
    company: [],
    project: [],
  });
  const [company, setCompany] = useState([]);
  const [project, setProject] = useState([]);
  const [projectByCompany, setProjectByCompany] = useState([]);
  const [projectDropdown, setProjectDropdown] = useState(false);
  const [error, setError] = useState({
    company: null,
    project: null,
  });


  const validateInput = (selectedOption: any, fieldName: string): boolean => {
    const validationRules: ValidationRules = {}; // Initialize an empty object

    if (url.includes('matching')) {
      validationRules.company = [validateRequired];
      validationRules.project = [validateRequired];
    } else {
      validationRules.company = [validateMultiSelectField];
      validationRules.project = [validateMultiSelectField];
    }
    const formData = { ...selectedOptions, [fieldName]: selectedOption };
    const validationErrors = validateForm(formData, validationRules);
    setError((prevErrors) => ({
      ...prevErrors,
      [fieldName]: validationErrors[fieldName],
    }));
    return !validationErrors[fieldName];
  };
  const handleAddClick = () => {
    const isCompanySelected = validateInput(selectedOptions.company, "company");
    const isProjectSelected = validateInput(selectedOptions.project, "project");

    if (isCompanySelected && isProjectSelected) {
      const postData: any = {
        employeeId: employeeId,
        companyId: selectedOptions.company,
        projectId: selectedOptions.project,
        category: "Project",
      };
      if (removeStatus) {
        ApiCall.service(
          REMOVE_FROM_PROJECT,
          "POST",
          postData,
          true,
          M_MASTER_DATA
        ).then((response) => {
          if (response?.status === 200) {
            CustomNotify({
              type: "success",
              message: response.message,
            });
          }
        });
      } else {
        ApiCall.service(
          ADD_TO_PROJECT,
          "POST",
          postData,
          true,
          M_MASTER_DATA
        ).then((response) => {
          if (response?.status === 200) {
            CustomNotify({
              type: "success",
              message: response.message,
            });
          }
        });
      }
      handleClose(); // Close the modal
    } else {
    }
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    if (fieldName === "company") {
      selectedOption.flatMap((company: any) => {
        setProjectByCompany(project[company.value]);
      });
    }

    setProjectDropdown(true);
    const handleCommonLogic = (value: any) => {
      setSelectedOptions((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
      validateInput(value, fieldName);
    };

    switch (fieldName) {
      case "company":
        const currentSelectedCompanies = selectedOption.map((company: any) => ({
          value: company.value,
          label: company.label,
        }));
        handleCommonLogic(currentSelectedCompanies);
        break;
      case "project":
        const currentSelectedProjects = selectedOption.map((project: any) => ({
          value: project.value,
          label: project.label,
        }));
        handleCommonLogic(currentSelectedProjects);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    fetchCompanyAndProjectOption();
  }, [companyID, employeeId]);

  const fetchCompanyAndProjectOption = async () => {
    try {
      const postData = {
        empId: employeeId,
      };
      const response = await ApiCall.service(
        GET_EMPLOYEE_PROJECT_OPTION,
        "POST",
        postData,
        false,
        M_MASTER_DATA
      );
      // const response = await ApiCall.getService(
      //   GET_COMPANY_PROJECT_OPTION,
      //   "GET",
      //   M_MASTER_DATA,
      //   false
      // );
      if (response?.status === 200) {
        setCompany(response?.data?.company);
        if (url.includes("hotlist")) {
          setProject(response?.data?.projects);
          const initialSelectedCompanies = response.data.existingCompanies.map(
            (company: any) => ({
              value: company.value,
              label: company.label,
            })
          );

          const initialSelectedProjects: any = Object.keys(
            response.data.existingProjects
          ).flatMap((key) =>
            response.data.existingProjects[key].map((project: any) => ({
              value: project.value,
              label: project.label,
            }))
          );

          setSelectedOptions((prevOptions: any) => ({
            ...prevOptions,
            company: initialSelectedCompanies || [],
            project: initialSelectedProjects || [],
          }));
        }
        if (url.includes("matching") && response?.data?.projects[companyID]) {
          const existingProject = response?.data?.existingProjects ? response?.data?.existingProjects.map((project: any) => (project.filter(
            (project: { value: number }) =>
              project.value === Number(projectId)
          ))) : [];          
          if (removeStatus) {
            setProject(response?.data?.existingProjects);
            setProjectByCompany(existingProject ? existingProject.flat() : []);
          } else {
            setProject(response?.data?.projects);
            setProjectByCompany(response?.data?.projects[companyID]);
          }
          setProjectDropdown(true);

          const filteredCompanies = response?.data?.company?.filter(
            (company: { value: number }) => company.value === companyID
          );          
          if (filteredCompanies && filteredCompanies.length > 0) {
            setSelectedOptions((previousOptions) => ({
              ...previousOptions,
              company: filteredCompanies[0].value && [filteredCompanies[0].value],
            }));
          }

          let filteredProjects: { value: number }[] = [];
          if (removeStatus) {
            filteredProjects = existingProject ? existingProject.flat() : [];
          } else {
            filteredProjects = response?.data?.projects[companyID].filter(
              (project: { value: number }) =>
                project.value === Number(projectId)
            );
          }
          if (filteredProjects && filteredProjects.length > 0) {
            setSelectedOptions((prevOptions: any) => ({
              ...prevOptions,
              project: filteredProjects[0].value && [filteredProjects[0].value] ,
            }));
          }

        }
      }
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };

  return (
    <Modal
      size="lg"
      show={true}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="bitter-italic-normal-medium-24">
          {t("Add Project")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <SelectWithSearch
            title={t("Company")}
            name="company"
            isMandatory={true}
            search={true}
            options={company}
            placeHolder="Select"
            value={selectedOptions.company.length>0 ? selectedOptions.company : []}
            onChange={(e) => handleSelectChange(e, "company")}
            isMulti={true}
            className="select-field"
            error={error.company || ""}
            isDisabled={url.includes("matching") ? true : false}
          />
        </div>
        <div className="">
          <SelectWithSearch
            title={t("Project")}
            name="project"
            isMandatory={true}
            search={true}
            options={
              projectDropdown === true && projectByCompany
              && projectByCompany
            }
            placeHolder="Select"
            value={selectedOptions.project.length>0 ? selectedOptions.project : []}
            onChange={(e) => handleSelectChange(e, "project")}
            isMulti={true}
            className="select-field"
            error={error.project || ""}
            isDisabled={url.includes("matching") ? true : false}
          />
        </div>
        <div className="row  align-items-center">
          <div className="col-6 ">
            <p
              className=" text-uppercase  mb-0 cursor-pointer"
              onClick={handleClose}
            >
              {t("Cancel")}
            </p>
          </div>
          <div className="col-6 ">
            <Button
              title={removeStatus ? t("Remove") : t("Add")}
              handleClick={handleAddClick}
              className="btn form-button float-end text-uppercase rounded-0 shadow-none"
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddProjectModal;
