import LabelField from "components/atoms/LabelField";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import PhoneInput from "react-phone-number-input";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import CheckBoxField from "components/atoms/CheckBoxField";
import FormSectionTitle from "components/molecules/FormSectionTitle";
import RadioMolecule from "components/molecules/RadioMolecule";
import { TextAreaMolecule } from "components/molecules/TextAreaMolecule";
import MaxiconFileUpload from "components/molecules/MaxiconFileUpload";
import { LabelWithInputFieldAndUnit } from "pages/microservices/masterData/templates/atoms/LabelWithInputFieldAndUnit";
import Button from "components/atoms/Button";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import InputRangeMolecule from "components/molecules/InputRangeMolecule";
import LabelWithCKEditorField from "components/molecules/LabelWithCKEditorField";
import Competence from "components/molecules/Competence";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import { Accordion } from "react-bootstrap";
import { t } from "pages/microservices/masterData/translation/Translation";
import DatePicker from "react-multi-date-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useParams } from "react-router-dom";
import Calender from "components/molecules/Calender";

export const FormElementRender = (field?: any, handleChange?: any) => {
  switch (field?.type) {
    case "text":
    case "string":
    case "number":
    case "range":
      return (
        <div
          className={`${field?.display === false ? "d-none" : ""} ${field?.class ? field?.class : "col-sm-12 col-md-4"
            }`}
        >
          <LabelWithInputField
            label={field.label}
            name={field.name}
            id={field.name}
            placeholder={field.placeholder}
            handleChange={handleChange}
            isMandatory={field.required}
            value={field?.value}
            min={0}
            max={24}
            step={field.step}
            error={field?.error}
            type={field?.type}
            isDisabled={field?.disabled}
            maxLenght={24}
          />
        </div>
      );
    case "date":
      return (
        <div
          className={`${field?.display === false ? "d-none" : ""}  ${field?.class
            ? field?.class
            : "col-sm-12 col-md-4  position-relative"
            }`}
        >
          <Calender
            onChange={(date) => handleChange(date, field.name)}
            selectedDate={field?.value}
            label={field?.label}
            isMandatory={field?.required}
            name={field?.name}
            error={field?.error}
            isDisabled={false}
            placeHolder={field?.placeholder}
          ></Calender>

          {/* <LabelWithInputField
            label={field.label}
            name={field.name}
            id={field.name}
            placeholder={field.placeholder}
            handleChange={handleChange}
            isMandatory={field.required}
            value={field?.value}
            error={field?.error}
            type={field?.type}
          /> */}
        </div>
      );
    case "text-area":
      return (
        <div
          className={`col-sm-12 col-md-12 ${field?.class} ${field?.display === false ? "d-none" : ""
            }`}
        >
          <TextAreaMolecule
            label={field.label}
            name={field.name}
            id={field.name}
            placeholder={field.placeholder}
            handleChange={handleChange}
            isMandatory={field.required}
            value={field?.value}
            error={field?.error}
          />
        </div>
      );
    case "multi-select":
      return (
        <div
          className={`${field?.display === false ? "d-none" : ""} ${field?.class ? field?.class : "col-sm-12 col-md-4"
            }`}
        >
          <SelectWithSearch
            search={field.search}
            onChange={handleChange}
            title={field.label}
            options={field.options}
            placeHolder={"Select"}
            isMulti={field.isMultiSelect}
            name={field.name}
            isMandatory={field.required}
            value={field?.value}
            error={field?.error}
            id={field.name}
            isDisabled={field?.disabled}
          />
        </div>
      );
    case "recaptcha":
      return (
        <InputRangeMolecule
          inputtype={"range"}
          rangevalue={field.value === "" ? 0 : field?.value}
          rangeclass={field.class}
          name={"recaptcha"}
          min={field.inputmin}
          max={field.inputmax}
          step={field.inputstep}
          title={field.label}
          id={field.name}
          error={field?.error}
          isMandatory={field.required}
          inputnumtype={"number"}
          value={field.value}
          inputname={"label"}
          inputmin={field.inputmin}
          inputmax={field.inputmax}
          inputstep={field.inputstep}
          handleChange={handleChange}
        />
      );
    case "mobile-number":
      return (
        <div
          className={`${field?.display === false ? "d-none" : ""} ${field?.class ? field?.class : "col-sm-12 col-md-4"
            }`}
        >
          <LabelField
            title={field?.label}
            isMandatory={field.required}
            key="PhoneInput"
            htmlfor={field.name}
          />
          <div className="form-control field-shadow">
            <PhoneInput
              name={field.name}
              label={field.label}
              required={field.required}
              defaultCountry="BE"
              initialValueFormat="national"
              placeholder={field.placeholder}
              onChange={handleChange}
              value={field?.value}
              id={field.name}
            />
          </div>
          <div
            className="height-20"
            style={{ marginTop: "0.25vw", marginBottom: "0.25vw" }}
          >
            {field?.error && (
              <span className="text-danger">{field?.error}</span>
            )}
          </div>
        </div>
      );
    case "text-hidden":
      return (
        <div className="col-sm-12 col-md-4 d-none">
          <LabelWithInputField
            label={field.label}
            name={field.name}
            id={field.name}
            placeholder={field.placeholder}
            handleChange={handleChange}
            isMandatory={field.required}
            value={field?.value}
            error={field?.error}
          />
        </div>
      );
    case "tags":
      return (
        <div
          className={`${field?.display === false ? "d-none" : ""
            } "col-sm-12 col-md-12"`}
        >
          <FormSectionTitle title={field?.label} />
        </div>
      );
    case "checkbox":
      return (
        <div
          className={`${field?.display === false ? "d-none" : ""} ${field?.class ? field?.class : "col-sm-12 col-md-4"
            }`}
        >
          <CheckBoxField
            label={field.label}
            name={field.name}
            id={field.name}
            onChangeHandler={handleChange}
            isChecked={field?.value}
            disable={false}
            lineHeight="1.5vw"
          />
        </div>
      );
    case "radio":
      return (
        <div
          className={`${field?.display === false ? "d-none" : ""} ${field?.class ? field?.class : "col-sm-12 col-md-4"
            }`}
        >
          <RadioMolecule
            label={field.label}
            name={field.name}
            handleChange={handleChange}
            fields={field.fields}
            value={
              field?.value === true || field?.value === "yes" ? "yes" : "no"
            } //It is better to change in the database level as yes or no(varchar)
            error={field?.error}
          />
        </div>
      );

    case "file":
      return (
        <div
          className={`${field?.display === false ? "d-none" : ""} ${field?.class ? field?.class : "col-sm-12 col-md-4 col-xl-4"
            }`}
        >
          <MaxiconFileUpload
            label={field.label}
            name={field.name}
            id={field.name}
            isMandatory={field?.required}
            edit={false}
            fileObj={field?.value}
            multiple={false}
            uploadPath="candidate-certificate"
            formats={field.formats}
            handleChange={handleChange}
            maxFileSize={10000}
          />
          <span className="text-danger">
            {/* {state.studiesErrors[index]?.certificateId} */}
          </span>
        </div>
      );
    case "text-with-unit":
      return (
        <div
          className={`${field?.display === false ? "d-none" : ""} ${field?.class ? field?.class : "col-sm-12 col-md-4 col-xl-4"
            }`}
        >
          <LabelWithInputFieldAndUnit
            label={field?.label}
            name={field?.name}
            id={field?.name}
            placeholder={field?.placeholder}
            handleChange={handleChange}
            isMandatory={field?.required}
            value={field?.value}
            unit={field?.unit}
            error={field?.error}
            containerClassName={"col-12"}
          />
        </div>
      );
    // case "table":
    //   return (
    //     <div className="col-sm-12 col-md-12">
    //       <DynamicTableStructure
    //         isAction={false}
    //         headers={field?.header}
    //         data={field?.data}
    //         values={field?.value}
    //       />
    //     </div>
    //   )

    case "button":
      return (
        <div className={field?.containerClassName}>
          <div className="col-md-3">
            <Button title={field?.title1} className={field?.className} />
          </div>
          <div className="col-md-3">
            <Button title={field?.title2} className={field?.className} />
          </div>
        </div>
      );
    case "ck-text-area":
      return (
        <div className={field?.containerClassName}>
          <LabelWithCKEditorField
            label={field?.label}
            name={field?.name}
            placeholder={field?.placeholder}
            handleChange={handleChange}
            isMandatory={field?.required}
            value={field?.value}
            error={field?.error}
          />
        </div>
      );
    case "competence":
      return (
        <div className={field?.containerClassName}>
          <Competence
            competenceOptions={field.options}
          // handleChange={handleChange}
          />
        </div>
      );

    case "location":
      return (
        <div
          className={`${field?.display === false ? "d-none" : ""} ${field?.class ? field?.class : "col-sm-12 col-md-6 col-xl-4"
            }`}
        >
          <label className="fs-5">{field.label}</label>
          <APIProvider apiKey={`${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}>
            <Map
              style={{ width: "30vw", height: "20vh" }}
              defaultCenter={
                field?.value ? field?.value : { lat: 50.5039, lng: 4.4699 }
              }
              center={field?.value}
              defaultZoom={5}
              gestureHandling={"greedy"}
              disableDefaultUI={true}
              onClick={handleChange}
            />
            <Marker position={field?.value} />
          </APIProvider>
        </div>
      );

    case "date-picker":
      return (
        <div
          className={`${field?.display === false ? "d-none" : ""} ${field?.class ? field?.class : "col-sm-12 col-md-6"
            }`}
        >
          <div>
            <LabelField title={field.label} isMandatory={field.required} />
          </div>
          <DatePicker
            value={field?.value ? new Date(field?.value, 0, 1) : null}
            inputClass="form-control field-shadow"
            name="date"
            placeholder={new Date().getFullYear().toString()}
            onChange={(event) => { handleChange(event) }}
            format="YYYY"
            onlyYearPicker={true}
            minDate={new Date(2014, 0, 1)}
            zIndex={1000} />
        </div>
      )
    default:
      return null;
  }
};
