import { useState } from "react";
import Navigation from "../form-navigation/Navigation";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { t } from "pages/microservices/masterData/translation/Translation";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import Button from "components/atoms/Button";
import MaxiconFileUpload from "components/molecules/MaxiconFileUpload";
import { TextAreaMolecule } from "components/molecules/TextAreaMolecule";
import { useFormContext } from "../../context/Context";
import { ADD_STUDY_FIELDS_SET, REMOVE_STUDY_FIELDS_SET, UPDATE_STUDY_FIELD } from "../../annotations/CandidateConstants";
import Close from "static/images/Close";
import { handleSingleFileUpload } from "utils/S3Bucket/Index";
import LabelField from "components/atoms/LabelField";
import RadioField from "components/atoms/RadioField";
import Calender from "../../helpers/Calender";

const StudiesTab: React.FC = () => {
    const { state, dispatch } = useFormContext();
    const [validationStatus, setValidationStatus] = useState({
        isValid: false,
        type: "",
    });

    const validStatus = (validation: any) => {
        setValidationStatus({
            isValid: validation.isValid,
            type: validation.type,
        });
    };
    const handleSelectChange = (selectedOption: any, fieldName: string, index: number) => {
        dispatch({
            type: UPDATE_STUDY_FIELD,
            index: index,
            field: fieldName,
            value: selectedOption
        });
    }
    const handleFieldChange = (e: any, index: number) => {
        const { name, value, type } = e.target;
        let updatedValue: any = value;
        let updatedName: string = name;
        if (type === 'radio') {
            updatedValue = parseInt(value);
            updatedName = "completed";
        }
        dispatch({
            type: UPDATE_STUDY_FIELD,
            index: index,
            field: updatedName,
            value: updatedValue
        });
    }
    const handleDateChange = (date: Date | null, fieldName: string, index: number) => {
        dispatch({
            type: UPDATE_STUDY_FIELD,
            index: index,
            field: fieldName,
            value: date
        });
    }

    const handleAddClick = () => {
        dispatch({
            type: ADD_STUDY_FIELDS_SET
        });

    }
    const handleFileChange = async (event: any, field: any, index: number) => {
        let fileData: any = null;
        if (event !== null) {
            fileData = await handleSingleFileUpload(event, "studies");
        }
        dispatch({
            type: UPDATE_STUDY_FIELD,
            index: index,
            field: field,
            value: fileData
        });
    };

    const handleRemove = (index: number) => {
        dispatch({
            type: REMOVE_STUDY_FIELDS_SET,
            indexToRemove: index
        });
    }

    return <>
        <div className="form-border" style={{ paddingTop: "3vw" }}>
            {state.studies && state.studies.length > 0 && state.studies.map((studyFields: any, index) => (
                <div key={index}>
                    {index != 0 && <div className="float-end" onClick={() => handleRemove(index)}><Close /></div>}
                    <div className="row">
                        <div className="col-4">
                            <SelectWithSearch
                                title={t("Education level")}
                                isMandatory={false}
                                search={true}
                                options={state.dropDownData.educationLevelList}
                                onChange={(e) => handleSelectChange(e, "educationLevel", index)}
                                isMulti={false}
                                name="educationLevel"
                                value={studyFields.educationLevel}
                                error={""}
                                placeHolder="Select"
                            />
                        </div>
                        <div className="col-4">
                            <SelectWithSearch
                                title={t("Diploma")}
                                isMandatory={false}
                                search={true}
                                options={state.dropDownData.diplomaList}
                                onChange={(e) => handleSelectChange(e, "diploma", index)}
                                isMulti={false}
                                name="diploma"
                                value={studyFields.diploma}
                                error={""}
                                placeHolder="Select"
                            />
                        </div>
                        <div className="col-4">
                            <LabelWithInputField
                                isMandatory={false}
                                name="fieldOfStudy"
                                handleChange={(e) => handleFieldChange(e, index)}
                                id="fieldOfStudy"
                                label="Field of study"
                                type="text"
                                value={studyFields.fieldOfStudy}
                                error=""
                            />
                        </div>
                        <div className="col-4">
                            <LabelWithInputField
                                isMandatory={false}
                                name="school"
                                handleChange={(e) => handleFieldChange(e, index)}
                                id="school"
                                label="School"
                                type="text"
                                value={studyFields.school}
                                error=""
                            />
                        </div>
                        <div className="col-2">
                            <Calender
                                onChange={(date) => handleDateChange(date, "from", index)}
                                label={t('From')}
                                isMandatory={false}
                                name={'from'}
                                selectedDate={studyFields.from}
                                maxDate={studyFields.to}
                            ></Calender>
                        </div>
                        <div className="col-2">
                            <Calender
                                onChange={(date) => handleDateChange(date, "to", index)}
                                label={t('To')}
                                isMandatory={false}
                                name={'to'}
                                selectedDate={studyFields.to}
                                minDate={studyFields.from}
                            ></Calender>
                        </div>
                        <div className="col-4">
                            <div>
                                <LabelField
                                    title={t("Completed")}
                                    isMandatory={false}
                                    key="completed"
                                />
                            </div>
                            <div className="mt-2 pt-1">
                                <div className="d-inline-block me-4">
                                    <RadioField
                                        name={`completed-${index}`}
                                        value={1}
                                        ischecked={studyFields.completed == 1}
                                        handleChange={(e) => handleFieldChange(e, index)}
                                        label={t('Yes')}
                                    />
                                </div>
                                <div className="d-inline-block">
                                    <RadioField
                                        name={`completed-${index}`}
                                        value={0}
                                        ischecked={studyFields.completed == 0}
                                        handleChange={(e) => handleFieldChange(e, index)}
                                        label={t('No')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <MaxiconFileUpload
                                label={t('Upload certificate')}
                                name={'uploadCertificate'}
                                id={String(index)}
                                isMandatory={false}
                                edit={false}
                                fileId={null}
                                fileObj={JSON.stringify(studyFields.uploadCertificate)}
                                multiple={false}
                                uploadPath="candidate-certificate"
                                formats={'value.formats'}
                                handleChange={(e: any) => handleFileChange(e, "uploadCertificate", index)}
                                maxFileSize={10000}
                            />
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: "1vw" }}>
                        <div className="col">
                            <TextAreaMolecule
                                label={t('Info')}
                                name={t('info')}
                                id={t('info')}
                                placeholder={"info"}
                                handleChange={(e) => handleFieldChange(e, index)}
                                isMandatory={false}
                                value={studyFields.info}
                                error={''}
                            />
                        </div>
                    </div>
                </div>
            ))}
            <div className="row">
                <div className="col-12">
                    <Button
                        title={"+ " + t("Add another")}
                        handleClick={handleAddClick}
                        className="form-button float-end px-3"
                    />
                </div>
            </div>

        </div>
        <div style={{ padding: "1vw 0" }}>
            <Navigation validStatus={validStatus} />
        </div>
    </>
}
export default StudiesTab;