import MultiSelectAtom from "components/atoms/MultiSelectAtom";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { OptionProps } from "utils/TypeAnnotations";
import { LabelWithInputField } from "./LabelWithInputField";
import { t } from "pages/microservices/masterData/translation/Translation";
import { InputTextFieldWithDebounce } from "pages/microservices/planning/molecules/InputTextFieldWithDebounce";
import { useDispatch } from "react-redux";
import { updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { Option } from "react-multi-select-component";
import CheckBoxField from "components/atoms/CheckBoxField";
import Calender from "./Calender";

interface FilterMoleculeProps {
  searchData: any;
  filters: Array<{
    label?: string;
    name: string;
    options?: OptionProps[];
    fieldType: string;
    placeholder?: string;
    isMulti?: boolean;
    isTranslate?: boolean;
    fieldPrefix?: string;
    type?: string;
    filterType?: string;
    autoComplete?: string;
    end?: any;
    start?: any;
  }>;
  colClass?: string;
  dispatch: any;
  handleRefresh?: () => void;
}

const FilterMolecule: React.FC<FilterMoleculeProps> = ({
  searchData,
  filters,
  handleRefresh,
  dispatch,
  colClass = ""
}) => {
  const handleChange = (value: any, name: string, field: string) => {    
    switch (field) {
      case 'singleSelect':
          value = value?.value ?? "";
          break;
      case 'default':
        value = value;
        break;
    }
    dispatch(updatePageFilters({filters: { [name]: value, currentPage: "1" }}));
    handleRefresh && handleRefresh();
  }

  return (
    <>
      {filters && filters?.filter((filter) => filter.filterType === "search")
        .map((filter) => (
          <th key={filter?.name} className={`FilterMolecule border-0 ${colClass}`}>
            {(() => {
              switch (filter?.fieldType) {
                case "multiSelect":                  
                  return (
                    <MultiSelectAtom
                      onChange={(selectedOption) => handleChange(selectedOption, filter?.name, filter?.fieldType)}
                      name={filter.name}
                      options={filter.options ?? []}
                      value={searchData[filter?.name] ?? []}
                      placeHolder={t(filter.placeholder ?? "")}
                    />
                  );
                case "singleSelect":
                  return (
                    <SelectWithSearch
                      search={true}
                      options={filter.options ?? []}
                      onChange={(selectedOption) => handleChange(selectedOption, filter?.name, filter?.fieldType)}
                      name={filter?.name}
                      value={searchData[filter?.name] ?? null}
                      placeHolder={t(filter.placeholder ?? "")}
                    />
                  );
                case "text":
                  return (
                    <InputTextFieldWithDebounce
                      name={filter.name}
                      handleChange={(e: any) => handleChange(e, filter?.name, filter?.fieldType)}
                      value={searchData[filter.name] ?? ""}
                      id={filter.name}
                      type={filter.type ?? "text"} // Input field type is text
                      placeholder={t(filter.placeholder ?? "")} // Use placeholder if provided
                    />
                  );

                case "textWithPrefix":
                  return (
                    <div className="input-group position-relative prefixWrapper">
                      <div className="position-absolute searchFieldPrefix">
                        {filter.fieldPrefix}
                      </div>
                      <InputTextFieldWithDebounce
                        name={filter.name}
                        handleChange={(e: any) => handleChange(e, filter?.name, filter?.fieldType)}
                        value={searchData[filter.name] ?? ""}
                        id={filter.name}
                        type={filter.type ?? "text"} // Input field type is text
                        placeholder={t(filter.placeholder ?? "")} // Use placeholder if provided
                      />
                    </div>
                  );
                case "checkbox":
                  return (
                    <div className="input-group position-relative prefixWrapper">
                      <div className="">
                        {filter.label}
                      </div>
                      <CheckBoxField
                        name={filter.name}
                        isChecked={searchData[filter.name] ?? false}
                        onChangeHandler={(e) => handleChange(e.target.checked, filter?.name, 'checkbox')}
                      />
                    </div>
                  );
                case "date":
                  return (
                    <Calender
                      onChange={(date) => handleChange(date, filter.name, 'date')}
                      selectedDate={searchData[filter.name] ?? null}
                      name={filter.name}
                      maxDate={filter?.end ? new Date(filter?.end) : null}
                      minDate={filter?.start ? new Date(filter?.start) : null}
                      placeHolder={t(filter.placeholder ?? "")}
                    />
                  );
                // You can add more cases for different input types here if needed
                default:
                  return null; // For unsupported types, return nothing
              }
            })()}
          </th>
        ))}
    </>
  );
};

export default FilterMolecule;