import TitleFieldMolecule from 'components/molecules/TitleField';
import { M_MASTER_DATA } from 'constants/Constants';
import React, { useEffect, useState } from 'react';
import { ARCHIVE_CONTRACT_PROPOSAL, GET_CONTRACT_PROPOSAL_DATA } from 'routes/ApiEndpoints';
import { ApiCall } from 'services/ApiServices';
import { t } from '../translation/Translation';
import EditIcon from 'static/images/EditIcon';
import LinkTo from 'components/atoms/LinkTo';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { CREATE_CONTRACT } from 'constants/Paths';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Button from 'components/atoms/Button';
import CustomNotify from 'components/atoms/CustomNotify';
import ModalPopup from 'components/atoms/ModalPopup';
import ArchieveIcon from 'static/images/ArchiveIcon';

interface ManagecontractProps {
    id: number | null;
    userId: number | null;
    startDate: Date | null;
    endDate: Date | null;
    employeeContrcatType: string;
    company: string;
    function: string;
    salary: string;
}

const ManageContractProposal = () => {
    const queryParam = useParams();
    const candidateId = Number(queryParam.candidateId) ?? undefined;
    const navigate = useNavigate();
    const initialManageContractData = {
        id: null,
        userId: Number(candidateId),
        startDate: null,
        endDate: null,
        employeeContrcatType: '',
        company: '',
        function: '',
        salary: '',
    };
    const [searchData, setSearchData] = useState();
    const [manageData, setManageData] = useState<ManagecontractProps[]>([initialManageContractData]);
    const [archievePopup, setArchievePopup] = useState(false);
    const [userName, setUserName] = useState('');

    const currentPage = 1;
    useEffect(() => {
        const postData = {
            search: searchData,
            page: currentPage,
            userId: candidateId
        }
        ApiCall.service(GET_CONTRACT_PROPOSAL_DATA, "POST", postData, true, M_MASTER_DATA).then((response) => {
            const name = response?.userDetails?.first_name + " " + response?.userDetails?.last_name;
            setUserName(name);
            setManageData(response.data);
        });
    }, []);

    const handleArcheive = async (id: number, apiCall?: string) => {
        setArchievePopup(true);
        if (apiCall) {
            const postData = {
                id: id
            };
            const response = await ApiCall.service(ARCHIVE_CONTRACT_PROPOSAL, "POST", postData, true, M_MASTER_DATA);
            if (response?.status === 200) {
                setArchievePopup(false);
                CustomNotify({ type: 'success', message: response?.message });
            }
        }
    }

    const handleArcheivePopup = () => {
        setArchievePopup(false);
    }

    return (
        <>
            <TitleFieldMolecule title={userName ? (userName + "'s ") + t("contract proposal overview") : t("Contract proposal overview")} />
            <div className="row  d-block m-auto">
                <div className="col">
                    <LinkTo
                        pagelink={`${CREATE_CONTRACT}/${candidateId}`}
                        title={t("Create contract proposal")}
                        icon={faPlus}
                    />
                </div>
            </div>
            <table className='table border'>
                <thead className='tableHeader'>
                    <tr>
                        <th>Start date</th>
                        <th>End date</th>
                        <th>Contract type</th>
                        <th>Company</th>
                        <th>Function</th>
                        <th>Salary</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {manageData && manageData.map((data: any, index: number) => (
                        <tr key={index}>
                            <td>{data.startDate}</td>
                            <td>{data.endDate}</td>
                            <td>{data.employeeContractType}</td>
                            <td>{data.company}</td>
                            <td>{data.function}</td>
                            <td>{data.salary}</td>
                            <td>
                                <Link
                                    to={`${CREATE_CONTRACT}/${data.userId}/${data.id}`}
                                    className="back-btn text-decoration-underline"
                                >
                                    <EditIcon />
                                </Link>
                                <Button
                                    title={<ArchieveIcon />}
                                    className={"table-action-btn border-0  p-0 me-2"}
                                    handleClick={() => handleArcheive(data.id)}
                                    tooltip={t("Archive")}
                                />
                            </td>
                            {archievePopup &&
                                <ModalPopup
                                    show={archievePopup}
                                    onHide={handleArcheivePopup}
                                    title={t("Archive contrcat proposal")}
                                    body={t("Are you sure you want to archive contract proposal")}
                                    confirmTitle={t("Yes")}
                                    closeTitle={t("No")}
                                    onConfirmButtonClick={() => handleArcheive(data.id, 'apiCall')}
                                    onCloseButtonClick={handleArcheivePopup}
                                />}
                        </tr>

                    ))}
                </tbody>
            </table>

            <div className="row">
                <div className="col">
                    <Link
                        to=""
                        className="back-btn text-decoration-underline"
                        onClick={() => navigate(-1)}
                    >
                        {t("Back")}
                    </Link>
                </div>
            </div>
        </>
    );
}

export default ManageContractProposal;