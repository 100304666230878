import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "components/atoms/Button";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import {
  PATH_PROJECTS_CREATE,
  PATH_PROJECTS_EDIT,
  PATH_MATCHING,
  PATH_PROJECTS_MANAGE,
  PATH_EMPLOYEE_MANAGE,
  PATH_PROJECT_PROPOSAL,
} from "constants/Paths";
import SearchWithLabel from "components/molecules/SearchWithLabel";
import TableActions from "components/organism/Actions/TableAction";
import { useNavigate } from "react-router-dom";
import {
  PROJECT_ARCHIVE,
  DOWNLOAD_PROJECT_PDF,
  COMPANY_LIST,
  ADD_TO_PROJECT,
  GET_FILTERED_PROJECTS,
} from "routes/ApiEndpoints";
import { ERROR, M_MASTER_DATA } from "constants/Constants";
import Pagination from "components/atoms/Pagination";
import {
  convertArrayToOptions,
  getAgreementStatusColor,
  getAgreementTooltipMessage,
  getProjectStatusColor,
  getProjectTooltipMessage,
  getStatusColor,
  getTooltipMessage,
} from "services/util/UtilService";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import AddPlanningIcon from "static/images/AddPlanningIcon";
import EncryptDecryptService from "services/EncryptDecryptService";
import RightSideBar from "../masterData/follow-up-todo/RightSideBar";
import { IconData } from "services/IconData";
import Calender from "components/molecules/Calender";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "./components/css/project.css";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import BackButton from "components/atoms/BackButton";
import FilterOrganism from "components/organism/FilterOrganism";
import {
  selectPageHistory,
  updatePageFilters,
} from "store/pageHistory/pageHistorySlice";
import { useDispatch } from "react-redux";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import { ProjectTableHeader } from "TableHeader";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import TextEllipsis from "components/atoms/TextEllipsis";
interface ManageProjectProps {
  data?: any[];
  handleData: any;
  history: any;
  dispatch: any;
  handleRefresh: () => void;
  pageData: any;
}
interface OptionItem {
  value: number;
  label: string;
}

interface CompanyAndBusinessUnit {
  companyOptions: OptionItem[] | null;
  businessUnitOptions: OptionItem[] | null;
}

export const ManageProjects: React.FC<ManageProjectProps> = ({
  data,
  handleData,
  dispatch,
  history,
  handleRefresh,
  pageData,
}) => {
  const userData = useSelector(selectAuth);
  const [id, setId] = useState<number | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const [options, setOptions] = useState<CompanyAndBusinessUnit>({
    companyOptions: null,
    businessUnitOptions: null,
  });
  const projectStatus = [
    "Drafted project",
    "Inactive project",
    "Active project",
  ];
  const [followupSideBar, setFollowupSideBar] = useState<boolean>(false);
  const [entity, setEntity] = useState<{
    entityId: string | number | null;
    businessUnit: string | number | null;
  }>({ entityId: null, businessUnit: null });
  const [sideBarWidth, setSidebarWidth] = useState("");
  const navigate = useNavigate();

  const handleSaveChanges = async () => {
    setShowModal(false);
    if (id) {
      const response = await ApiCall.getService(
        `${PROJECT_ARCHIVE}/${id}`,
        "GET",
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        handleRefresh();
        // handleData((prevData: any) =>
        //   prevData.filter((data: any) => data.id !== id)
        // );
        CustomNotify({ type: "success", message: response.message });
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  useEffect(() => {
    fetchEmployeeData();
  }, []);

  const fetchEmployeeData = async () => {
    try {
      const response = await ApiCall.getService(
        COMPANY_LIST,
        "get",
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setOptions({
          companyOptions: response?.companyOption,
          businessUnitOptions: response?.businessUnit,
        });
      } else {
        console.error(ERROR);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleEdit = (id: number) => {
    navigate(`${PATH_PROJECTS_EDIT}/${id}`);
  };

  const handleArchive = (id: number) => {
    setShowModal(true);
    setId(id);
  };

  const handleDownload = async (id: number) => {
    const response = await ApiCall.getService(
      `${DOWNLOAD_PROJECT_PDF}/${id}`,
      "GET",
      M_MASTER_DATA
    );
    if (response && response.download_url) {
      const link = document.createElement("a");
      link.href = response.download_url;
      link.target = "_blank";
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
      }, 1000);
    } else {
      //   console.error("Invalid response or missing download URL");
    }
  };

  const handleSendProjectProposal = (
    projectId: number,
    clientMailStatus: any,
    managerMailStatus: any,
    user: any
  ) => {
    const userIds = user.map((item: { user_id: any }) => item.user_id);
    const data = {
      id: projectId,
      clientMailStatus: clientMailStatus,
      ManagerMailStatus: managerMailStatus,
      users: userIds,
    };
    const append = EncryptDecryptService.encryptData(JSON?.stringify(data));
    const encodedData = encodeURIComponent(append);
    navigate(`${PATH_PROJECT_PROPOSAL}/?data=${encodedData}`);
  };
  const handleMatching = (projectId: number, companyId: number) => {
    navigate(`${PATH_MATCHING}/${projectId}/${companyId}`);
  };
  const handleLink = (projectId: number, companyId: number) => {
    navigate(`${PATH_EMPLOYEE_MANAGE}/${projectId}/${companyId}`);
  };

  const handleFollowup = (
    event: any,
    id: string | number | null,
    businessUnit: string | number | null
  ) => {
    // event.preventDefault(); // Prevent the default link action
    if (entity.entityId === id) {
      setEntity({
        entityId: null,
        businessUnit: null,
      });
      setFollowupSideBar(false);
    } else {
      setEntity({
        entityId: id ?? null,
        businessUnit: businessUnit ?? null,
      });
      setFollowupSideBar(true);
    }
  };

  const handleCloseSidebar = () => {
    setFollowupSideBar(false);
  };

  const getWidth = (width: any) => {
    setSidebarWidth(width);
  };

  const filters = [
    {
      name: "businessUnits",
      fieldType: "multiSelect",
      options: options?.businessUnitOptions,
      placeholder: "Business unit",
      filterType: "search",
    },
    {
      name: "project",
      fieldType: "text",
      placeholder: "Project",
      filterType: "search",
    },
    {
      name: "company",
      fieldType: "text",
      placeholder: "Company",
      filterType: "search",
    },
    {
      name: "location",
      fieldType: "text",
      placeholder: "Location",
      filterType: "search",
    },
    {
      name: "startDate",
      fieldType: "date",
      placeholder: "Start date",
      end: history?.filters?.endDate,
      filterType: "search",
    },
    {
      name: "endDate",
      fieldType: "date",
      placeholder: "End date",
      start: history?.filters?.startDate,
      filterType: "search",
    },
    {
      name: "status",
      fieldType: "singleSelect",
      options: convertArrayToOptions(projectStatus),
      placeholder: "Status",
      filterType: "search",
    },
    { name: "", fieldType: "", placeholder: "", filterType: "search" },
  ];

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Project",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar" style={{ paddingBottom: "0.25vw" }}>
          <Title title={t("Manage projects")} />
        </div>
        <div className="position-relative tableMainWrapper">
          <AccessControl
            requiredPermissions={[
              {
                permission: "Project",
                create: true,
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <div className="row">
              <div className="ManageCreateBtn">
                <LinkTo
                  pagelink="/project/create"
                  title={t("Create Project")}
                  icon={faPlus}
                />
              </div>
            </div>
          </AccessControl>
          <div className="table-responsive manage-projects tableSection">
            <FilterOrganism
              dispatch={dispatch}
              history={history}
              updatePageFilters={updatePageFilters}
              filters={filters}
              handleRefresh={handleRefresh}
            />
            <div className="TableHeight">
              <table className="table table-hover manage-projects">
                <thead>
                  <tr className="TableHeader">
                    {ProjectTableHeader.map((value: any) => (
                      <th
                        key={value.alias}
                        className="border-bottom-0"
                        // style={{ width: `${value.width}%` }}
                      >
                        {t(value.name)}
                        {value?.issort && (
                          <SortAtomForManagePage
                            value={value}
                            dispatch={dispatch}
                            history={history}
                            updatePageFilters={updatePageFilters}
                            handleRefresh={handleRefresh}
                          />
                        )}
                      </th>
                    ))}
                    <th className="table_actions">{t("Action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length > 0 ? (
                    data.map((record, key) => {
                      const formatDate = (
                        dateString: string | number | Date
                      ) => {
                        const date = new Date(dateString);
                        const day = String(date.getDate()).padStart(2, "0"); // Leading zero for day
                        const month = String(date.getMonth() + 1).padStart(
                          2,
                          "0"
                        ); // Leading zero for month
                        const year = date.getFullYear();
                        return `${day}-${month}-${year}`; // Formatted date
                      };
                      const canDownload = record?.proposal_status === 1;
                      const canViewProposal = record?.proposal_status === 0 ? true : false;
                      return (
                        <tr key={key}>
                          <td className="align-middle">
                            <TextEllipsis
                              label={record?.businessUnits}
                              title={record?.businessUnits}
                              width="5.5vw"
                            />
                          </td>
                          <td className="align-middle">
                            <TextEllipsis
                              label={record?.project}
                              title={record?.project}
                              width="11vw"
                            />
                          </td>
                          <td className="align-middle">
                            <TextEllipsis
                              label={record?.company}
                              title={record?.company}
                              width="11vw"
                            />
                          </td>
                          <td className="align-middle">
                            <TextEllipsis
                              label={record?.location}
                              title={record?.location}
                              width="11vw"
                            />
                          </td>
                          {/* <td className="align-middle">{record?.startdate}</td>
                        <td className="align-middle">{record?.enddate}</td> */}
                          <td className="align-middle">
                            {record?.startDate && (
                              <>
                                <div>
                                  {formatDate(record?.startDate)}
                                  {/* {new Date(
                                    record.startdate
                                  ).toLocaleDateString("en-GB")} */}
                                </div>
                              </>
                            )}
                          </td>
                          <td className="align-middle">
                            {record?.endDate && (
                              <>
                                <div>
                                  {formatDate(record?.endDate)}
                                  {/* {new Date(
                                    record.enddate
                                  ).toLocaleDateString()} */}
                                </div>
                              </>
                            )}
                          </td>

                          <td className="align-middle">
                            <div
                              className={getProjectStatusColor(
                                record?.isActive
                              )}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={getProjectTooltipMessage(record?.isActive)}
                            ></div>
                          </td>
                          <td className="align-middle">
                            <div
                              className={getAgreementStatusColor(
                                record?.proposal_status
                              )}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={getAgreementTooltipMessage(
                                record?.proposal_status,record?.mail_send_client,record?.mail_send_manager
                              )}
                            ></div>
                          </td>
                          <td className="align-middle">
                            <TableActions
                              isAction={true}
                              handleEdit={handleEdit}
                              handleArchive={handleArchive}
                              handleSendProjectProposal={
                                record?.proposal_status !== 1 &&
                                record?.isActive == 2
                                  ? () =>
                                      handleSendProjectProposal(
                                        record?.id,
                                        record?.mail_send_client,
                                        record?.mail_send_manager,
                                        record?.project_contact_persons
                                      )
                                  : undefined
                              }
                              // handleDownload={handleDownload}
                              handleDownload={
                                canDownload ? handleDownload : undefined
                              }
                              handleMatching={
                                record?.isActive == 2 &&
                                record?.proposal_status == 1
                                  ? () =>
                                      handleMatching(
                                        record?.id,
                                        record?.company_id
                                      )
                                  : undefined
                              }
                              linkIcon={record && record?.status.length > 0}
                              handleLink={() =>
                                handleLink(record?.id, record?.company_id)
                              }
                              followupIcon={record && record?.isActive === "2"}
                              handleFollowup={(e) => {
                                handleFollowup(
                                  e,
                                  record?.id,
                                  record?.project_business_units
                                );
                              }}
                              value={record?.id}
                              permission={"Project"}
                              signature={record?.project_pdf_path}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={10} className="text-center">
                        {t("No records found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div
              className=""
              style={{ paddingTop: "0.25vw" }}
            >
            <PaginationWithPerPage
              handleRefresh={handleRefresh}
              dispatch={dispatch}
              history={history}
              pageData={pageData}
            />
            </div>
          </div>
        </div>
        {/* <SearchWithLabel
              label={t("Start date or end date")}
              isMandatory={false}
              showLabel
              name={"date"}
              type={"date"}
              value={searchData?.date}
              placeholder={t("Start date or end date")}
              isDisabled={false}
              handleChange={handleSearchChange}
              className="col"
            /> */}
        {/* <div
          className="col-md-6 align-self-center"
          style={{ padding: "1vw 0" }}
        >
          <BackButton />
        </div> */}
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle="No"
          confirmTitle="Yes"
        />
        {followupSideBar && (
          <RightSideBar
            title={"Follow-ups"}
            entityType={"project"}
            businessUnit={entity.businessUnit}
            entityId={entity.entityId}
            onClose={handleCloseSidebar}
            getWidth={getWidth}
            recordsLimit={10}
            className="right-sidebar p-3"
            titleClassName="mt-4"
            destination="/projects"
          />
        )}
      </>
    </AccessControl>
  );
};
export default ManageProjects;
