import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as ENDPOINTS from "routes/ApiEndpoints";
import ViewIcon from "static/images/ViewIcon";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
// import "../questions/questions.css";
import { Spinner } from "react-bootstrap";
import MatchingProfileCard, {
  MatchingCandidateProfileCardType,
} from "components/Matching/MatchingCandidateProfileCard";
import { OptionProps } from "utils/TypeAnnotations";
import { ApiCall } from "services/ApiServices";
import { mapToSelect } from "utils/MapToSelect";
import CustomNotify from "components/atoms/CustomNotify";
import AccessControl from "services/AccessControl";
import Title from "components/atoms/Title";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import Calender from "components/molecules/Calender";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import Button from "components/atoms/Button";
import { t } from "../translation/Translation";
import Reset from "static/images/ResetIcon";
import Pagination from "components/atoms/Pagination";
import { M_MASTER_DATA } from "constants/Constants";
import BackButton from "components/atoms/BackButton";
import { PATH_CANDIDATE_CORNER, PATH_MANAGE_APPLICANTS } from "constants/Paths";

interface manageData {
  screenedBy: string;
  date: string;
  screeningId: number;
//   officeName: string;
}

interface searchData {
  date: Date | null;
  screenedBy: OptionProps[];
//   office: OptionProps[];
}

const ManageScreening: React.FC = () => {
  //initiliaze the formData state for search fields
  const [searchData, setSearchData] = useState<searchData>({
    date: null,
    screenedBy: [],
    // office: [],
  });
  const navigate = useNavigate();

  const [profileCardData, setProfileCardData] =
    useState<MatchingCandidateProfileCardType>({
      id: null,
      firstName: "",
      lastName: "",
      functions: "",
      mobNumber: "",
      email: "",
      street: "",
      number: "",
      box: "",
      zipCode: "",
      city: "",
      picFileName: "",
      picFilePath: "",
      country: "",
      cvData: "",
      profilePicData: "",
      isScreening: true,
      source: "manageScreening",
    });

  const [formData, setFormData] = useState([] as manageData[]);

  //state for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [loading, setLoading] = useState(true);
  const { candidateId } = useParams<{ candidateId: string }>();

  //initiliaze the state for dropdown values
  const [dropDowns, setDropDowns] = useState({
    screenedBy: [] as OptionProps[]
    // offices: [] as OptionProps[],
  });

  useEffect(() => {
    fetchScreeningDataBySearch();
  }, []);

  //API call for to fetch countries
  const fetchScreeningDataBySearch = async (searchData = {}, page = 1) => {
    const requestData = {
      ...searchData,
      page: page,
    };
    
    const response = await ApiCall.service(
      `${ENDPOINTS.MANAGE_SCREENING}/${candidateId}`,
      "POST",
      requestData,
      false,
      M_MASTER_DATA
    );

    if (response.status === 200) {
      setProfileCardData((prev) => ({
        ...prev,
        ...response.candidateData.candidateInfo.general['candidateInfo'][0],
        country: response.candidateData.candidateInfo.general.country,
        // ...{ cvData: response.candidateData.candidateInfo?.cvData },
        // ...{
          profilePicData: response.candidateData.candidateInfo?.profilePicData,
        // },
        ...{ functions: response.candidateData.candidateInfo?.functions },
      }));
      setFormData(response.data);
      setDropDowns((prevData) => ({
        ...prevData,
        screenedBy: mapToSelect(response.screenedBy),
      }));
      setTotalPages(response.totalPages);
      setLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent, reset:boolean) => {
    e.preventDefault();
    if(reset){
      setSearchData((prevData)=>({
        ...prevData,
        date: null,
        screenedBy : []
      }));
      fetchScreeningDataBySearch();

    }
    if (
      searchData.date === null &&
      searchData.screenedBy === undefined
    //    &&
    //   searchData.office.length === 0
    ) {
      CustomNotify({
        type: "warning",
        message: t("Search input not provided."),
      });
      fetchScreeningDataBySearch();
    } else {
      fetchScreeningDataBySearch(searchData, 1);
    }
  };

  const handleReset = () => {
    // Clear the selected values in the dropdowns
    setSearchData({
      date: null,
      screenedBy: [],
    //   office: [],
    });
    setCurrentPage(1);
    fetchScreeningDataBySearch();
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchScreeningDataBySearch(searchData, newPage);
  };

  const handleDateChange = (date: Date | null, name: string) => {
    const updatedDate = date !== null ? date : new Date();
    setSearchData((prevData) => ({
      ...prevData,
      [name]: updatedDate,
    }));
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSearchData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //changehandler for search fields
  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setSearchData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
  };

  const handleClick =  (value:boolean)=>{
    let candidateResult = true;
    if(value){
      candidateResult = true;
      navigate(`/employee/edit/${candidateId}?candidateId=${candidateId}`);
    }else{
      candidateResult = false;
      navigate(`${PATH_MANAGE_APPLICANTS}`);
    } 
    const postData = {
      id: candidateId,
      candidateResult: candidateResult
    }
    ApiCall.service(`${ENDPOINTS.CANDIDATE_RESULT}`, "POST", postData , true, M_MASTER_DATA).then((response)=>{
      if(response.status === 200){        
        CustomNotify({type: "success", message: response.message});
      }
    });      
  }
  
  return (
    <>
      {/* <AccessControl
        requiredPermissions={[
          {
            permission: "Candidates",
            read: true,
          },
        ]}
        renderNoAccess={true}
      > */}
        <Title title={t("Screening")} />
        <div className="px-4">
          <div className="row pt-5 mb-5">
            <div className="col-md-12">
              <MatchingProfileCard profileCardProps={profileCardData} />
              <div className="text-end">
                {formData && formData.length>0 &&
                <>
                 <Button
                title={t("SELECTED")}
                type="submit"
                handleClick={() => handleClick(true)}
                className="btn  form-button rounded-3 shadow-none me-3"
              />
              <Button
                title={t("REJECTED")}
                type="submit"
                handleClick={() => handleClick(false)}
                className="btn  form-button rounded-3 shadow-none"
              />
                </>
               }
            </div>
            </div>
          </div>
          <div className="form-height-dashboard">
            <div className="search-bar pb-3">
              <div className="row">
                <div className="col-md-12 manage-function-search">
                  <div className="row">
                    <div className="col-md-12 mb-3 mb-lg-0">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="col-sm-12 col-md-12">
                          <SelectWithSearch
                            search={true}
                            options={dropDowns.screenedBy}
                            title={t("Screened by")}
                            onChange={(e) => handleSelectChange(e, "screenedBy")}
                            isMulti={false}
                            name="screenedBy"
                            value={searchData.screenedBy}
                            placeHolder={t("Screened by")}
                          />
                          </div>
                        </div>
                        <div className="mb-3 mb-xl-0 position-relative col-md-3">
                          <Calender
                            onChange={handleDateChange}
                            selectedDate={searchData.date}
                            label={t("Date")}
                            isMandatory={false}
                            name="date"
                          />
                        </div>
                        {/* <div className="col-md-3">
                          <SelectWithSearch
                            search={true}
                            options={dropDowns.offices}
                            title={t("Office")}
                            onChange={(e) => handleSelectChange(e, "office")}
                            isMulti={true}
                            name="office"
                            value={searchData.office}
                            placeHolder={""}
                          />
                        </div> */}
                        <div className="col-lg-3 col-xxl-3">
                          <div className="d-flex align-items-center h-100">
                              <Button
                      title={t("Reset")}
                      type="submit"
                      handleClick={(e) => handleSubmit(e, true)}
                      className="btn delete-btn  shadow-none text-uppercase me-3 px-4 search-btns"
                    />
                    <Button
                      title={t("Search")}
                      type="submit"
                      handleClick={(e) => handleSubmit(e, false)}
                      className="btn form-button  shadow-none text-uppercase search-btns"
                    />
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="position-relative my-lg-4 mt-3 mb-5">
              <div className="">
                <div className="table-responsive manageScreeningTable border px-md-5 pt-5 px-2 pb-3 rounded-4 pwa">
                  <table className="table table-hover">
                    <thead>
                      <tr className="TableHeader">
                        <>
                          <th className="align-middle ps-lg-4">
                            {t("Screened by")}
                          </th>
                          <th className="align-middle ps-lg-4">
                            {t("Date of screening")}
                          </th>
                          {/* <th className="align-middle ps-lg-4">
                            {t("Office")}
                          </th> */}
                        </>
                        <th className="align-middle">{t("Actions")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr className="border-0">
                          <td
                            className="text-center border-0 spinner-wrapper"
                            colSpan={3}
                          >
                            <Spinner size="sm" className="me-2" />{" "}
                            {t("Loading")}
                          </td>
                        </tr>
                      ) : (
                        <>
                          {formData && formData.length > 0 ? (
                            formData.map((screening) => (
                              <tr
                                key={screening.screeningId}
                                className="border-bottom mb-3 box-shadow align-middle"
                              >
                                <>
                                  <td
                                    data-label={t("screenedBy")}
                                    className="align-middle ps-lg-4"
                                  >
                                    {screening.screenedBy}
                                  </td>
                                  <td
                                    data-label={t("date")}
                                    className="align-middle ps-lg-4"
                                  >
                                    {screening.date}
                                  </td>
                                  {/* <td
                                    data-label={t("office")}
                                    className="align-middle ps-lg-4"
                                  >
                                    {screening.officeName}
                                  </td> */}
                                </>

                                <td className="table-action-icons px-2 align-middle">
                                  <div className="d-none d-md-none d-lg-block">
                                    <Link
                                      to={`/screening/assessment/${screening.screeningId}/${candidateId}`}
                                      className="btn p-0 border-0 me-2"
                                      title={t("View")}
                                    >
                                      <ViewIcon />
                                    </Link>
                                  </div>
                                  {/* {/ PWA /} */}
                                  <div className="d-block d-md-block d-lg-none">
                                    <Link
                                      to={`/screening/assessment/${screening.screeningId}/${candidateId}`}
                                      className="btn p-0 border-0 w-100"
                                    >
                                      <Button
                                        title={t("View")}
                                        className=" mb-2 button-width delete-btn rounded-3"
                                      />
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="border rounded-3">
                              <td
                                colSpan={7}
                                className="border-0 text-center py-3 no-records"
                              >
                                <span className="text-danger w-100 d-block text-center">
                                  {t("No records")}
                                </span>
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                  {totalPages > 1 && (
                    <div className="pagination justify-content-center align-items-center mt-4">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={(newPage) => handlePageChange(newPage)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 align-self-center py-4">
            <BackButton />
          </div>
        </div>
      {/* </AccessControl> */}
    </>
  );
};

export default ManageScreening;
