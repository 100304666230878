import { useState } from "react";
import Navigation from "../form-navigation/Navigation";
import LabelField from "components/atoms/LabelField";
import { t } from "pages/microservices/masterData/translation/Translation";
import RadioField from "components/atoms/RadioField";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { TextAreaMolecule } from "components/molecules/TextAreaMolecule";
import PhoneInput from "react-phone-number-input";
import Button from "components/atoms/Button";
import { ADD_EXPERIENCE_FIELDS_SET, REMOVE_EXPERIENCE_FIELDS_SET, UPDATE_EXPERIENCE_FIELD, UPDATE_WORK_EXPERIENCE_ERROR, WORK_EXPERIENCE } from "../../annotations/CandidateConstants";
import { useFormContext } from "../../context/Context";
import Close from "static/images/Close";
import { refernceRelatedFields, workExperienceRelatedFields } from "../../helpers/CandidateHelperFunctions";
import Calender from "../../helpers/Calender";
import { ValidationRules } from "utils/TypeAnnotations";
import { validateEmailCanBeNull, validateForm, validatePhoneNumberCanBeNull, validateTextFieldAlphaCanBeNull } from "services/validation/ValidationService";

const WorkExperienceTab: React.FC = () => {
    const { state, dispatch } = useFormContext();
    const [validationStatus, setValidationStatus] = useState({
        isValid: false,
        type: "",
    });

    const validStatus = (validation: any) => {
        setValidationStatus({
            isValid: validation.isValid,
            type: validation.type,
        });
    };

    const validation = (
        name: string,
        value: string | boolean | Date | object[] | undefined,
        index: number,
        isSingleFieldValidation: boolean = false
    ) => {
        const validationRules: ValidationRules = {
            name: [validateTextFieldAlphaCanBeNull],
            email: [validateEmailCanBeNull],
            phoneNumber: [validatePhoneNumberCanBeNull]
        };
        const validationErrors = validateForm(
            { ...state.emergencyContactDetails[index], [name]: value },
            validationRules,
            isSingleFieldValidation ? name : undefined
        );
        dispatch({
            type: UPDATE_WORK_EXPERIENCE_ERROR,
            field: name,
            error: validationErrors[name],
            index,
        });
        const isFieldValid = Object.keys(validationErrors).length === 0;

        if (isFieldValid) {
            dispatch({
                type: UPDATE_WORK_EXPERIENCE_ERROR,
                field: name,
                error: "",
                index,
            });
        }

        if (Object.keys(validationErrors).length > 0) {
            return false;
        }

        return true;
    };

    const handleSelectChange = (selectedOption: any, fieldName: string, index: number) => {
        dispatch({
            type: UPDATE_EXPERIENCE_FIELD,
            field: fieldName,
            value: selectedOption,
            index: index
        });

    }

    const handleFieldChange = (e: any, index: number) => {
        const { name, value, type, checked } = e.target;
        let updatedName: string = name;
        let updatedValue = value;
        if (type == "radio" && name.startsWith('experience')) {
            updatedValue = parseInt(value);
            updatedName = "experience";
            if (updatedValue == 0) {
                workExperienceRelatedFields(dispatch, index)
            }
        } else if (type == "radio" && name.startsWith('reference')) {
            updatedValue = parseInt(value);
            updatedName = "reference";
            if (updatedValue == 0) {
                refernceRelatedFields(dispatch, index);
            }
        }

        dispatch({
            type: UPDATE_EXPERIENCE_FIELD,
            field: updatedName,
            value: updatedValue,
            index: index
        });
        validation(name, value, index, true);
    }

    const handleDateChange = (date: Date | null, fieldName: string, index: number) => {
        dispatch({
            type: UPDATE_EXPERIENCE_FIELD,
            field: fieldName,
            value: date,
            index: index

        });
    }

    const handlePhoneNumberChange = (number: any, fieldName: string, index: number) => {
        dispatch({
            type: UPDATE_EXPERIENCE_FIELD,
            field: fieldName,
            value: number,
            index: index
        });
        validation(fieldName, number, index, true);
    }

    const handleAddClick = () => {
        dispatch({
            type: ADD_EXPERIENCE_FIELDS_SET
        })
    }
    const handleRemove = (index: number) => {
        dispatch({
            type: REMOVE_EXPERIENCE_FIELDS_SET,
            indexToRemove: index
        })
    }

    return (
        <>
            <div className="form-border" style={{ paddingTop: "3vw" }}>
                {state.workExperience && state.workExperience.length > 0 && state.workExperience.map((experienceFields: any, index) => (
                    <div key={index}>
                        {index != 0 && <div className="float-end" onClick={() => handleRemove(index)}><Close /></div>}
                        <div className="row" style={{ marginBottom: "1vw" }}>
                            <div className="col">
                                <div>
                                    <LabelField
                                        title={t("Experience")}
                                        isMandatory={false}
                                        key="experience"
                                    />
                                </div>
                                <div className="mt-2 pt-1">
                                    <div className="d-inline-block me-4">
                                        <RadioField
                                            name={`experience-${index}`}
                                            value={1}
                                            ischecked={experienceFields.experience == 1}
                                            handleChange={(e) => handleFieldChange(e, index)}
                                            label={t('Yes')}
                                        />
                                    </div>
                                    <div className="d-inline-block">
                                        <RadioField
                                            name={`experience-${index}`}
                                            value={0}
                                            ischecked={experienceFields.experience == 0}
                                            handleChange={(e) => handleFieldChange(e, index)}
                                            label={t('No')}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        {experienceFields.experience === 1 && (
                            <div className="experienceFields">
                                <div className="row">
                                    <div className="col-4">
                                        <SelectWithSearch
                                            title={t("Experience in years")}
                                            isMandatory={false}
                                            search={true}
                                            options={state.dropDownData.experienceList}
                                            onChange={(e) => handleSelectChange(e, "experienceInYears", index)}
                                            isMulti={false}
                                            name="experienceInYears"
                                            value={experienceFields?.experienceInYears}
                                            error={""}
                                            placeHolder="Select"
                                        />
                                    </div>
                                    <div className="col-4">
                                        <LabelWithInputField
                                            isMandatory={false}
                                            name="designation"
                                            handleChange={(e) => handleFieldChange(e, index)}
                                            id="designation"
                                            label="Designation"
                                            type="text"
                                            value={experienceFields?.designation}
                                            error=""
                                        />
                                    </div>
                                    <div className="col-4">
                                        <LabelWithInputField
                                            isMandatory={false}
                                            name="jobCategory"
                                            handleChange={(e) => handleFieldChange(e, index)}
                                            id="jobCategory"
                                            label="Job category"
                                            type="text"
                                            value={experienceFields?.jobCategory}
                                            error=""
                                        />
                                    </div>
                                    <div className="col-4">
                                        <LabelWithInputField
                                            isMandatory={false}
                                            name="function"
                                            handleChange={(e) => handleFieldChange(e, index)}
                                            id="function"
                                            label="Function"
                                            type="text"
                                            value={experienceFields.function}
                                            error=""
                                        />
                                    </div>
                                    <div className="col-4">
                                        <LabelWithInputField
                                            isMandatory={false}
                                            name="employer"
                                            handleChange={(e) => handleFieldChange(e, index)}
                                            id="employer"
                                            label="Employer"
                                            type="text"
                                            value={experienceFields.employer}
                                            error=""
                                        />
                                    </div>
                                    <div className="col-2">
                                        <Calender
                                            onChange={(date) => handleDateChange(date, "from", index)}
                                            label={t('From')}
                                            isMandatory={false}
                                            name={'from'}
                                            selectedDate={experienceFields.from}
                                            maxDate={experienceFields.to}
                                        // minDate={new Date()} //enable dates from current date
                                        ></Calender>
                                    </div>
                                    <div className="col-2">
                                        <Calender
                                            onChange={(date) => handleDateChange(date, "to", index)}
                                            label={t('To')}
                                            isMandatory={false}
                                            name={'to'}
                                            selectedDate={experienceFields.to}
                                            minDate={experienceFields.from}
                                        ></Calender>
                                    </div>
                                    <div className="col">
                                        <TextAreaMolecule
                                            label={t('Job Description')}
                                            name={t('jobDescription')}
                                            id={t('jobDescription')}
                                            placeholder={"Job description"}
                                            handleChange={(e) => handleFieldChange(e, index)}
                                            isMandatory={false}
                                            value={experienceFields.jobDescription}
                                            error={''}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <TextAreaMolecule
                                            label={t('Reason for leaving')}
                                            name={t('reasonForLeaving')}
                                            id={t('reasonForLeaving')}
                                            placeholder={"Reason for leaving"}
                                            handleChange={(e) => handleFieldChange(e, index)}
                                            isMandatory={false}
                                            value={experienceFields.reasonForLeaving}
                                            error={''}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <div>
                                            <LabelField
                                                title={t("Refernece")}
                                                isMandatory={false}
                                                key="reference"
                                            />
                                        </div>
                                        <div className="mt-2 pt-1">
                                            <div className="d-inline-block me-4">
                                                <RadioField
                                                    name={`reference-${index}`}
                                                    value={1}
                                                    ischecked={experienceFields.reference == 1}
                                                    handleChange={(e) => handleFieldChange(e, index)}
                                                    label={t('Yes')}
                                                />
                                            </div>
                                            <div className="d-inline-block">
                                                <RadioField
                                                    name={`reference-${index}`}
                                                    value={0}
                                                    ischecked={experienceFields.reference == 0}
                                                    handleChange={(e) => handleFieldChange(e, index)}
                                                    label={t('No')}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {experienceFields.reference === 1 && (
                                    <div className="referenceFields" style={{ marginTop: "1vw" }}>
                                        <div className="row">
                                            <div className="col-6">
                                                <LabelWithInputField
                                                    isMandatory={false}
                                                    name="company"
                                                    handleChange={(e) => handleFieldChange(e, index)}
                                                    id="company"
                                                    label="Company"
                                                    type="text"
                                                    value={experienceFields.company}
                                                    error=""
                                                />
                                            </div>
                                            <div className="col-3">
                                                <LabelWithInputField
                                                    isMandatory={false}
                                                    name="name"
                                                    handleChange={(e) => handleFieldChange(e, index)}
                                                    id="name"
                                                    label="Name"
                                                    type="text"
                                                    value={experienceFields.name}
                                                    error={state.workExperienceErrors[index]?.name}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <LabelWithInputField
                                                    isMandatory={false}
                                                    name="email"
                                                    handleChange={(e) => handleFieldChange(e, index)}
                                                    id="email"
                                                    label="Email address"
                                                    type="email"
                                                    value={experienceFields.email}
                                                    error={state.workExperienceErrors[index]?.email}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <LabelField
                                                    title={t("Phone number")}
                                                    isMandatory={false}
                                                    key="PhoneInput"
                                                // htmlfor={field.name}
                                                />
                                                <div className="form-control field-shadow">
                                                    <PhoneInput
                                                        defaultCountry="BE"
                                                        international
                                                        placeholder="Enter phone number"
                                                        onChange={(mobileNumber) =>
                                                            handlePhoneNumberChange(mobileNumber, "phoneNumber", index)
                                                        }
                                                        value={experienceFields.phoneNumber}
                                                    // disabled={isEdit}
                                                    />
                                                </div>
                                                <div>
                                                    {state.workExperienceErrors[index]?.phoneNumber &&
                                                        <span className="text-danger">{state.workExperienceErrors[index]?.phoneNumber}</span>}
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <TextAreaMolecule
                                                    label={t('Reference info')}
                                                    name={t('referenceInfo')}
                                                    id={t('referenceInfo')}
                                                    placeholder={"Reference info"}
                                                    handleChange={(e) => handleFieldChange(e, index)}
                                                    isMandatory={false}
                                                    value={experienceFields.referenceInfo}
                                                    error={''}
                                                />
                                            </div>


                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                ))}
                <div className="row">
                    <div className="col-12">
                        <Button
                            title={"+ " + t("Add another")}
                            handleClick={handleAddClick}
                            className="form-button float-end px-3"
                        />
                    </div>
                </div>

            </div >
            <div style={{ padding: "1vw 0" }}>
                <Navigation validStatus={validStatus} />
            </div>
        </>


    );
}
export default WorkExperienceTab;