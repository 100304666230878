// src/store/pageHistorySlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AddPagePayload, findIndexOfPage, initialState, UpdateFiltersPayload } from "./state";
import { RootState } from "store";

const pageHistorySlice = createSlice({
    name: "pageHistory",
    initialState,
    reducers: {
        // Add a new page to history or update existing page filters
        addPageToHistory: (state, action: PayloadAction<AddPagePayload>) => {
            const { pageName, route, isBackAction, filters } = action.payload;
            // If the action is a back action, remove the last page from history
            if (isBackAction) {
                state.history.pop(); // Remove the latest page from history
            } else {
                if (pageName && route) {
                    // Find the last occurrence of the page
                    const lastIndex = findIndexOfPage(state.history, pageName);                                
                    if (lastIndex !== -1) {
                        const existingPage = state.history.splice(lastIndex, 1)[0];                
                        // Add it to the end of the history array with updated data
                        state.history.push({ ...existingPage, pageName, route, filters: {...existingPage?.filters, ...filters}});
                    } else {
                        // If it's a new page, add it to the history
                        state.history.push({ pageName, route, filters: filters ?? {}});
                        // Keep history within limit
                        if (state.history.length > 10) {
                            state.history.shift(); // Remove the oldest entry
                        }
                    }            
                }
            }
        },
        // Update filters for an existing page
        updatePageFilters: (state, action: PayloadAction<UpdateFiltersPayload>) => {
            const { filters, reset } = action.payload; 
            if (state?.history?.length > 0) {
                if (reset) {
                    state.history[state?.history?.length - 1].filters = {currentPage: "1", itemsPerPage: "10"};
                } else {
                // Update filters if the page exists
                    state.history[state?.history?.length - 1].filters = {
                        ...state.history[state?.history?.length - 1]?.filters,
                        ...filters
                    }
                }
            }        
        },
        removeHistory: (state) => {
            state.history = [];
        },
    },
});

// Exporting actions
export const { addPageToHistory, updatePageFilters, removeHistory } = pageHistorySlice.actions;
export const selectPageHistory = (state: RootState) => state.pageHistory;
export default pageHistorySlice.reducer;
