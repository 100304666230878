import React, { CSSProperties, useState, useEffect } from "react";
import "static/css/file-upload.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudArrowUp,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import LabelField from "../atoms/LabelField";
import { t } from "pages/microservices/masterData/translation/Translation";

interface SelectedFile {
  name: string;
  url: string;
}

interface FileUploadProps {
  label: string;
  isMandatory?: boolean;
  id: string;
  edit?: any;
  fileId?: any;
  multiple?: any;
  uploadPath?: any;
  formats?: any;
  name: string;
  style?: CSSProperties;
  handleChange: any;
  fileObj?: any;
  maxFileSize?: any;
  mode?: any;
  error?: any
}

const FileUploadMolecule: React.FC<FileUploadProps> = (props) => {
  const {
    label,
    isMandatory,
    id,
    name,
    style,
    fileObj,
    handleChange,
    formats,
    mode,
  } = props;
  const [selectedFile, setSelectedFile] = useState<SelectedFile | null>(null);
  useEffect(() => {
    if (fileObj) {
      const parsedFileObj = JSON.parse(fileObj);
      if (parsedFileObj?.accessUrl && parsedFileObj?.fileName) {
        setSelectedFile({
          name: parsedFileObj?.fileName,
          url: parsedFileObj?.accessUrl,
        });
      }
    }
  }, [fileObj]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setSelectedFile({
        name: files[0].name,
        url: URL.createObjectURL(files[0]),
      });
      if (handleChange) handleChange(files[0]);
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    if (handleChange) handleChange(null);
  };

  const handleFileNameClick = () => {
    if (selectedFile && selectedFile?.url) {
      window.open(selectedFile?.url, "_blank");
    }
  };

  const trimFileName = (fileName: string) => {
    if (fileName.length > 10) {
      return fileName.substring(0, 22) + "...";
    }
    return fileName;
  };

  return (
    <>
      <LabelField title={label} isMandatory={isMandatory} />
      <div style={{ position: "relative", ...style }}>
        <div
          className="d-flex align-items-center file-upload-wrapper cursor-pointer"
          style={{ marginBottom: "5px" }}
        >
          <label
            htmlFor={id}
            className="btn upload-btn cursor-pointer w-100 text-left d-flex align-items-center"
          >
            <FontAwesomeIcon icon={faCloudArrowUp} className="me-2" />
            <span className="uploadFileText">{t("Upload file")}</span>
          </label>
          <input
            type="file"
            id={id}
            name={name}
            className="cursor-pointer"
            accept={formats}
            onChange={handleFileChange}
            style={{ display: "none" }}
            disabled={mode}
          />
        </div>
        {props?.error && <span className="text-danger">{props.error}</span>}
        {selectedFile && (
          <div className="d-flex align-items-center table-action-icons">
            <span
              onClick={handleFileNameClick}
              style={{ cursor: "pointer" }}
              className="me-3 uploadedFileName"
            >
              {trimFileName(selectedFile.name)}
            </span>
            <span
              className="border-0 table-action-btn cursor-pointer"
              onClick={handleRemoveFile}
              title={t("Remove")}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default FileUploadMolecule;
