import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import HelpInfoIcon from "static/images/HelpIcon";
import { RANGE_VALUE, RANGE_VALUE_FOR_GENERAL } from "constants/Constants";
import { COMPETENCE_CHANGE } from "../context/Constants";
import { t } from "pages/microservices/masterData/translation/Translation";

interface Option {
  label: string;
  value: string;
  range: string;
}

interface CompetenceProps {
  competenceOptions: any;
  state: any;
  dispatch: any;
}
const ProjectCompetences: React.FC<CompetenceProps> = ({ competenceOptions, state, dispatch }) => {

  const handleSelect = (selectedId: number, section: string, selected: Option[]) => {
    dispatch({
      type: COMPETENCE_CHANGE, field: "SELECT",
      payload: { selection: section, selected: selected.length > 0 ? selected : [], id: selectedId },
    });
  };

  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({ isValid: validation.isValid, type: validation.type });
  };

  let data = state.competences?.competence;
  const getTitleForRating = (range: string) => {
    switch (parseInt(range)) {
      case 1:
        return "A1";
      case 2:
        return "A2";
      case 3:
        return "B1";
      case 4:
        return "B2";
      case 5:
        return "C1";
      case 6:
        return "C2";
      default:
        return "A1";
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    section: string,
    index: number
  ) => {
    dispatch({
      type: COMPETENCE_CHANGE,
      payload: { value: e.target.value, section: section, index: index },
    });
  };

  return (
    <div className="vacancyEmployeementTab">
      <div className="position-relative">
        <div className="form-border px-lg-5 py-5 px-4">
          <div className="form pb-4 pt-2">
            <Accordion defaultActiveKey={competenceOptions?.[0]?.key} alwaysOpen>
              {competenceOptions && competenceOptions.map((section: any) => {
                return (<Accordion.Item key={section?.key} eventKey={section?.key}>
                  <Accordion.Header>{section?.label}</Accordion.Header>
                  <Accordion.Body>
                    <SelectWithSearch
                      name={section.key}
                      isMandatory={false}
                      search={true}
                      placeHolder="Select"
                      options={section.options}
                      value={data?.filter((item: any) => item.key == section.key)?.[0]?.options}
                      onChange={(selected) => handleSelect(section.id, section.key, selected)}
                      isMulti={true}
                      className="select-field"
                      error={""}
                    />
                    <div className="row border m-0 mb-2">
                      {data?.length > 0 &&
                        data.find((item: any) => item.key === section.key)?.options && (
                          <div
                            className="text-end mt-2"
                            data-bs-toggle="tooltip"
                            data-bs-placement="left"
                            title={section?.info}
                          >
                            {/* `A. Basic user (A1/A2: beginner)\nB. Independent user (B1/B2: advanced)\nC. Skilled user (C1/C2: advanced)` */}
                            <HelpInfoIcon />
                          </div>
                        )}
                      {data?.length > 0 && data?.find((item: any) => item.key === section.key)?.options?.map((option: any, index: any) => {
                        return (
                          <div key={index} className="col-6 my-3">
                            <div className="row">
                              <div className="col-3">
                                <span className="me-3 fw-bolder">{option?.label}</span>
                              </div>
                              <div className="col">
                                <div className="w-100">
                                  <input
                                    type="range"
                                    min="1"
                                    max={option?.maxRange ?? "6"}
                                    title={getTitleForRating(option?.range)}
                                    value={option.range}
                                    className="w-100"
                                    onChange={(e) => handleChange(e, section.key, index)}
                                  />{" "}
                                  <div className="d-flex justify-content-between">
                                    {(option?.maxRange == 4 ? RANGE_VALUE_FOR_GENERAL : RANGE_VALUE).map((value, index) => (
                                      <span key={value}>{value}</span>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>)
              })}
            </Accordion>
            {/* <Navigation validStatus={validStatus} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCompetences;
