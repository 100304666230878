import { useState } from "react";
import { useFormContext } from "../../context/Context";
import { JOB_DESCRIPTION, UPDATE_FIELD_ERROR, UPDATE_FIELDS } from "../../annotation/VacancyConstants";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { t } from "pages/microservices/masterData/translation/Translation";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import FileUpload from "components/atoms/FileUpload";
import LabelWithCKEditorField from "components/molecules/LabelWithCKEditorField";
import Navigation from "../form-navigation/Navigation";

const VacancyJobDescriptionDetails = () => {
    const { state, dispatch } = useFormContext();

    const [validationStatus, setValidationStatus] = useState({
        isValid: false,
        type: ""
    });

    const validStatus = (validation: any) => {
        setValidationStatus({
            isValid: validation.isValid,
            type: validation.type
        });
    };

    const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        dispatch({type: UPDATE_FIELDS, template: JOB_DESCRIPTION, field: name, value: value});
    };
    const handleCKEditorChange = (event: any, editor: any, fieldName: string) => {
        const content = editor.getData(); // Get the content from the editor
        const name = fieldName; // Use the fieldName parameter
        dispatch({type: UPDATE_FIELDS, template: JOB_DESCRIPTION, field: name, value: content });
    };

    const handleSelectChange = (selectedOption: any, name: string) => {
        dispatch({
            type: UPDATE_FIELDS, template: JOB_DESCRIPTION, field: name,
            value: Array.isArray(selectedOption) ? selectedOption?.map((item: any) => item?.value) : selectedOption?.value,
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
    };

    const fileUploadStyleObject: React.CSSProperties = {
        width: "100%",
    };

    const returnFileUploadErrors = (errorResposne: any) => {
        const step = errorResposne["step"];
        const error = errorResposne["error"];
        dispatch({ type: UPDATE_FIELD_ERROR, field: step, error: error });
    };

    const fileUploadResponse = (responseObj: any) => {
        const step = responseObj["step"];
        const response = responseObj["response"];
        const fieldMappings: { [key: string]: string[] } = {
            fileId: ["fileId", "fileName", "filePath"],
        };
        const fieldNames = fieldMappings[step];
        if (fieldNames) {
            fieldNames.forEach((fieldName: string, key: number) => {
                dispatch({type: UPDATE_FIELDS, template: JOB_DESCRIPTION, field: fieldName, value: response[key] });
            });
        }
    };

    const deleteResponse = (field: string) => {
        const fieldMappings: { [key: string]: string[] } = {
            certificateId: ["fileId", "fileName", "filePath"],
        };

        const fieldNames = fieldMappings[field];

        if (fieldNames) {
            fieldNames.forEach((fieldName: string) => {
                dispatch({
                    type: UPDATE_FIELDS, template: JOB_DESCRIPTION,
                    field: fieldName, value: fieldName.endsWith("Id") ? null : ""
                });
            });
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="position-relative">
                <div className="form-border px-lg-5 py-5 px-4">
                    <div className="form pb-4 pt-2">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <SelectWithSearch
                                    title={t("Reason for closure")}
                                    name="reasonForClosure"
                                    placeHolder={t("Select")}
                                    search={true}
                                    options={state.options.ReasonForClosure}
                                    value={state.jobDescription.reasonForClosure}
                                    onChange={(e) =>
                                        handleSelectChange(e, "reasonForClosure")}
                                    isMulti={false}
                                    className="select-field"
                                />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <LabelWithInputField
                                    isMandatory={false}
                                    name="extraInfo"
                                    handleChange={handleFieldChange}
                                    value={state.jobDescription.extraInfo}
                                    id="extraInfo"
                                    label={t("Extra info")}
                                    type="text"
                                />
                            </div>
                        </div>
                        <div>
                            {/* <div className="col-sm-12 col-md-6 col-xl-4">
                                <FileUpload
                                    id="fileId"
                                    name="fileId"
                                    label={t("Upload file")}
                                    isMandatory={false}
                                    edit={false}
                                    fileId={state.jobDescription.fileId ? state.jobDescription.fileId : null}
                                    filePath={state.jobDescription.filePath ? state.jobDescription.filePath : ""}
                                    fileName={state.jobDescription.fileName ? state.jobDescription.fileName : ""}
                                    style={fileUploadStyleObject}
                                    multiple={false}
                                    uploadPath="vacancy-certificates"
                                    formats=".pdf,.docx,.doc,.jpg,.jpeg"
                                    returnFileUploadErrors={(errorResponse: any) =>
                                        returnFileUploadErrors(errorResponse)
                                    }
                                    fileUploadResponse={(uploadResposne: any) =>
                                        fileUploadResponse(uploadResposne)
                                    }
                                    deleteResponse={(field: any) => deleteResponse(field)}
                                    maxFileSize={10 * 1024 * 1024}
                                    microserviceURL={`${process.env.REACT_APP_CDM_SERVICE}`}
                                />
                                <span className="text-danger">
                                    {state.errors?.fileId}
                                </span>
                            </div> */}
                        </div>
                        <div className="row pb-3 mt-4">
                            <div className="col-md-12">
                                <LabelWithCKEditorField
                                    label={t("Info")}
                                    name="info"
                                    value={state.jobDescription.info}
                                    placeholder={t("Type here") + "..."}
                                    handleChange={(event, editor) =>
                                        handleCKEditorChange(event, editor, "info")}
                                    // className="field-shadow"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Navigation validStatus={validStatus} />
        </form>
    );
};

export default VacancyJobDescriptionDetails;