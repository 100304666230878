import React, { useState, useEffect, useRef } from "react";
import { ApiCall } from "services/ApiServices";
import { APPROVE_BY_MANAGER, SAVE_SIGNATURE } from "routes/ApiEndpoints";
import CheckBoxField from "components/atoms/CheckBoxField";
import SignatureCanvas from "react-signature-canvas";
import { M_MASTER_DATA } from "constants/Constants";
import { t } from "pages/microservices/masterData/translation/Translation";
import { selectAuth } from "features/auth/AuthSlice";
import { useSelector, useDispatch } from "react-redux";
import { PATH_PROJECTS_MANAGE } from "constants/Paths";
import { useNavigate } from "react-router-dom";
import Button from "components/atoms/Button";
import SignatureModal from "./SignatureModel";
import WindowHeightComponent from "components/atoms/WindowHeightComponent";

export const Proposal: React.FC = () => {
  const [state, setState] = useState({
    approved: 0,
    showModal: false,
    signingAsEmployer: false,
    iframeKey: 0,
    signatureErrorMessage: "",
    signatureCompleted: false,
    approveAsManager: false,
  });
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  
  const encryptedProjectId = urlParams.get("id");
  const decryptedProjectId = encryptedProjectId ? atob(encryptedProjectId) : "";
  const splitArray = decryptedProjectId.split("id-");
  const projectId = splitArray[1];

  // Explicitly define the type of the ref
  const signatureRef = useRef<SignatureCanvas | null>(null);
  const userAuth = useSelector(selectAuth);

  const handleCheckbox = (checked: boolean) => {
    setState((prevState) => ({
      ...prevState,
      approved: checked ? 1 : 0,
      signingAsEmployer: false,
    }));
  };
  useEffect(() => {
    if (window.location.href.includes("manager")) {
      setState(prevState => ({
        ...prevState,
        approveAsManager: true
      }));
    }
  }, []);
  const handleModalClose = () => {
    setShowModal(false);
    setState((prevState) => ({
      ...prevState,
      signatureErrorMessage: "",
      signingAsEmployer: false,
    }));
  };
  const handleSignEmployer = () => {
    setState((prevState) => ({ ...prevState, signingAsEmployer: true }));
    setShowModal(true);
  };
  const handleClearSignature = () => {
    signatureRef.current?.clear();
  };

  const handleSaveSignature = async () => {
    if (!signatureRef.current?.isEmpty()) {
      const signatureImage = signatureRef.current?.toDataURL();
      // Create an object with the key 'image' and the value as signatureImage
      const requestData = {
        image: signatureImage,
        userEmail: userAuth.email,
        projectId: projectId,
      };
      // Now you can use the requestData as needed
      const response = await ApiCall.service(
        SAVE_SIGNATURE,
        "POST",
        requestData,
        false,
        M_MASTER_DATA
      );

      setState((prevState) => ({
        ...prevState,
        iframeKey: prevState.iframeKey + 1,
        signatureErrorMessage: "",
        signatureCompleted: true,
        approveAsManager:false,

      }));
      if (response?.status == 200) {
        navigate(`${PATH_PROJECTS_MANAGE}`);
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        signatureErrorMessage: t("Please add signature before saving."),
        signatureCompleted: false,
        approveAsManager:false,
      }));
    }
  };
  const handleApprove = async () => {
  
    const signatureImage = signatureRef.current?.toDataURL();
    
    const requestData = {
      image: signatureImage || '',
      userEmail: userAuth.email,
      projectId: projectId,
    };
    const response = await ApiCall.service(
      APPROVE_BY_MANAGER,
      "POST",
      requestData,
      false,
      M_MASTER_DATA
    );
    if (response?.status == 200) {
      navigate(`${PATH_PROJECTS_MANAGE}`);
    }
  }

  return (
    <div>
      <WindowHeightComponent>
        <div className="d-flex h-100 overflow-auto flex-column">
          <div className="flex-1 overflow-auto">
            <iframe
              key={state.iframeKey}
              src={`${process.env.REACT_APP_MASTER_DATA_URL}/project-pdf/${projectId}`}
              height="99%"
              width="100%"
            />
          </div>
          {!state.approveAsManager && !state.signatureCompleted && (
            <div className="my-3 fw-bolder">
              <CheckBoxField
                name={"checkBox"}
                onChangeHandler={(e) => handleCheckbox(e.target.checked)}
                isChecked={state.approved === 1}
                label={t(
                  "I hereby declare that I have reviewed the document and confirming that I agree with all details"
                )}
              />
            </div>
          )}
        </div>
      </WindowHeightComponent>
      {state.approved === 1 && !state.signatureCompleted && (
        <div>
          {state.signingAsEmployer ? (
            <div>
              {showModal && (
                <SignatureModal
                  handleClose={handleModalClose}
                  handleClearSignature={handleClearSignature}
                  handleSaveSignature={handleSaveSignature}
                  signatureRef={signatureRef}
                  signatureErrorMessage={state.signatureErrorMessage}
                />
              )}
            </div>
          ) : (
            <>
              <Button
                type="button"
                title={t("Sign as Employer")}
                handleClick={handleSignEmployer}
                className="btn float-end form-button ms-3 shadow-none  text-uppercase"
              />
            </>
          )}
        </div>
      )}
      {state.approveAsManager && (
        <Button
          type="button"
          title={t("Appove")}
          handleClick={handleApprove}
          className="btn  form-button ms-3 shadow-none  text-uppercase"
        />
      )}
    </div>
  );
};
