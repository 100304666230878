export const NEXT_STEP = "NEXT_STEP";
export const PREVIOUS_STEP = "PREVIOUS_STEP";
export const UPDATE_TAB_DETAILS = "UPDATE_TAB_DETAILS";
export const UPDATE_TAB_ERROR = "UPDATE_TAB_ERROR";
export const UPDATE_PROFILE_FIELD = "UPDATE_PROFILE_FIELD";
export const ADD_CERTIFICATE = "ADD_CERTIFICATE";
export const REMOVE_CERTIFICATE = "REMOVE_CERTIFICATE";
export const REMOVE_DOCUMENT = "REMOVE_DOCUMENT";
export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const ADD_STUDY_FIELDS_SET = "ADD_STUDY_FIELDS";
export const UPDATE_STUDY_FIELD = "UPDATE_STUDY_FIELD";
export const REMOVE_STUDY_FIELDS_SET = "REMOVE_STUDY_FIELDS";
export const ADD_EXPERIENCE_FIELDS_SET = "ADD_EXPERIENCE_FIELDS_SET";
export const REMOVE_EXPERIENCE_FIELDS_SET = "REMOVE_EXPERIENCE_FIELDS_SET";
export const UPDATE_EXPERIENCE_FIELD = "UPDATE_EXPERIENCE_FIELD";
export const UPDATE_FIELD = "UPDATE_FIELD";
export const ADD_EMERGENCY_CONTACT_FIELDS = "ADD_EMERGENCY_CONTACT_FIELDS";
export const REMOVE_EMERGENCY_CONTACT_FIELDS = "REMOVE_EMERGENCY_CONTACT_FIELDS";
export const UPDATE_EMERGENCY_CONTACT_DETAILS = "UPDATE_EMERGENCY_CONTACT_DETAILS";
export const UPDATE_EMERGENCY_CONTACT_FIELD_ERROR = "UPDATE_EMERGENCY_CONTACT_FIELD_ERROR";
export const UPDATE_WORK_EXPERIENCE_ERROR = "UPDATE_WORK_EXPERIENCE_ERROR";

export const UPDATE_DOMICILE_ADDRESS_FIELDS = "UPDATE_DOMICILE_ADDRESS_FIELDS";
export const UPDATE_ERROR_FIELD = "UPDATE_ERROR_FIELD";
export const SET_COMPETENCE_DATA = "SET_COMPETENCE_DATA";
export const SET_DROPDOWN_DATA = "SET_DROPDOWN_DATA";

//edit
export const UPDATE_TAB_DATA="UPDATE_TAB_DATA";
export const EDIT_FIELD="EDIT_FIELD";

//competence
export const COMPETENCE_CHANGE = "COMPETENCE_CHANGE";

//Tab constants
export const PROFILE_CARD = "profileCard";
export const GENERAL = "general";
export const EMERGENCY_CONTACT_DETAILS = "emergencyContactDetails";
export const OTHERS = "others";
export const STUDIES = "studies";
export const COMPETENCE = "competence";
export const WORK_EXPERIENCE = "workExperience";
export const JOB_PROFILE = "jobProfile";
export const DOCUMENTS = "documents";
export const EUROPEAN_CITIZEN = "europeanCitizen"
