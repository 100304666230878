import React, { useState, useEffect } from "react";
import CommonBox from "../atoms/commonBox";
import {
  useEmployee,
  useEmployeeDispatch,
} from "../timesheets-redux/context/EmployeeContext/EmployeeContext";

import { EMPLOYEE_ACTIONS } from "pages/microservices/planning/planning-redux/actions/EmployeesActions";
import { useTimesheetDispatchContext } from "../timesheets-redux/context/TimesheetContext/TImesheetContex";
import { getWeekDays } from "../utils/utils";
import { dateFormat } from "../utils/utils";
import { fetchWeeklyTimesheetData } from "../utils/utils";
import { TIMESHEET_ACTIONS } from "../timesheets-redux/actions/TimesheetActions";
import { useWeekAndDay } from "../timesheets-redux/context/WeekAndDayContext/WeekAndDayContext";
import { useProject } from "../timesheets-redux/context/ProjectContext/ProjectContext";
import { useFilterOptions } from "../timesheets-redux/context/FiltersContext/FiltersContext";
const TimesheetsEmployees = () => {
  const { initialEmployeeState } = useEmployee();
  const { dispatchEmployee } = useEmployeeDispatch();
  const { filterOptionsState } = useFilterOptions();
  const { timesheetDispatch } = useTimesheetDispatchContext();
  const { initialWeekAndDayPropsState } = useWeekAndDay();
  const { projectState } = useProject();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);

  useEffect(() => {
    if (initialEmployeeState.employees.length > 0) {
      setSelectedEmployeeId(initialEmployeeState.employees[0].value);
    }
  }, [initialEmployeeState.employees]);

  useEffect(() => {
    if (selectedEmployeeId) {
      handleEmployeeClick(selectedEmployeeId);
    }
  }, [filterOptionsState?.refresh]);

  const handleEmployeeClick = async (employee_id) => {
    setSelectedEmployeeId(employee_id);
    dispatchEmployee({
      type: EMPLOYEE_ACTIONS.SET_EMPLOYEE,
      payload: employee_id,
    });
    const date = new Date(initialWeekAndDayPropsState.currentDate);
    let weekDays = getWeekDays(date);
    let startDate = dateFormat(weekDays[0]);
    let endDate = dateFormat(weekDays[6]);
    const data = {
      project_id: projectState.project_id,
      employee_id: employee_id,
      from_date: startDate,
      to_date: endDate,
    };
    const updatedData = await fetchWeeklyTimesheetData(data, date);
    timesheetDispatch({
      type: TIMESHEET_ACTIONS.CHANGE_WEEK,
      payload: {
        data: updatedData,
      },
    });
  };
  const tableBodyStyle = {
    // maxHeight: "900px",
    // overflowY: "scroll",
    // display: "block",
  };

  return (
    <table className="w-100 border-end h-100 overflow-auto">
      <thead className="position-sticky top-0">
        <tr className="border-0">
          <th className="py-3 bg-lightskyblue">Employee</th>
        </tr>
      </thead>
      <tbody style={tableBodyStyle}>
        {initialEmployeeState?.employees?.map((employee, index) => (
          <tr key={employee?.value} className="border-0 d-block cursor-pointer">
            <td style={{ height: "70px" }} className="d-block">
              <CommonBox
                data={employee?.label ?? ""}
                bg_color={
                  selectedEmployeeId === employee?.value ? "#00a5ce" : "#e8f8fb"
                } // Highlight if selected
                color={"black"}
                handleBoxClick={() => handleEmployeeClick(employee?.value)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TimesheetsEmployees;
