import { useState } from "react";
import { useFormContext } from "../../context/Context";
import { EMPLOYMENT, JOB_DESCRIPTION, SET_OPTIONS, UPDATE_FIELD_ERROR, UPDATE_FIELDS } from "../../annotation/VacancyConstants";
import { scrollToTop, validateForm, validateMultiSelectField, validateRequired, validateSelectField } from "services/validation/ValidationService";
import { Option } from "components/common/CommonInterfaces";
import { ValidationRules } from "utils/TypeAnnotations";
import { t } from "pages/microservices/masterData/translation/Translation";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import LabelWithCKEditorField from "components/molecules/LabelWithCKEditorField";
import Navigation from "../form-navigation/Navigation";
import { checKVdabIsSelectedOrNot, getVdabCompetences } from "../../utils/Utils";
import { GET_VDAB_COMPETENCES } from "routes/ApiEndpoints";

const VacancyEmplomentDetails = () => {
  const { state, dispatch } = useFormContext();
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    const newValue = value.replace(/[^0-9,]/g, ""); // Allow only numbers and commas
    if ((parseInt(newValue) >= 0 && parseInt(newValue) <= 300) || newValue === "") {
      dispatch({type: UPDATE_FIELDS, template: EMPLOYMENT, field: name, value: newValue});
    }
    validation(name, newValue, true);
  };

  const handleSelectChange = async (selectedOption: any, name: string) => {    
    dispatch({
      type: UPDATE_FIELDS, template: EMPLOYMENT, field: name,
      value: Array.isArray(selectedOption) ? selectedOption?.map((item: any) => item?.value) : selectedOption?.value,
    });
    if (name === "vdabTemplate") {
      const template: any = state?.options?.VdabTemplate?.find((op) => op?.value === selectedOption?.value);
      dispatch({type: UPDATE_FIELDS, template: EMPLOYMENT, field: 'vdabCompetences', value: []});
      if (template) {
        await getVdabCompetences(state, dispatch, {template_id: template?.template_id});
      }else {
        dispatch({ type: SET_OPTIONS, payload: {VdabCompetence: []}});
      }
    }
    validation(name, selectedOption, true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    scrollToTop();
  };

  const handleCKEditorChange = (event: any, editor: any, fieldName: string) => {
    const content = editor.getData();
    const name = fieldName;
    dispatch({type: UPDATE_FIELDS, template: EMPLOYMENT, field: name, value: content});
  };

  const validation = (name: string, value: any,isSingleFieldValidation: boolean = false) => {
    const validationRules: ValidationRules = {
      contract: [validateMultiSelectField],
      employment: [validateSelectField],
      hrs: [validateRequired],
      regime: [validateSelectField],
      shift: [validateMultiSelectField],
      vdabTemplate: checKVdabIsSelectedOrNot(state) ? [validateSelectField] : [],
      vdabCompetences: checKVdabIsSelectedOrNot(state) ? [validateMultiSelectField] : []
    };
    const validationErrors = validateForm(
      { ...state.employment, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    dispatch({type: UPDATE_FIELD_ERROR, field: name, error: validationErrors[name]});
    const isFieldValid = Object.keys(validationErrors).length === 0;
    if (isFieldValid) {
      dispatch({ type: UPDATE_FIELD_ERROR, field: name, error: "" });
    }
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="vacancyEmployeementTab">
          <div className="position-relative">
            <div className="form-border px-lg-5 py-5 px-4">
              <div className="form pb-4 pt-2">
                <div className="row ">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <SelectWithSearch
                      title={t("Contract type")}
                      name="contract"
                      placeHolder={t("Select")}
                      search
                      isMandatory
                      options={state.options.ContractType}
                      value={state.employment.contract}
                      onChange={(e) => handleSelectChange(e, "contract")}
                      isMulti
                      className="select-field"
                      // isTranslate
                      error={state.errors.contract}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <SelectWithSearch
                      title={t("Employment")}
                      name="employment"
                      placeHolder={t("Select")}
                      search
                      isMandatory
                      options={state.options.PreferredEmployment}
                      value={state.employment.employment}
                      onChange={(e) => handleSelectChange(e, "employment")}
                      isMulti={false}
                      className="select-field"
                      error={state.errors.employment}
                      // isTranslate
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <LabelWithInputField
                      isMandatory
                      name="hrs"
                      handleChange={handleFieldChange}
                      value={state.employment.hrs}
                      id="hrs"
                      label={t("Number of hours per week")}
                      type="text"
                      placeholder={t("Number of hours")}
                      error={state.errors.hrs}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <SelectWithSearch
                      title={t("Regime")}
                      name="regime"
                      placeHolder={t("Select")}
                      search
                      options={state.options.Regimen}
                      value={state.employment.regime}
                      onChange={(e) => handleSelectChange(e, "regime")}
                      isMulti={false}
                      className="select-field"
                      isMandatory
                      error={state.errors.regime}
                      // isTranslate
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <SelectWithSearch
                      title={t("Shift")}
                      name="shift"
                      placeHolder={t("Select shift")}
                      search
                      options={state.options.Shift}
                      value={state.employment.shift}
                      onChange={(e) => handleSelectChange(e, "shift")}
                      isMulti
                      className="select-field"
                      isMandatory
                      error={state.errors.shift}
                      // isTranslate
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <SelectWithSearch
                      title={t("VDAB template")}
                      name="vdabTemplate"
                      placeHolder={t("Select")}
                      search
                      options={state.options.VdabTemplate}
                      value={state.employment.vdabTemplate}
                      onChange={(e) => handleSelectChange(e, "vdabTemplate")}
                      isMulti={false}
                      className="select-field"
                      // isTranslate
                      error={state.errors.vdabTemplate}
                      isMandatory={checKVdabIsSelectedOrNot(state)}
                    />
                  </div>
                  {state.employment.vdabTemplate &&
                    state.employment.vdabTemplate?.value !== null && (
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <SelectWithSearch
                          title={t("VDAB competences")}
                          name="vdabCompetences"
                          placeHolder={t("Select")}
                          search
                          options={state.options.VdabCompetence}
                          value={state.employment.vdabCompetences}
                          onChange={(e) => handleSelectChange(e, "vdabCompetences")}
                          isMulti
                          className="select-field"
                          // isTranslate
                          error={state.errors.vdabCompetences}
                          isMandatory={checKVdabIsSelectedOrNot(state)}
                        />
                      </div>
                    )}
                </div>
                <div className="row pb-3">
                  <div className="col-md-12">
                    <LabelWithCKEditorField
                      label={t("Job description")}
                      name="description"
                      value={state.employment.description}
                      placeholder={t("Type here") + "..."}
                      handleChange={(event, editor) =>
                        handleCKEditorChange(event, editor, "description")
                      }
                      // className="field-shadow"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Navigation validStatus={validStatus} />
      </form>
    </>
  );
};

export default VacancyEmplomentDetails;
