import { useState } from "react";
import { useFormContext } from "../../context/Context";
import { Option } from "components/common/CommonInterfaces";
import { ValidationRules } from "utils/TypeAnnotations";
import { scrollToTop, validateForm } from "services/validation/ValidationService";
import { OFFER, UPDATE_FIELD_ERROR, UPDATE_FIELDS } from "../../annotation/VacancyConstants";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import LabelField from "components/atoms/LabelField";
import { t } from "pages/microservices/masterData/translation/Translation";
import CheckBoxField from "components/atoms/CheckBoxField";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import LabelWithCKEditorField from "components/molecules/LabelWithCKEditorField";
import Navigation from "../form-navigation/Navigation";
import "../../css/vacancy.css";

const VacancyOfferDetails = () => {
    const { state, dispatch } = useFormContext();

    const [validationStatus, setValidationStatus] = useState({
        isValid: false,
        type: ""
    });

    const validStatus = (validation: any) => {
        setValidationStatus({
            isValid: validation.isValid,
            type: validation.type
        });
    };

    const validation = (name: string, value: any, isSingleFieldValidation: boolean = false) => {
        const validationRules: ValidationRules = {
            // starterWage: [
            //     (value: string) =>
            //         validateWage(value, state.offer.experiencedWage, name)
            // ],
            // experiencedWage: [
            //     (value: string) =>
            //         validateWage(state.offer.starterWage, value, name)
            // ]
        };

        const validationErrors = validateForm({ ...state.general, [name]: value }, validationRules, isSingleFieldValidation ? name : undefined);
        dispatch({
            type: UPDATE_FIELD_ERROR,
            field: name === "starterWage" || name === "experiencedWage" ? "wage" : name,
            error: validationErrors[name]
        });
        const isFieldValid = Object.keys(validationErrors).length === 0;
        if (isFieldValid) {
            dispatch({ type: UPDATE_FIELD_ERROR, field: name, error: "" });
        }
        if (Object.keys(validationErrors).length > 0) {
            return false;
        }
        return true;
    };

    const handleFieldChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        let newValue: string | number = value;
        if (name === "starterWage" || name === "experiencedWage") {
            newValue = value.replace(/[^0-9,]/g, ""); // Allow only numbers and commas
        } else if (name === "starter" || name === "experienced") {
            const checked = (event.target as HTMLInputElement).checked;
            newValue = checked ? 1 : 0;
            dispatch({type: UPDATE_FIELDS, template: OFFER, field: name + "Wage", value: ""});
        }
        dispatch({type: UPDATE_FIELDS, template: OFFER, field: name, value: newValue});
        validation(name, newValue, true);
    };
    const handleSelectChange = (selectedOption: any, name: string) => {
        // if (name === "payType") {
            // const resetArray = {
            //     starterWage: "",
            //     experiencedWage: "",
            //     starter: 0,
            //     experienced: 0
            // };

            // Object.entries(resetArray).map(([key, value]) => {
            //     dispatch({type: UPDATE_FIELDS, template: OFFER, field: key, value: value });
            //     return null;
            // });
        // }
        dispatch({
            type: UPDATE_FIELDS, template: OFFER, field: name,
            value: Array.isArray(selectedOption) ? selectedOption?.map((item: any) => item?.value) : selectedOption?.value,
        });
    };

    const handleCKEditorChange = (
        event: any,
        editor: any,
        fieldName: string
    ) => {
        const content = editor.getData(); // Get the content from the editor
        const name = fieldName; // Use the fieldName parameter
        dispatch({type: UPDATE_FIELDS, template: OFFER, field: name, value: content});
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        scrollToTop();
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="position-relative">
                    <div className="form-border px-lg-5 py-5 px-4">
                        <div className="form pb-4 pt-2">
                            <div className="row ">
                                <div className="col-lg-4">
                                    <SelectWithSearch
                                        title={t("Pay type")}
                                        name="payType"
                                        placeHolder={t("Select")}
                                        search={true}
                                        options={state.options.PayType}
                                        value={state.offer.payType}
                                        onChange={(e) =>
                                            handleSelectChange(e, "payType")
                                        }
                                        isMulti={false}
                                        className="select-field"
                                    />
                                </div>
                                <div className="col-lg-8">
                                    <div className="row mt-2">
                                        <LabelField
                                            title={t("Wage")}
                                            className="align-self-center"
                                            isMandatory={false}
                                        />
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12 col-lg-6">
                                            <div className="row">
                                                <div className="col-sm-6 col-md-5 mt-2">
                                                    <CheckBoxField
                                                        label={t("Starter")}
                                                        name="starter"
                                                        onChangeHandler={handleFieldChange}
                                                        isChecked={state.offer.starter === 1}
                                                        id="starter"
                                                        lineHeight="1.5vw"
                                                    />
                                                </div>
                                                {state.offer.starter === 1 && (
                                                    <div className="col-sm-6 col-md-7 wage-wrapper position-relative">
                                                        <LabelWithInputField
                                                            isMandatory={false}
                                                            name="starterWage"
                                                            handleChange={handleFieldChange}
                                                            value={state.offer.starterWage}
                                                            id="starterWage"
                                                            type="text"
                                                            className=""
                                                        />
                                                        <span
                                                            className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute"
                                                            id="basic-addon1"
                                                        >
                                                            €
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-lg-6 col-md-12 mt-4 mt-lg-0">
                                            <div className="row">
                                                <div className="col-sm-6 col-md-5 mt-2 px-lg-0">
                                                    <CheckBoxField
                                                        label={t("Experienced")}
                                                        name="experienced"
                                                        onChangeHandler={handleFieldChange}
                                                        isChecked={state.offer.experienced === 1}
                                                        id="experienced"
                                                        lineHeight="1.5vw"
                                                    />
                                                </div>
                                                {state.offer.experienced ===
                                                    1 && (
                                                        <div className="col-sm-6 col-md-7 wage-wrapper position-relative">
                                                            <LabelWithInputField
                                                                isMandatory={false}
                                                                name="experiencedWage"
                                                                handleChange={handleFieldChange}
                                                                value={state.offer.experiencedWage}
                                                                id="experiencedWage"
                                                                type="text"
                                                            />
                                                            <span
                                                                className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute"
                                                                id="basic-addon1"
                                                            >
                                                                €
                                                            </span>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row text-danger mt-2">
                                        {state.errors.wage !== "" &&
                                            state.errors.wage}
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <LabelWithCKEditorField
                                        label={t("Job offer and benefits")}
                                        name="jobOffer"
                                        value={state.offer.jobOffer}
                                        placeholder={t("Type here") + "..."}
                                        handleChange={(event, editor) =>
                                            handleCKEditorChange(
                                                event,
                                                editor,
                                                "jobOffer"
                                            )
                                        }
                                        // className="field-shadow"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Navigation validStatus={validStatus} />
            </form>
        </>
    );
};

export default VacancyOfferDetails;