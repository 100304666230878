import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useFormContext } from '../../context/Context';
import SelectWithSearch from 'components/atoms/SelectWithSearch';
import HelpInfoIcon from 'static/images/HelpIcon';
import { Option } from 'utils/TypeAnnotations';
import { RANGE_VALUE } from 'constants/Constants';
import { COMPETENCE_CHANGE } from '../../annotations/CandidateConstants';
import Navigation from '../form-navigation/Navigation';
import { t } from 'pages/microservices/masterData/translation/Translation';

interface CompetenceProps {
  competenceOptions: any;
}
const CompetenceTab: React.FC<CompetenceProps> = ({
  competenceOptions,
}) => {
  const { state, dispatch } = useFormContext();
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });
  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };
  const data: any = state.dropDownData.competence;
  const getTitleForRating = (range: string) => {
    switch (parseInt(range)) {
      case 1:
        return "A1";
      case 2:
        return "A2";
      case 3:
        return "B1";
      case 4:
        return "B2";
      case 5:
        return "C1";
      case 6:
        return "C2";
      default:
        return "A1";
    }
  };

  const handleSelect = (selectedId: number, section: string, selected: Option[]) => {
    dispatch({
      type: COMPETENCE_CHANGE, field: "SELECT",
      payload: { selection: section, selected: selected.length > 0 ? selected : [], id: selectedId },
    });
  };
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    section: string,
    index: number
  ) => {
    dispatch({
      type: COMPETENCE_CHANGE,
      payload: { value: e.target.value, section: section, index: index },
    });
  };

  return (
    <div>
      <Accordion defaultActiveKey={competenceOptions!== undefined && competenceOptions[0]?.key} alwaysOpen>
        {competenceOptions!==undefined && competenceOptions.length>0 ?( competenceOptions.map((section: any) => {
          return (
            <Accordion.Item key={section?.key} eventKey={section?.key}>
              <Accordion.Header>{section?.label}</Accordion.Header>
              <Accordion.Body>
                <SelectWithSearch
                  name={section.key}
                  isMandatory={false}
                  search={true}
                  placeHolder="Select"
                  options={section.options}
                  value={data?.filter((item: any) => item.key == section.key)?.[0]?.options}
                  onChange={(selected) => handleSelect(section.id, section.key, selected)}
                  isMulti={true}
                  className="select-field"
                  error={""}
                />
                <div className="row border m-0 mb-2">
                  {data?.length > 0 &&
                    data.find((item: any) => item.key === section.key)?.options && (
                      <div
                        className="text-end mt-2"
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        title={`A. Basic user (A1/A2: beginner)\nB. Independent user (B1/B2: advanced)\nC. Skilled user (C1/C2: advanced)`}
                      >
                        <HelpInfoIcon />
                      </div>
                    )}
                  {data?.length > 0 && data?.find((item: any) => item.key === section.key)?.options?.map((option: any, index: any) => (
                    <div key={index} className="col-6 my-3">
                      <div className="row">
                        <div className="col-3">
                          <span className="me-3 fw-bolder">{option?.label}</span>
                        </div>
                        <div className="col">
                          <div className="w-100">
                            <input
                              type="range"
                              min="1"
                              max="6"
                              title={getTitleForRating(option?.range)}
                              value={option.range}
                              className="w-100"
                              onChange={(e) => handleChange(e, section.key, index)}
                            />{" "}
                            <div className="d-flex justify-content-between">
                              {RANGE_VALUE.map((value, index) => (
                                <span key={value}>{value}</span>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          );
        })):(
          <div className='text-center text-danger'> {t("No records found")}</div>
        )}
      </Accordion>
      <div style={{ padding: "1vw 0" }}>
        <Navigation validStatus={validStatus} />
      </div>
    </div>
  );
}

export default CompetenceTab;