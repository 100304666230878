import { t } from "pages/microservices/masterData/translation/Translation";
import { candidateFormData } from "./CandidateInterface";
import { emergencyContactInitialData, studiesInitialState, workExperienceInitialState } from "../helpers/CandidateHelperFunctions";

export const CandidateIntialState: candidateFormData = {
  tabDetails: [
    {
      id: "general",
      title: t("General"),
      showStatus: true,
      error: false,
      draft: false
    },
    {
      id: "studies",
      title: t("Studies"),
      showStatus: false,
      error: false,
      draft: false
    },
    {
      id: "workExperience",
      title: t("Work experience"),
      showStatus: false,
      error: false,
      draft: false
    },
    {
      id: "jobProfile",
      title: t("Job profile"),
      showStatus: false,
      error: false,
      draft: false
    },
    {
      id: "competence",
      title: t("Competence"),
      showStatus: false,
      error: false,
      draft: false
    },
    {
      id: "documents",
      title: t("Documents"),
      showStatus: false,
      error: false,
      draft: false
    }
  ],

  profileCard: {
    uploadCv: {
      accessUrl: "", // Initialize with an empty string
      fileName: "", // Initialize with an empty string
    },
    profilePicture: {
      accessUrl: "", // Initialize with an empty string
      fileName: "", // Initialize with an empty string
    }
  },

  general: {
    ssn: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    dob: null,
    age: '',
    gender: null,
    shortCode: '',
    businessUnit: null,
    placeOfBirth: '',
    countryOfBirth: null,
    nationality: null,
    ibanCountry: null,
    iban: '',
    bic: '',
    street: '',
    number: '',
    box: '',
    zipCode: '',
    country: null,
    city: '',
    sameAsResidence: 0,
    domStreet: '',
    domNumber: '',
    domBox: '',
    domZipCode: '',
    domCountry: null,
    domCity: '',
  },

  emergencyContactDetails: [emergencyContactInitialData],
  emergencyContactErrors: [
    {
      emergencyContactName: "",
      emergencyContactEmail: "",
      emergencyContactPhoneNumber: "",
    }
  ],

  others: {
    id: null,
    civilStatus: null,
    dependantSpouse: null,
    dependantChildren: 0,
    educationLevel: null,
    languageOfDocuments: null,
    drivingLicense: '',
    transport: null,
    additional: "",
  },

  studies: [studiesInitialState],
  
  workExperience: [workExperienceInitialState],

  workExperienceErrors: [
    {
      name: '',
      email: '',
      phoneNumber: ''
    }
  ],

  jobProfile: {
    desiredPartiarComitte: [],
    desiredCategory: [],
    desiredFunctions: [],
    desiredJobsInfo: '',
    maximunDistanceFromHome: 0,
    desiredRegimen: [],
    preferredEmployment: null,
    salaryExpectation: null,
    minimunGrossSalary: '',
    formStatus: 0

  },

  certificates: [],

  europeanCitizen: {
    isEuropeanCitizen: 0,
    workPermit: {
      accessUrl: '',  // Initialize with an empty string
      fileName: '',   // Initialize with an empty string
    },
    from: null,
    to: null
  },

  documents: [],

  dropDownData: {
    countriesList: [],
    contractTypeList: [],
    businessUnitList: [],
    civilStatusList: [],
    educationLevelList: [],
    languagesList: [],
    transportList: [],
    additionalList: [],
    diplomaList: [],
    experienceList: [],
    companiesList: [],
    paritairComiteeList: [],
    pcCategoriesList: [],
    pcFunctionsList: [],
    regimenList: [],
    preferredEmploymentList: [],
    salaryExpectationsList: [],
    attestTypeList: [],
    groupList: [],
    competence: [],
    categoryList: [],
    pcCategoryInitialList: [],
    pcFunctionInitialList: []
  },

  errors: {
    ssn: '',
    firstName: '',
    lastName: '',
    email: '',
    shortCode: '',
    phoneNumber: '',
    countryOfBirth: '',
    businessUnit: '',
    iban: '',
    street: '',
    number: '',
    zipCode: '',
    city: '',
    country: '',
    desiredFunctions: '',
  }
}
