import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ApiCall } from "services/ApiServices";
import EncryptDecryptService from "services/EncryptDecryptService";
import CustomNotify from "components/atoms/CustomNotify";
import { LOGIN } from "routes/ApiEndpoints";
import { M_IDENTITY_MANAGER } from "constants/Constants";
import { t } from "pages/microservices/masterData/translation/Translation";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import LabelField from "components/atoms/LabelField";
import InputTextfield from "components/atoms/InputTextField";
import {
  validateEmail,
  validateForm,
  validatePassword,
  validateRequired,
} from "services/validation/ValidationService";
import HidePassword from "static/images/Hide.svg";
import ShowPassword from "static/images/Show.svg";

interface Credentials {
  email?: string;
  password?: string;
}

interface ValidationRules {
  [key: string]: Function[];
}

interface EmployerAuthenticModalProps {
  handleClose: () => void;
  onAuthenticationSuccess: (
    success: any,
    email: any,
    signatureImage: any,
    userId: any
  ) => void;
  users?: any; // Mark users as optional
}

const EmployerAuthenticModal: React.FC<EmployerAuthenticModalProps> = ({
  handleClose,
  onAuthenticationSuccess,
  users,
}) => {
  const [passwordType, setPasswordType] = useState("password");
  const [data, setData] = useState<Credentials>({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState<Credentials>({});
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
    validation(name, value, true);
  };

  const validation = (
    name: string,
    value: string,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      email: [validateRequired, validateEmail],
      password: [validateRequired, validatePassword],
    };

    const validationErrors = validateForm(
      { ...data, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const submitHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const { name, value } = event.target as HTMLInputElement;

    if (validation(name, value)) {
      const formData = {
        email: data.email,
        password: EncryptDecryptService.encryptData(
          JSON.stringify(data.password)
        ),
      };

      try {
        const response = await ApiCall.service(
          LOGIN,
          "POST",
          formData,
          false,
          M_IDENTITY_MANAGER
        );

        if (response.status === "200") {
          // Ensure response contains userId
          const userId = response.userId;

          if (users && Array.isArray(users)) {
            // Check if userId exists in the users array if users is provided
            if (users.includes(userId)) {
              const getRefreshToken = localStorage.getItem("refresh_token");
              let token = getRefreshToken
                ? JSON.parse(getRefreshToken).token
                : null;
              const user = {
                token: response.access_token,
                email: response.email,
                token_type: response.token_type,
                isAuthenticated: true,
                refreshToken: token,
              };
              CustomNotify({
                type: "success",
                message: t("Authentication is successful!"),
              });
              onAuthenticationSuccess(
                true,
                response.email,
                response.signature,
                response.userId
              );
              handleClose();
              // //   dispatch(addUserDetails(user));
            } else {
              CustomNotify({
                type: "error",
                message: t("User is not related with this project."),
              });
              onAuthenticationSuccess(false, "", "", "");
            }
          } else {
            // Proceed with normal flow if users is not provided
            const getRefreshToken = localStorage.getItem("refresh_token");
            let token = getRefreshToken
              ? JSON.parse(getRefreshToken).token
              : null;
            const user = {
              token: response.access_token,
              email: response.email,
              token_type: response.token_type,
              isAuthenticated: true,
              refreshToken: token,
            };

            CustomNotify({
              type: "success",
              message: t("Authentication is successful!"),
            });
            onAuthenticationSuccess(
              true,
              response.email,
              response.signature,
              response.userId
            );
            handleClose();
            // dispatch(addUserDetails(user));
          }
        } else {
          // Handle error responses
          if (response.field === "email") {
            setErrors({ email: response.msg });
          } else {
            setErrors({ password: response.msg });
          }
          onAuthenticationSuccess(false, "", "", "");
        }
      } catch (error) {
        console.error("Error authenticating:", error);
        CustomNotify({
          type: "error",
          message: t(
            "An error occurred while authenticating. Please try again later."
          ),
        });
        onAuthenticationSuccess(false, "", "", "");
      }
    }
  };

  return (
    <div>
      <Modal
        size={"lg"}
        show={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="bitter-italic-normal-medium-24">
            {t("Authenticate employer")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="login-form">
            <div className="col-md-10 m-auto">
              <div className="mb-3">
                <LabelWithInputField
                  label={t("Email")}
                  id="email"
                  isMandatory={true}
                  placeholder={t("Enter email")}
                  type="email"
                  name="email"
                  handleChange={changeHandler}
                  value={data.email}
                  error={errors.email}
                  className="form-control shadow-none rounded-0"
                />
              </div>
              <div className="mb-3 position-relative">
                <LabelField
                  title={t("Password")}
                  isMandatory={true}
                  htmlfor="password"
                />
                <div className="d-flex position-relative">
                  <InputTextfield
                    handleChange={changeHandler}
                    type={passwordType}
                    value={data.password}
                    id="password"
                    placeholder={t("Enter password")}
                    name="password"
                    className="form-control shadow-none rounded-0"
                  />
                  <p
                    onClick={togglePassword}
                    className="position-absolute mt-1 me-2 end-0 cursor-pointer"
                  >
                    {passwordType === "password" ? (
                      <img src={HidePassword} alt="Hide Password" />
                    ) : (
                      <img src={ShowPassword} alt="Show Password" />
                    )}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="height-20 mb-2">
                  {errors.password && (
                    <span className="text-danger">{errors.password}</span>
                  )}
                </div>
              </div>
              <div className="row my-4">
                <div className="col-md-6 align-self-center">
                  <p
                    className="back-btn mb-0 text-uppercase text-decoration-underline"
                    onClick={handleClose}
                  >
                    {t("Cancel")}
                  </p>
                </div>
                <div className="col-md-6 text-end">
                  <button
                    className="form-button shadow-none float-end"
                    type="submit"
                    onClick={(e) => submitHandler(e)}
                  >
                    {t("Authenticate")}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EmployerAuthenticModal;
