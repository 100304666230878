import { Dispatch } from "react";
import { COMPETENCE, DOCUMENTS, GENERAL, JOB_PROFILE, STUDIES, UPDATE_ERROR_FIELD, UPDATE_TAB_ERROR, WORK_EXPERIENCE } from "../../annotations/CandidateConstants";
import { validateMultiSelectField, validatePhoneNumber, validateRequired, validateSelectField } from "services/validation/ValidationService";

// Define types for props if necessary
export interface MandatoryFields {
    [key: string]: string[];
}

export const validateMandatoryFields = (
    state: any,
    dispatch: Dispatch<any>,
    type: string
): boolean => {
    const activeTab = state.tabDetails.find((tab: any) => tab.showStatus);
    const activeTabId = activeTab?.id;
    const generalFields = [
        "firstName",
        "lastName",
        "phoneNumber",
        "email",
        "street",
        "number",
        "zipCode",
        "city",
        "country",
        "businessUnit",
        "countryOfBirth",
        // "drivingLicence",
    ];

    const mandatoryFieldMapping: { [key: string]: string[] } =
        type === "draft"
            ? {
                general: ["firstName", "lastName",
                    "phoneNumber",
                    "email"],
            }
            : {
                general: generalFields,
                jobProfile: ["desiredFunctions"],
            };

    const errorsByTab: { [tabId: string]: { [fieldName: string]: string } } = {};

    state.tabDetails
        .slice(
            0,
            state.tabDetails.findIndex((tab: any) => tab.id === activeTabId) + 1
        )
        .forEach((tab: any) => {
            const tabId = tab.id;
            let tabFields = {};

            switch (tabId) {
                case GENERAL:
                    tabFields = state.general;
                    break;
                case JOB_PROFILE:
                    tabFields = state.jobProfile;
                    break;
                default:
                    break;
            }

            const validationRules: {
                [fieldName: string]: ((value: any) => string | null | undefined)[];
            } = {
                firstName: [validateRequired],
                lastName: [validateRequired],
                phoneNumber: [validatePhoneNumber],
                email: [validateRequired],
                desiredFunctions: [validateMultiSelectField],
                businessUnit: [validateSelectField],
                countryOfBirth: [validateSelectField],
                street: [validateRequired],
                number: [validateRequired],
                zipCode: [validateRequired],
                city: [validateRequired],
                country: [validateSelectField]
            };

            const mandatoryFields = mandatoryFieldMapping[tabId] || [];

            const errorsInTab: { [fieldName: string]: string } = {};
            for (const fieldName of mandatoryFields) {
                const fieldValidationRules = validationRules[fieldName];

                const fieldValue = tabFields[fieldName as keyof typeof tabFields];
                if (fieldValidationRules) {
                    for (const rule of fieldValidationRules) {
                        const validationError = rule(fieldValue);


                        if (validationError) {
                            errorsInTab[fieldName] = validationError;
                            dispatch({
                                type: UPDATE_ERROR_FIELD,
                                field: fieldName,
                                error: validationError,
                            });
                            break;
                        } else {
                            dispatch({
                                type: UPDATE_ERROR_FIELD,
                                field: fieldName,
                                error: "",
                            });
                        }
                    }
                }
            }

            if (Object.keys(errorsInTab).length > 0) {
                errorsByTab[tabId] = errorsInTab;
            }
        });

    updateTabsStatus(state, dispatch, errorsByTab);
    return Object.keys(errorsByTab).length === 0;
};

export const updateTabsStatus = (
    state: any,
    dispatch: Dispatch<any>,
    errors: object
) => {
    const keysToGet = [
        GENERAL,
        STUDIES,
        // COMPETENCE,
        WORK_EXPERIENCE,
        JOB_PROFILE,
        DOCUMENTS,
    ];

    const selectKeys = keysToGet
        .map((key) => {
            if (errors.hasOwnProperty(key)) {
                const tabIndex = findTabObjectById(state, key);
                if (tabIndex !== undefined && tabIndex !== null) {
                    dispatch({
                        type: UPDATE_TAB_ERROR,
                        tabIndex,
                        error: true,
                    });
                }
                return tabIndex;
            }
            return null;
        })
        .filter((key) => key !== null);

    const tabIndexes = [0, 1, 2, 3, 4, 5];
    tabIndexes.forEach((index) => {
        if (!selectKeys.includes(index)) {
            dispatch({
                type: UPDATE_TAB_ERROR,
                tabIndex: index,
                error: false,
            });
        }
    });
};

const findTabObjectById = (state: any, idToFind: string) => {
    return state.tabDetails.findIndex((tab: any) => tab.id === idToFind);
};