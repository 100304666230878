import React from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useFormContext } from "pages/microservices/project/context/Context";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import CheckBoxField from "components/atoms/CheckBoxField";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { UPDATE_BILLING_FIELD } from "../context/Constants";
import {
  validateForm,
  validateMultiSelectField,
  validateRequired,
  validateSelectField,
} from "services/validation/ValidationService";
import { ValidationRules } from "utils/TypeAnnotations";

interface ProjectsProps {
  id?: string | number;
}

const Billing: React.FC<ProjectsProps> = () => {
  const { state, dispatch } = useFormContext();
  const billing = state.billing.data;
  const billingErr = state.billing.errors;
  const options = state.options;
  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    if (type === "checkbox") {      
      if (event?.target?.name === 'proformaInvoice') {
        dispatch({ type: UPDATE_BILLING_FIELD, field: 'email', value: []});
      } else if (event?.target?.name === 'referenceRequired') {
        dispatch({ type: UPDATE_BILLING_FIELD, field: 'poNumber', value: ""});
        dispatch({ type: UPDATE_BILLING_FIELD, field: 'reference', value: ""});
      }
      dispatch({ type: UPDATE_BILLING_FIELD, field: name, value: checked });
    } else {
      dispatch({ type: UPDATE_BILLING_FIELD, field: name, value });
    }
  };

  const validation = (
    name: string,
    value: string | boolean | Date | object[] | undefined,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      vatRate: [validateSelectField],
      billingStatus: [validateSelectField],
      paymentTerm: [validateSelectField],
      poNumber: billing.referenceRequired ? [validateRequired] : [],
      reference: billing.referenceRequired ? [validateRequired] : [],
      email: billing.proformaInvoice ? [validateMultiSelectField] : [],
    };
    const validationErrors = validateForm(
      { ...state.billing.data, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    console.log(name, value, validationErrors);
    
    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      const updatedErrors = {
        ...state.billing.errors,
        [name]: "",
      };

      state.billing.errors = updatedErrors;
    } else {
      const updatedErrors = {
        ...state.billing.errors,
        [name]: validationErrors[name],
      };
      state.billing.errors = updatedErrors;
    }
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };
  const handleSelectChange = (selectedOption: any, name: string) => {
    dispatch({
      type: "UPDATE_BILLING_FIELD",
      field: name,
      value: selectedOption,
    });
    validation(name, selectedOption, true);
  };

  return (
    <form>
      <div className="form-border" style={{ paddingTop: "3vw" }}>
        <div className="row">
          <div className="col-6">
            <SelectWithSearch
              title="VAT rate"
              name="vatRate"
              isMandatory={true}
              search={true}
              options={[
                { value: 1, label: "Vat rate 1" },
                { value: 2, label: "Vat rate 2" },
                { value: 3, label: "Vat rate 3" },
                { value: 4, label: "Vat rate 4" },
              ]}
              value={billing.vatRate}
              onChange={(e) => handleSelectChange(e, "vatRate")}
              placeHolder="Select"
              isMulti={false}
              className="select-field"
              error={billingErr.vatRate?.toString()}
            />
          </div>
          <div className="col-6">
            <SelectWithSearch
              title="Billing status"
              name="billingStatus"
              isMandatory={true}
              search={true}
              options={[
                { value: 1, label: "Billable" },
                { value: 2, label: "Not billable" },
              ]}
              value={billing.billingStatus}
              onChange={(e) => handleSelectChange(e, "billingStatus")}
              placeHolder="Select"
              isMulti={false}
              className="select-field"
              error={billingErr.billingStatus?.toString()}
            />
          </div>
          <div className="col-6">
            <SelectWithSearch
              title="Payment term"
              name="paymentTerm"
              isMandatory={true}
              search={true}
              options={[
                { value: 1, label: "Weekly" },
                { value: 2, label: "Monthly" },
              ]}
              value={billing.paymentTerm}
              onChange={(e) => handleSelectChange(e, "paymentTerm")}
              placeHolder="Select"
              isMulti={false}
              className="select-field"
              error={billingErr.paymentTerm?.toString()}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="me-5 d-inline-block">
              <CheckBoxField
                label={"Proforma invoice"}
                name={"proformaInvoice"}
                onChangeHandler={handleFieldChange}
                isChecked={billing.proformaInvoice}
                lineHeight="1.5vw"
                id="proformaInvoice"
              />
            </div>

            <div className="d-inline-block">
              <CheckBoxField
                label="Split invoice per person"
                name="splitInvoicePerPerson"
                onChangeHandler={handleFieldChange}
                isChecked={billing.splitInvoicePerPerson}
                lineHeight="1.5vw"
                id="splitInvoicePerPerson"
              />
            </div>
            {billing.proformaInvoice && (
              <div className="col-12" style={{ marginTop: "0.6vw" }}>
                <div className="row">
                  <div className="col-12">
                    <SelectWithSearch
                      title="Email"
                      name="email"
                      isMandatory={true}
                      search={true}
                      options={options.companyUsers}
                      value={billing.email}
                      onChange={(e) => handleSelectChange(e, "email")}
                      placeHolder="Select"
                      isMulti={true}
                      error={billingErr.email}
                      className="select-field"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-6">
            <CheckBoxField
              label="Reference required"
              name="referenceRequired"
              onChangeHandler={handleFieldChange}
              isChecked={billing.referenceRequired}
              lineHeight="1.5vw"
              id="referenceRequired"
            />
          {billing.referenceRequired && (
            <div className="col-12" style={{ marginTop: "0.6vw" }}>
              <div className="row align-items-start">
                <div className="col">
                  <LabelWithInputField
                    isMandatory={billing.referenceRequired}
                    name="poNumber"
                    handleChange={handleFieldChange}
                    id="po_number"
                    label="PO number"
                    type="text"
                    error={billingErr.poNumber}
                    value={billing.poNumber}
                  />
                </div>
                <div className="col">
                  <LabelWithInputField
                    isMandatory={billing.referenceRequired}
                    name="reference"
                    handleChange={handleFieldChange}
                    id="reference"
                    label="Reference"
                    error={billingErr.reference}
                    type="text"
                    value={billing.reference}
                  />
                </div>
              </div>
            </div>
          )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default Billing;
